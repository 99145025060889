import React from 'react';

import { CollectionCount } from 'module/Curation/Curation.type';
import { DetectionSummary, DetectionSummaryCatalog } from 'module/Detection';
import { LandingStats } from 'module/Landing/Landing.type';
import { ArtifactMetadataService, MetadataCatalog } from 'module/Metadata';
import { SupplementalArtifact, SupplementalCatalog } from 'module/Search';

import { Status } from 'storage';

import { Artifact, Guid } from 'types/common';

export type FeedProvider = {
  detectionCatalog: DetectionSummaryCatalog;
  supplementalCatalog: SupplementalCatalog;
  metadataCatalog: MetadataCatalog;
  getDetection: (value: Guid) => DetectionSummary;
  getSupplemental: (value: Guid) => SupplementalArtifact;
  getMetadata: (value: Guid) => ArtifactMetadataService;
  getCounts: (item: Artifact) => LandingStats | CollectionCount;
  getCountsStatus: (item: Artifact) => Status;
  detectionPending: boolean;
  supplementalPending: boolean;
  metadataPending: boolean;
  refreshMetadata: () => void;
};

const FeedExtraContext = React.createContext<FeedProvider>(null);
FeedExtraContext.displayName = 'FeedContext';

function useFeedExtra(): FeedProvider {
  const context = React.useContext(FeedExtraContext);

  if (!context) {
    throw new Error('useFeedExtra must be used within the FeedExtraContext');
  }

  return context;
}

function FeedExtraProvider({
  children,
  detection,
  supplemental,
  metadata = {},
  getCounts,
  getCountsStatus,
  detectionPending,
  supplementalPending,
  metadataPending = false,
  refreshMetadata
}: {
  children: any;
  detection: DetectionSummaryCatalog;
  supplemental: SupplementalCatalog;
  metadata?: MetadataCatalog;
  getCounts?: (item: Artifact) => LandingStats | CollectionCount;
  getCountsStatus?: (item: Artifact) => Status;
  detectionPending: boolean;
  supplementalPending: boolean;
  metadataPending?: boolean;
  refreshMetadata?: () => void;
}) {
  const value = React.useMemo(() => {
    return {
      detectionCatalog: detection,
      supplementalCatalog: supplemental,
      metadataCatalog: metadata,
      getDetection: (value: Guid) => detection[value],
      getSupplemental: (value: Guid) => supplemental[value],
      getMetadata: (value: Guid) => metadata[value],
      getCounts,
      getCountsStatus,
      detectionPending,
      supplementalPending,
      metadataPending,
      refreshMetadata
    };
  }, [
    getCounts,
    getCountsStatus,
    detection,
    detectionPending,
    metadata,
    metadataPending,
    refreshMetadata,
    supplemental,
    supplementalPending
  ]);

  return <FeedExtraContext.Provider value={value}>{children}</FeedExtraContext.Provider>;
}

export { FeedExtraProvider, useFeedExtra };
