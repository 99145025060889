import React, { useState } from 'react';

import { faCopy } from '@fortawesome/pro-solid-svg-icons';
import mean from 'lodash/mean';
import sum from 'lodash/sum';

import { ActionIconButton, RouterButton } from 'snap-ui/Button';
import {
  GRID_AGGREGATION_FUNCTIONS,
  GridColumnVisibilityModel,
  GridFilterModel,
  GridValueGetterParams,
  gridStringOrNumberComparator,
  useGridApiRef,
  useKeepGroupedColumnsHidden
} from 'snap-ui/DataGrid';
import { getRowForSort } from 'snap-ui/DataGrid/DatGrid.helper';
import { DL } from 'snap-ui/KeyValueList';

import { useCannotRedirect } from 'aso/useCannotRedirect';

import Path from 'constants/paths';

import useTitle from 'hooks/useTitle';

import { ApiError } from 'module/ApiError';
import { ALL_INTEGRATION, JobOverviewDetailItem, JobType, JobTypeTableMap } from 'module/Job';
import { getGroupJobStatus } from 'module/Job/Job.util';
import { JobGridTreeDataGroupingCell, TaskTableToolbar } from 'module/Job/Job.widgets';
import { aggregateIntegration } from 'module/Job/Task/Column';
import { TaskGuidContainer } from 'module/Job/Task/Task.style';
import useJobGroupDetail from 'module/Job/useJobGroupDetail';
import Artifact from 'module/Layout/Artifact';
import { useMayI } from 'module/May';
import ContentHeader from 'module/Widgets/ContentHeader';
import CopyButton from 'module/Widgets/CopyButton';
import OverviewTab from 'module/Widgets/OverviewTab';

import { useAuth, useManagedOrganizations } from 'provider';

import { Status } from 'storage';

import { FunctionalPermission } from 'types/auth';
import { ArtifactType } from 'types/common';

import {
  HuntDetailStatusLabel,
  Hunted,
  JobCreation,
  JobFrequency,
  JobGroupDuration,
  JobGroupEndDate,
  JobGroupHits,
  JobGroupStartDate,
  JobModified,
  JobOrganization,
  JobProgress,
  JobUser,
  OverviewPlaceholder,
  TaskColumnsPlaceholders
} from '../Hunt.widget';
import IntegrationSwitcher from '../IntegrationSwitcher';
import { detectionColumns, errorAggregate, guidAggregate, iocColumns, sumUp, taskActions } from './Column';
import {
  DetailPanelsContainer,
  GridSizer,
  HeaderContainer,
  HitsGrid,
  PageContainer,
  TaskDetails,
  TaskOverviewPanel,
  Title
} from './HuntDetail.style';

export default function HuntDetail(): JSX.Element {
  useCannotRedirect(FunctionalPermission.OpenSearchUIAccess);
  const { user } = useAuth();
  // TODO: include read_only check in canTune
  const canTune = useMayI(FunctionalPermission.Tuning);
  const { organizations, status } = useManagedOrganizations();
  const { jobGroup, jobs, details, hits, isPending, errorProps, exportDataAsCsv, isExportPending } =
    useJobGroupDetail();
  useTitle(`${jobGroup?.name || 'Hunt Results'}`);

  const jobType = jobGroup?.type as JobType;

  const apiRef = useGridApiRef();
  const [integrationFilter, setIntegrationFilter] = React.useState<string>();
  const isAllIntegration = integrationFilter === ALL_INTEGRATION.value;
  const isIntegrationLoading = status === Status.pending;

  const options = jobs.map(org => ({
    value: org.integration_guid,
    content: org.integration_name
  }));
  if (jobs.length > 1) options.unshift(ALL_INTEGRATION);

  const filterJob = jobs.find(j => j.integration_guid === integrationFilter);
  const filterJobGroup = React.useMemo(() => {
    if (integrationFilter !== ALL_INTEGRATION.value) {
      return jobs.find(job => job.integration_guid === integrationFilter);
    } else
      return {
        ...jobGroup,
        total_hits: sum(Object.values(hits)),
        job_status: getGroupJobStatus(jobs.map(job => job.job_status)),
        progress_percent: mean(jobs.map(job => job.progress_percent))
      };
  }, [hits, integrationFilter, jobGroup, jobs]);
  const filteredDetails = React.useMemo(() => {
    if (integrationFilter === ALL_INTEGRATION.value) return details;
    else {
      return details
        .filter(j => j.integration_guid === integrationFilter)
        .map(j => {
          return {
            ...j,
            detectionGuidForGrouping: null
          };
        });
    }
  }, [details, integrationFilter]);
  const [filterModel, setFilterModel] = React.useState<GridFilterModel>({
    items: [],
    quickFilterValues: []
  });

  function onIntegrationChange(type) {
    setIntegrationFilter(type);
  }

  function onSearchChange(event: React.ChangeEvent<HTMLInputElement>) {
    setFilterModel(model => ({
      ...model,
      items: [
        {
          id: 1,
          field: 'name',
          operator: 'contains',
          value: event.target.value
        }
      ]
    }));
  }

  React.useEffect(() => {
    setIntegrationFilter(jobs.length > 1 ? ALL_INTEGRATION.value : jobs?.[0]?.integration_guid);
  }, [jobs]);

  const statusDetail = (
    <div>
      {isAllIntegration &&
        jobs.map(job => (
          <div key={job.integration_guid}>
            <dt>{job.integration_name || 'Unknown'}</dt>
            <dd>{job?.job_status_detail}</dd>
          </div>
        ))}
      {!isAllIntegration && (
        <div>
          <dt>{filterJob?.integration_name || 'Unknown'}</dt>
          <dd>{filterJob?.job_status_detail}</dd>
        </div>
      )}
    </div>
  );

  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      rowGrouping: {
        model: ['detectionGuidForGrouping']
      },
      filter: {
        filterModel: {
          items: []
        }
      },
      sorting: {
        sortModel: [{ field: 'hit_count', sort: 'desc' }]
      },
      aggregation: {
        model: {
          integration_name: 'aggregateIntegration',
          guid: 'guidAggregate',
          item_error: 'errorAggregate',
          hit_count: 'sumUp'
        }
      }
    }
  });

  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    detectionGuidForGrouping: false,
    name: jobType === JobTypeTableMap.IOC
  });

  // jobType may change during loading, resulting in the column not being visible when the initial state is set
  React.useEffect(() => {
    setColumnVisibilityModel({
      detectionGuidForGrouping: false,
      name: jobType === JobTypeTableMap.IOC
    });
  }, [jobType]);

  return (
    <Artifact
      label='Hunt Results'
      meta={
        <HeaderContainer>
          <Title>
            <ContentHeader headerText={filterJobGroup?.name || 'Hunt Result'} showPlaceholder={isPending} />
            {!isPending && user.superuser && (
              <TaskGuidContainer>
                <CopyButton
                  tooltip='Copy Task ID to clipboard'
                  placement='top'
                  value={filterJobGroup?.analytic_job_group_guid}
                >
                  <ActionIconButton aria-label='Copy Task ID to clipboard' icon={faCopy} />
                </CopyButton>
              </TaskGuidContainer>
            )}
          </Title>
          {!!filterJobGroup?.analytic_job_group_guid && (
            <div>
              <RouterButton
                to={Path.HuntWorkbench.replace(':guid', filterJobGroup?.analytic_job_group_guid)}
                variant='outlined'
              >
                View Hunt in Workbench
              </RouterButton>
            </div>
          )}
        </HeaderContainer>
      }
      type={ArtifactType.Hunt}
    >
      <PageContainer>
        <ApiError {...errorProps} />
        <div>
          <DetailPanelsContainer>
            <TaskOverviewPanel>
              <OverviewTab title='Hunt Configuration' description='' includeBranding={false} includeHeading={false}>
                {isPending ? (
                  <OverviewPlaceholder width={150} />
                ) : (
                  <DL>
                    <IntegrationSwitcher
                      onIntegrationChange={onIntegrationChange}
                      value={integrationFilter}
                      options={options}
                    />

                    <Hunted details={filteredDetails} type={jobType} />
                    <JobGroupHits hits={filterJobGroup?.total_hits} type={jobType} />
                    <JobGroupDuration jobGroup={filterJobGroup} />
                    <JobGroupStartDate jobGroup={filterJobGroup} />
                    <JobGroupEndDate jobGroup={filterJobGroup} />
                  </DL>
                )}
              </OverviewTab>
            </TaskOverviewPanel>
            <TaskDetails>
              <OverviewTab title='Task Details' description='' includeBranding={false} includeHeading={false}>
                {isPending ? (
                  <OverviewPlaceholder width={100} />
                ) : (
                  <DL>
                    {isIntegrationLoading ? (
                      <TaskColumnsPlaceholders width={100} />
                    ) : (
                      user.superuser && (
                        <JobOrganization orgId={filterJobGroup?.organization_id} organizations={organizations} />
                      )
                    )}
                    <JobUser name={filterJobGroup?.created_by} />
                    <JobFrequency scheduleId={filterJobGroup?.schedule_id} />
                    <JobCreation created={filterJobGroup?.creation} />
                    <JobModified modified={filterJobGroup?.modified} />
                    <JobProgress
                      timeRemaining={filterJobGroup?.estimated_remaining_duration}
                      percent={filterJobGroup?.progress_percent}
                    />

                    <HuntDetailStatusLabel
                      status={filterJobGroup?.job_status}
                      percent={filterJobGroup?.progress_percent}
                      statusDetail={statusDetail}
                    />
                  </DL>
                )}
              </OverviewTab>
            </TaskDetails>
          </DetailPanelsContainer>

          <GridSizer>
            <HitsGrid
              columns={
                jobType === JobTypeTableMap.IOC
                  ? [
                      ...iocColumns,
                      taskActions({
                        jobGroupGuid: filterJobGroup?.analytic_job_group_guid,
                        apiRef,
                        includeTuningLink: false
                      })
                    ]
                  : [
                      ...detectionColumns(user.superuser),
                      taskActions({
                        jobGroupGuid: filterJobGroup?.analytic_job_group_guid,
                        apiRef,
                        includeTuningLink: canTune
                      })
                    ]
              }
              rows={filteredDetails || []}
              getRowId={row => row.guid + row.integration_guid}
              loading={isPending}
              apiRef={apiRef}
              initialState={initialState}
              filterModel={filterModel}
              onFilterModelChange={newModel => setFilterModel(newModel)}
              groupingColDef={{
                headerName: 'Name',
                flex: 1,
                minWidth: 300,
                mainGroupingCriteria: 'name',
                sortingOrder: ['asc', 'desc'],
                hideable: false,
                sortComparator: (v1, v2, param1, param2) => gridStringOrNumberComparator(v1, v2, param1, param2),
                valueGetter(params: GridValueGetterParams) {
                  const row = getRowForSort<JobOverviewDetailItem>(apiRef.current, params);
                  return row?.name;
                },
                renderCell: params => (
                  <JobGridTreeDataGroupingCell
                    {...params}
                    idPath='guid'
                    linkPath={Path.Detection}
                    matchingGuid={null}
                  />
                )
              }}
              aggregationFunctions={{
                ...GRID_AGGREGATION_FUNCTIONS,
                aggregateIntegration,
                guidAggregate,
                errorAggregate,
                sumUp
              }}
              columnVisibilityModel={columnVisibilityModel}
              onColumnVisibilityModelChange={newModel => {
                setColumnVisibilityModel(newModel);
              }}
              slots={{
                toolbar: TaskTableToolbar
              }}
              slotProps={{
                toolbar: {
                  onSearchChange,
                  exportDataAsCsv,
                  isExportPending,
                  includeByOrg: false,
                  exportChildren: jobType === JobTypeTableMap.IOC ? 'Export Indicator Hits' : undefined
                }
              }}
              getAggregationPosition={groupNode => (groupNode == null ? null : 'inline')}
            />
          </GridSizer>
        </div>
      </PageContainer>
    </Artifact>
  );
}
