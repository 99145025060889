import React from 'react';

import zod from 'zod';

import Checkbox from 'snap-ui/Checkbox';
import FormControlLabel from 'snap-ui/FormControlLabel';
import FormGroup from 'snap-ui/FormGroup';
import FormLabel from 'snap-ui/FormLabel';
import Icon from 'snap-ui/Icon';
import Tooltip from 'snap-ui/Tooltip';

import { ARTIFACT_SCORE_OPTIONS } from 'constants/common';

import Badge from 'module/Widgets/Badge';

import { ArtifactScore, ArtifactType } from 'types/common';
import { Ops, Query } from 'types/filter';

import { FilterControl } from '../GlobalFilter.style';
import { FilterAugment, FilterConfig } from '../GlobalFilter.type';
import { addOrRemoveValue, fromQueryNoop } from '../GlobalFilter.util';

type AnalyticRankKeys = {
  confidence: ArtifactScore[];
};

type AnalyticRankFilterProps = {
  onChange(values: AnalyticRankKeys): void;
  values: AnalyticRankKeys;
};

function AnalyticRankFilter({ onChange, values }: AnalyticRankFilterProps): React.ReactElement {
  return (
    <FilterControl>
      <FormLabel id='rank-toggle-button-group-label' className='title-tooltip'>
        By Detection Confidence
        <Tooltip title='Measure of false positive hits in real world' placement='right' arrow wrap>
          <Icon.Info />
        </Tooltip>
      </FormLabel>
      <FormGroup aria-labelledby='rank-toggle-button-group-label'>
        {ARTIFACT_SCORE_OPTIONS.map(option => (
          <FormControlLabel
            key={option.key}
            control={
              <Checkbox
                onChange={handleChange}
                value={option.value}
                checked={values.confidence.includes(option.value)}
              />
            }
            label={<Badge badgeSignature={option.value} badgeName='CONFIDENCE' />}
          />
        ))}
      </FormGroup>
    </FilterControl>
  );

  function handleChange(e: React.FormEvent<HTMLInputElement>, checked: boolean): void {
    const v = e.currentTarget.value as ArtifactScore;
    const confidence = addOrRemoveValue(checked, values.confidence, v);
    onChange({ confidence });
  }
}

function toQuery(path: string) {
  return function (values: AnalyticRankKeys, { orgId }: FilterAugment): Query {
    if (!values.confidence?.length || Object.values(ArtifactScore).every(score => values.confidence?.includes(score)))
      return;
    const query: Query = {
      op: Ops.or,
      items: [
        {
          op: Ops.for_each,
          items: [
            {
              field: `${path}.rank`,
              op: Ops.in,
              value: values.confidence
            },
            {
              field: `${path}.organization_id`,
              op: Ops.equals,
              value: orgId
            }
          ]
        }
      ]
    };

    return query;
  };
}

function fromQuery(path: string) {
  return zod
    .object({
      op: zod.literal(Ops.or),
      items: zod.array(
        zod.union([
          zod
            .object({
              op: zod.literal(Ops.for_each),
              items: zod.array(
                zod.union([
                  zod
                    .object({
                      field: zod.literal(`${path}.organization_id`)
                    })
                    .transform(() => undefined),
                  zod
                    .object({
                      field: zod.literal(`${path}.rank`),
                      op: zod.literal(Ops.in),
                      value: zod.array(zod.nativeEnum(ArtifactScore))
                    })
                    .transform(query => query.value)
                ])
              )
            })
            .transform(query => query.items.filter(Boolean).flat()),
          zod
            .object({
              field: zod.literal(`${path}.rank`),
              op: zod.literal(Ops.equals),
              value: zod.union([zod.literal(null), zod.literal(undefined)])
            })
            .transform(() => [ArtifactScore.UNKNOWN])
        ])
      )
    })
    .transform(query => ({
      confidence: query.items.filter(Boolean).flat()
    }));
}

const AnalyticRankFilterConfig: FilterConfig<AnalyticRankKeys> = {
  defaults: { default: () => ({ confidence: Object.values(ArtifactScore) }) },
  supportedTopics: [ArtifactType.Analytic, ArtifactType.Marker, ArtifactType.Session],
  component: AnalyticRankFilter,
  toQuery: {
    [ArtifactType.Analytic]: toQuery('ranks'),
    [ArtifactType.Session]: toQuery('recommended_analytic_ranks'),
    [ArtifactType.Marker]: () => null
  },
  fromQuery: {
    [ArtifactType.Analytic]: fromQuery('ranks'),
    [ArtifactType.Session]: fromQuery('recommended_analytic_ranks'),
    [ArtifactType.Marker]: fromQueryNoop({
      field: zod.union([zod.literal('ranks.rank'), zod.literal('recommended_analytic_ranks.rank')]),
      value: zod.array(zod.string())
    })
  }
};
export default AnalyticRankFilterConfig;
