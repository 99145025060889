import React from 'react';

import { useFormikContext } from 'formik';
import z from 'zod';

import { Alert, AlertTitle } from 'snap-ui/Alert';
import { Option } from 'snap-ui/Autocomplete';
import CircularProgress from 'snap-ui/CircularProgress';
import { FormDialog } from 'snap-ui/Dialog';
import { FieldsLayout } from 'snap-ui/Layout';

import { standardFormikBaseProps } from 'module/Form';
import AutocompleteFormik from 'module/Form/AutocompleteFormik';
import MultiCheckboxFormik from 'module/Form/MultiCheckboxFormix';
import TextFieldFormik from 'module/Form/TextFieldFormik';
import { useRequester } from 'module/May';
import { SyntaxViewer } from 'module/Widgets/SyntaxEditor/SyntaxEditor';

import { useAuth, useIntegrationCatalog } from 'provider';

import { FunctionalPermission } from 'types/auth';

import { ApiKeyScope, CreateApiKeyPayload, CreatedApiKey } from './ApiKeys.type';

type ApiKeyFormDialogProps = {
  createdApiKey?: CreatedApiKey;
  isLoading: boolean;
  apiError?: string;
  className?: string;
  onClose(): void;
  onSubmit: (key: CreateApiKeyPayload) => void;
  open: boolean;
};

function ApiKeyFormDialog(props: ApiKeyFormDialogProps): JSX.Element {
  const { defaultOrgId } = useAuth();

  return (
    <FormDialog
      className={props.className}
      DialogProps={{ open: props.open, onClose: props.onClose }}
      FormikConfig={{
        ...standardFormikBaseProps,
        initialValues: {
          name: '',
          organization_id: defaultOrgId,
          scopes: [],
          integrations: []
        },
        onSubmit: handleSubmit,
        zodSchema: z.object({
          name: z.string().min(1, 'Please enter a name'),
          organization_id: z.number().min(1, 'Please select an organization'),
          scopes: z.array(z.string()).min(1, 'Please select at least one scope')
        })
      }}
      SubmitProps={{
        children: props.isLoading ? <CircularProgress size={25} /> : 'Save',
        disabled: props.isLoading || !!props.createdApiKey
      }}
      title={props.createdApiKey ? 'New API Key Created' : 'Create a New API Key'}
    >
      {!!props.apiError && (
        <Alert severity='error'>
          <AlertTitle>Oops! Something went wrong.</AlertTitle>
          {props.apiError}
        </Alert>
      )}

      <FieldsLayout>
        <ApiKeyForm createdApiKey={props.createdApiKey} />
      </FieldsLayout>
    </FormDialog>
  );

  function handleSubmit(key: CreateApiKeyPayload): void {
    props.onSubmit(key);
  }
}

export default ApiKeyFormDialog;

const scopeOptions = [
  {
    value: ApiKeyScope.AnalyticDeployment,
    text: (
      <label>
        <b>Detection Deployment</b>
        <p>
          <em>Deploy detections to your environments with configured integrations</em>
        </p>
      </label>
    ),
    permission: FunctionalPermission.DeployAnalytic
  },
  {
    value: ApiKeyScope.BASTestResults,
    text: (
      <label>
        <b>Attack Simulations</b>
        <p>
          <em>
            Perform automated attack simulations in your own environment to validate deployed detections and controls
            effectiveness
          </em>
        </p>
      </label>
    ),
    permission: FunctionalPermission.TaskBASAgent
  },
  {
    value: ApiKeyScope.LocalSessionCapture,
    text: (
      <label>
        <b>Local Threat Capture</b>
        <p>
          <em>
            Capture logs, videos, and keystrokes from threats you run in your own environment with the CapAttack Agent
          </em>
        </p>
      </label>
    ),
    permission: FunctionalPermission.CreateSession
  },
  {
    value: ApiKeyScope.REST,
    text: (
      <label>
        <b>REST</b>
        <p>
          <em>Use the REST API to programmatically interact with SnapAttack</em>
        </p>
      </label>
    ),
    permission: FunctionalPermission.CreateAPIKey
  }
];

export function ApiKeyForm({ createdApiKey }: { createdApiKey: CreatedApiKey }): JSX.Element {
  const { defaultOrgId } = useAuth();
  const { values, setValues, resetForm } = useFormikContext<CreateApiKeyPayload>();
  const { integrations } = useIntegrationCatalog();
  const requester = useRequester();

  React.useEffect(() => {
    if (createdApiKey) resetForm();
  }, [createdApiKey, defaultOrgId, resetForm, setValues]);

  const isDeploymentKey = values.scopes.includes(ApiKeyScope.AnalyticDeployment);
  const integrationOptions: Option[] = integrations.preferred_org_aware
    .filter(integration => integration.organization_id === values.organization_id && !integration.is_registered)
    .map(integration => ({
      value: integration.guid,
      content: integration.name
    }));

  React.useEffect(() => {
    // unset integrations if not an AnalyticDeployment key
    if (!values.scopes.includes(ApiKeyScope.AnalyticDeployment)) {
      setValues({ ...values, integrations: [] });
    }
  }, [values.scopes]); // eslint-disable-line react-hooks/exhaustive-deps

  const filteredScopeOptions = React.useMemo(() => {
    return scopeOptions.filter(scope => requester(scope.permission));
  }, [requester]);

  return (
    <>
      {createdApiKey ? (
        <>
          <p>Here&apos;s your new API key. You won&apos;t be able to see it again, so save it in a safe place!</p>
          <SyntaxViewer value={createdApiKey.secret_key} />
        </>
      ) : (
        <>
          <TextFieldFormik label='Note' name='name' multiline minRows={1} helperText='What is this API key for?' />
          <MultiCheckboxFormik label='Scope' name='scopes' options={filteredScopeOptions} />
          {isDeploymentKey && (
            <AutocompleteFormik
              name='integrations'
              label='Environments'
              options={integrationOptions}
              multiple
              disableUserAdditions
            />
          )}
        </>
      )}
    </>
  );
}
