import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';

import { OSName } from 'module/BAS/BAS.type';
import { CollectionDiscriminator } from 'module/Collection/Collection.type';
import { IOCArtifact, IOCType } from 'module/IOC/IOC.type';
import { Discriminator, TagGroup } from 'module/Tag';

import { StrictReactNode } from './core';

export type Ident = number;
export type Guid = string;
export type Timestamp = string;
export type Url = string;

/** An Artifact state of visibility */
export enum Visibility {
  Draft = 'Draft',
  Published = 'Published',
  Deleted = 'Deleted'
}

export interface Named {
  name: string;
}

export interface Tracked {
  guid: Guid;
}

export type Tasked = {
  task_id: Guid;
};

/**
 * The smallest unit returned with search endpoint.
 */
export interface Artifact
  extends Tracked,
    Named,
    Partial<TagGroup>,
    Omit<Partial<IOCArtifact>, 'name' | 'guid' | 'type'> {
  short_id: string;
  description?: string;
  ai_description?: string;
  organization_id: number;
  visibility: Visibility;
  modified: string;
  creation: string;
  created_by_id: number;
  modified_by_id: number;
  owner_id?: number;
  small_image?: string;
  large_image?: string;
  logsource: string;
  preview: boolean;

  /** @deprecated  */
  actors?: number[];
  /** @deprecated  */
  tags?: number[];
  /** @deprecated  */
  attacks?: number[];
  /** @deprecated  */
  attack_ancestors?: number[];

  action_aliases?: string[];
  actor_aliases?: string[];
  software_aliases?: string[];
  attack_aliases?: string[];
  vulnerability_aliases?: string[];

  // Added by ThreatTab when an item is referenced
  readonly?: boolean;

  // Detection & Threat fields
  severity?: ArtifactScore;
  is_native?: boolean;
  raw?: string;
  actions?: number[];
  actions_aliases?: string[];
  actions_names?: string[];

  // Detection specific fields
  analytic_compilation_targets?: number[];
  rank?: ArtifactScore;
  author?: string;
  recompile_in_progress?: boolean;

  // Threat fields
  /** @deprecated  */
  linked_actors?: number[];
  /** @deprecated  */
  linked_attacks?: number[];
  /** @deprecated  */
  linked_tags?: number[]; // Contains all tag ids; filter by Discriminator after mapping if necessary

  // Intel fields
  url?: string;
  original_author?: string;

  /**
   * polymorphic
   * with Tag Model - type represents the Discriminator.
   * with Collection Model - Hybrid 0r Static (Dynamic are saved filters)
   */
  type?: Discriminator | typeof CollectionDiscriminator.Hybrid | typeof CollectionDiscriminator.Static | IOCType;

  // Validation fields
  platforms?: OSName[];
  has_threat_capture?: boolean;
  simulated?: boolean;

  // highlight fields: string[] of size 1
  actor_aliases_idx_highlights?: string[];
  software_aliases_idx_highlights?: string[];
  vulnerability_aliases_idx_highlights?: string[];
  description_highlights?: string[];
  name_highlights?: string[];
}

export type LinkedThreatIntelligence = Named &
  Tracked & {
    uri: string;
  };

export type Identity = Named &
  Tracked & {
    id: Ident;
  };

/**
 * Ideally, we would have a single common property name provided by BE.
 * e.g. { score: "XXX", organization_id: 1 } or { value: "XXX", organization_id: 1 }
 * This would keep FE sniffing to a minimum. Consider a future refactor in collaboration
 * with BE folks.
 */
export interface ArtifactScoreDetail {
  rank?: ArtifactScore;
  severity?: ArtifactScore;
  organization_id: number;
}

export interface AnalyticSnapScore {
  score?: number; // decimal from 0-1 - represents global score
  deploy_score?: number; // decimal from 0-1
  hunt_score?: number; // decimal from 0-1
  organization_id: Ident;
}

export interface ReferenceCounts {
  threat_count: number;
  session_count: number;
  analytic_count: number;
  bas_script_count: number;
  reference_count: number;
}

export enum ArtifactType {
  Intel = 'intelligence',
  DeprecatedIntel = 'threat_intelligence',
  Session = 'threat',
  DeprecatedSession = 'session',
  Analytic = 'detection',
  DeprecatedAnalytic = 'analytic',
  AttackScript = 'attack_script',
  DeprecatedAttackScript = 'bas_script',
  Indicator = 'indicator',
  Marker = 'marker',
  Collection = 'collection',
  AttackTag = 'tags',
  Landing = 'landing',
  AttackPlan = 'attack_simulation',
  Hunt = 'hunt'
}

export function isArtifactType(name: string): name is ArtifactType {
  return Object.values(ArtifactType).includes(name as ArtifactType);
}

export enum ArtifactScore {
  HIGHEST = 'Highest',
  HIGH = 'High',
  MEDIUM = 'Medium',
  LOW = 'Low',
  LOWEST = 'Lowest',
  UNKNOWN = 'Unknown'
}

export enum DefensivePosture {
  ANALYTIC_GAP = 'detectionGaps',
  UNVALIDATED = 'unvalidatedDetections',
  VALIDATED = 'validatedDetections',
  UNTESTED = 'untestedDetections',
  UNDETECTED = 'undetectedThreats'
}

export const DefensivePostureQueryFields = {
  [DefensivePosture.ANALYTIC_GAP]: 'gap',
  [DefensivePosture.UNVALIDATED]: 'unvalidated',
  [DefensivePosture.VALIDATED]: 'validated',
  [DefensivePosture.UNTESTED]: 'untested',
  [DefensivePosture.UNDETECTED]: 'undetected'
};

export enum DisplayState {
  Error = 'error',
  Loading = 'loading',
  Ready = 'ready',
  Saving = 'saving',
  Deleting = 'deleting'
}

export type Vote = {
  upvote: boolean;
  downvote: boolean;
};

export enum VoteName {
  UP = 'up',
  DOWN = 'down'
}

export type UploadResult = {
  filename: string;
  message?: string;
  success: boolean;
};

export const BooleanString = {
  False: 'false',
  True: 'true'
} as const;

export type BooleanString = (typeof BooleanString)[keyof typeof BooleanString];

export function isBooleanString(value: string): value is BooleanString {
  return Object.values(BooleanString).includes(value as BooleanString);
}

export type SuccessResponse = {
  success: boolean;
  message: string;
};

export type UserPublic = {
  guid: Guid;
  id: Ident;
  name: string;
};

export type File = {
  guid: Guid;
  threat_intelligence_id: string;
  file_name: string;
};
export type SavedFileMetadata = File;

/**
 * Returns the property keys where its corresponding values type matches `C`.
 *
 * @template T the base type
 * @template C the conditional type
 *
 * @example
 * FilteredKeys<{ foo: 'bar', bar: 1, baz: 2 }, number> // 'bar' | 'baz'
 */
export type FilteredKeys<T, C> = {
  [K in keyof T]: T[K] extends C ? K : never;
}[keyof T];

/** Returns the all the possible values of a type */
export type ValueOf<T> = T[keyof T];

/**
 * Form fields might be valid on client side but fail server side. Like custom tags containing 'T108'
 */
export type FieldError = {
  message: string;
  field?: string;
};

export type CeleryTaskResponse = {
  task_id: Guid;
};

export enum CeleryState {
  success = 'SUCCESS',
  complete = 'COMPLETE',
  failed = 'FAILED',
  in_progress = 'IN PROGRESS',
  pending = 'PENDING',
  retrying = 'RETRYING'
}

export type TaskStatus<T, S = CeleryState> = {
  creation: string;
  modified: string;
  task_id: Guid;
  status: S;
  output: T;
};

/**
 * If provided, will replace the default button. Ignores simple flag.
 */
export type IconButtonRenderProps = {
  children?(props: { onClick(): void; icon: FontAwesomeIconProps['icon']; tooltip?: string }): StrictReactNode;
};

export type URLState = Record<string, string | string[]>;

export type PageParams = {
  page: number;
  size: number;
};
