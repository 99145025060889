import React from 'react';

import { faCog, faPowerOff, faRefresh } from '@fortawesome/pro-solid-svg-icons';
import { useField } from 'formik';
import isEmpty from 'lodash/isEmpty';
import { Link } from 'react-router-dom';

import Alert from 'snap-ui/Alert';
import { IconButton } from 'snap-ui/Button';
import Icon from 'snap-ui/Icon';
import Tooltip from 'snap-ui/Tooltip';

import { AgentInterface } from 'aso/useAgents';

import Path from 'constants/paths';

import AgentLabel, { getOSDisplayName } from 'module/BAS/AgentLabel';
import AutocompleteFormik from 'module/Form/AutocompleteFormik';

import { Status } from 'storage';

import { OSName } from '../BAS.type';
import { FieldContainer, NegativeAgentMessage, PositiveAgentMessage } from './layout';

type AgentFieldProps = {
  agentInterface: AgentInterface;
  name: string;
  target_platforms?: OSName[];
};

export default function AgentField({ agentInterface, name, target_platforms }: AgentFieldProps) {
  const { filteredAgents, refreshAgents, status } = agentInterface;

  const [field] = useField(name);
  const isPending = status === Status.pending;

  const options = filteredAgents
    .filter(agent => target_platforms.includes(agent.os_name))
    .map(agent => ({
      content: <AgentLabel agent={agent} />,
      label: agent.name || agent.host_metadata?.host_name,
      value: agent.guid,
      status: agent.is_online
    }));

  const selectedOption = options.find(option => option.value === field.value);

  return (
    <>
      <FieldContainer>
        <AutocompleteFormik
          className='input'
          disableClearable
          disableUserAdditions
          label='Target Machine'
          name={name}
          options={options}
          disabled={isPending || isEmpty(options)}
        />

        {refreshAgents && (
          <Tooltip arrow wrap title='Refresh agent list'>
            <IconButton aria-label='Refresh agent list' disabled={isPending} onClick={() => refreshAgents()}>
              <Icon icon={faRefresh} spin={isPending} color='white' />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip arrow title='View attack agent settings'>
          <IconButton aria-label='View attack agent settings' component={Link} to={Path.Agents}>
            <Icon icon={faCog} color='white' />
          </IconButton>
        </Tooltip>
      </FieldContainer>
      {isEmpty(options) && !isPending && (
        <Alert severity='error'>
          You must first download and configure a{' '}
          {target_platforms ? target_platforms.map(platform => getOSDisplayName(platform)).join('/') : ''} agent to run
          this attack simulation.
          <br />
          <Link to={`${Path.Setting}/agents`}>Go to the settings page</Link>.
        </Alert>
      )}
      {!isEmpty(selectedOption) && (
        <>
          {selectedOption?.status ? (
            <PositiveAgentMessage>
              <Icon icon={faPowerOff} />
              This agent is online.
            </PositiveAgentMessage>
          ) : (
            <NegativeAgentMessage>
              <Icon icon={faPowerOff} />
              The agent is offline and may not run the attack until it is restarted
            </NegativeAgentMessage>
          )}
        </>
      )}
    </>
  );
}
