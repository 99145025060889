import React from 'react';

import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import { Link } from 'react-router-dom';

import { SlimAccordion } from 'snap-ui/Accordion';
import { Alert, AlertTitle, InlineAlert } from 'snap-ui/Alert';
import { GridRowParams } from 'snap-ui/DataGrid';
import { FormDialog } from 'snap-ui/Dialog';
import Icon from 'snap-ui/Icon';
import Placeholder from 'snap-ui/Placeholder';

import { CancelToken } from 'apis/snapattack';

import useDetectionQuery from 'aso/useDetectionQuery';

import Path from 'constants/paths';

import { ApiError } from 'module/ApiError';
import { buildCuratedFilter } from 'module/Curation/Curation.service';
import AutocompleteFormik from 'module/Form/AutocompleteFormik';
import CronJobFormik from 'module/Form/CronJobFormik';
import DataGridFormik from 'module/Form/DataGridFormik';
import { standardFormikBaseProps } from 'module/Form/Form.const';
import RadioGroupFormik from 'module/Form/RadioGroupFormik';
import RjsfFormik from 'module/Form/RjsfFormik';
import TextFieldFormik from 'module/Form/TextFieldFormik';
import { HuntableIntegration, IntegrationAction } from 'module/Integration/Integration.type';
import {
  getIntegrationTypeWithMostParameters,
  getSpecificJobSchema,
  validateSearchAndCronHasNoGap
} from 'module/Integration/Integration.util';
import { integrationHasActionableTarget } from 'module/Integration/useIntegrations';
import useJobSchema from 'module/Integration/useJobSchema';
import { useScheduledJobs } from 'module/Integration/useScheduledJobs';
import OverwatchOrganizationsAutocomplete from 'module/Widgets/OverwatchOrganizationsAutocomplete';

import { useAuth, useIntegrationCatalog, usePushSnack } from 'provider';

import { Status, useAsync } from 'storage';

import { Guid } from 'types/common';
import { Query } from 'types/filter';

import { renderValueWithLabel } from 'utilities/TextUtils';
import { FULL_TIMESTAMP, TimeInSeconds, formatCustomNow } from 'utilities/TimeUtils';

import {
  FREQUENCY_TYPE,
  WEEKLY,
  maxHuntSearchWindowOptions,
  maxRecurringHuntSearchWindowOptions,
  maxResultsPerQuery
} from '../Job.const';
import { postAsyncJobs } from '../Job.service';
import { HuntFormInput, HuntFormSchema, JobType } from '../Job.type';
import HuntStyle from './Hunt.style';

export const HuntFormInitial = {
  name: '',
  max_search_window_seconds: TimeInSeconds.sevenDays.toString(),
  max_results_per_query: maxResultsPerQuery,
  frequency: FREQUENCY_TYPE.Once,
  cron_trigger: WEEKLY,
  selectedIntegrations: []
};

export type AllowedJobTypes = JobType.Hunt | JobType.Rank | JobType.IOC;

export type Hunt = {
  onClose(): void;
  isOpen: boolean;
  jobType: AllowedJobTypes;
  query?: Query;
  selections?: Guid[];
  selectedIntegrations?: Guid[];
  jobName?: string;
};

const ACTIONS = [IntegrationAction.Hunt];
/**
 * @deprecated This should not be used directly. Use HuntInterface => useHuntInterfaceFactory
 * @param selections required when `jobType === JobType.IOC`
 */
export function Hunt({ onClose, isOpen, jobType, query, selections = [], selectedIntegrations = [], jobName }: Hunt) {
  const { defaultOrgId, permission } = useAuth();
  const pushSnack = usePushSnack();

  const preferredOrg = permission.find(org => org.id === defaultOrgId);

  const { status: integrationStatus, overwatch, integrations } = useIntegrationCatalog();
  const { canOverwatch, organizationIDs } = overwatch;
  const { getIntegrationsByOrgIDs } = integrations;

  const orgIDs = React.useMemo(() => organizationIDs.map(o => o.toString()), [organizationIDs]);
  const { isLoading, refresh, items, total: deployableDetectionTotal } = useDetectionQuery(query, orgIDs);

  const { task, status: actingStatus, errorProps } = useAsync();
  const { data: jobSchemaCatalog, status: jobSchemaStatus } = useJobSchema();
  const { createJobSchedule } = useScheduledJobs();

  const hasOrganizationIDs = organizationIDs.length > 0;
  const hasSelections = selections && selections.length > 0;
  const loading = integrationStatus === Status.pending || isLoading;
  const isActing = [actingStatus, integrationStatus, jobSchemaStatus].includes(Status.pending) || isLoading;

  const title = jobType === JobType.Rank ? 'Confidence Tailoring' : 'Hunt';
  const displayTitle = `${title} ${selections?.length || (loading ? '' : deployableDetectionTotal?.toLocaleString())} ${
    jobType === JobType.IOC ? 'IOC' : 'Detection'
  }${(selections?.length || deployableDetectionTotal) === 1 ? '' : 's'}`;

  const choices: HuntableIntegration[] = React.useMemo(() => {
    return getIntegrationsByOrgIDs(organizationIDs)
      .filter(integration => integrationHasActionableTarget(integration, [IntegrationAction.Hunt]))
      .map(integration => {
        const huntableDetections = items.filter(
          i => i.preview === false && i.huntable_integrations?.some(d => d.guid === integration.guid)
        ).length;
        return {
          ...integration,
          huntableDetections,
          incompatible: items.length - huntableDetections
        };
      });
  }, [getIntegrationsByOrgIDs, items, organizationIDs]);

  const sortedChoices = (choices || []).slice().sort((a, b) => Number(b.ioc_huntable) - Number(a.ioc_huntable));

  const jobSchema = getSpecificJobSchema(
    jobSchemaCatalog,
    getIntegrationTypeWithMostParameters(choices, jobSchemaCatalog)
  );
  if (!isEmpty(jobSchema))
    jobSchema.properties = omit(jobSchema.properties, ['max_results_per_query', 'max_search_window_seconds']);

  const handleHunt = async (values: HuntFormInput) => {
    const extra_parameters = {
      ...values.extra_parameters,
      max_results_per_query: values.max_results_per_query,
      max_search_window_seconds: Number(values.max_search_window_seconds)
    };

    if (values.frequency === FREQUENCY_TYPE.Recurring) {
      const payload = {
        name: values.name,
        criteria: 'All',
        extra_parameters,
        analytic_filter:
          jobType === JobType.IOC ? undefined : buildCuratedFilter(hasSelections ? selections : items.map(i => i.guid)),
        cron_trigger: values.cron_trigger,
        type: jobType
      };
      const jobSchedulesPromises = values.selectedIntegrations.map(integration => {
        return createJobSchedule(integration, payload);
      });

      return Promise.all(jobSchedulesPromises).then(() => onClose());
    } else {
      const payload = {
        name: values.name,
        type: jobType,
        guids: hasSelections ? selections : items.map(i => i.guid),
        parameters: extra_parameters,
        integrations: values.selectedIntegrations,
        organization_id: canOverwatch ? defaultOrgId : undefined
      };
      omit(payload, ['cron_trigger', 'search_latest_time', 'frequency']);
      task(postAsyncJobs(payload)).then(jobGroup => {
        if (jobGroup?.guid) {
          pushSnack(
            <>
              {title} job created! <Link to={`${Path.Hunt}/${jobGroup.guid}`}>Click here</Link> to see the results.
            </>,
            'info',
            'center',
            'bottom',
            30000
          );
        }
        onClose();
      });
    }
  };

  React.useEffect(() => {
    const cancelSource = CancelToken.source();
    if (isOpen && [JobType.Hunt, JobType.Rank].includes(jobType)) refresh(cancelSource);
    return () => cancelSource.cancel();
  }, [isOpen, jobType, refresh]);

  const noIntegrationsAvailable = choices.length === 0 && !isActing;

  return (
    <>
      <FormDialog
        title={displayTitle}
        DialogProps={{ open: isOpen, onClose, maxWidth: canOverwatch ? 'md' : 'sm' }}
        confirmMessage={`Do you want to hunt with ${renderValueWithLabel(
          selections?.length || deployableDetectionTotal,
          jobType === JobType.IOC ? 'IOC' : 'detection'
        )}?`}
        SubmitProps={{
          children: 'Submit',
          disabled: isActing || noIntegrationsAvailable || !hasOrganizationIDs,
          startIcon: isActing && hasOrganizationIDs && <Icon.SpinnerProgress />
        }}
        FormikConfig={{
          ...standardFormikBaseProps,
          validateOnChange: true,
          initialValues: {
            ...HuntFormInitial,
            name: `${jobName || title} ${formatCustomNow(FULL_TIMESTAMP)}`,
            selectedIntegrations
          },
          nestedRjsf: { extra_parameters: jobSchema },
          zodSchema: HuntFormSchema,
          onSubmit: handleHunt
        }}
      >
        {({ values, setErrors, errors }) => {
          if (values.frequency === FREQUENCY_TYPE.Recurring && errors) {
            const { cron_trigger: cronString, max_search_window_seconds: searchWindow } = values;
            validateSearchAndCronHasNoGap(cronString, searchWindow, setErrors, errors);
          }
          return (
            <HuntStyle>
              {noIntegrationsAvailable && (
                <Alert severity='info' className='Hunt-createIntegration'>
                  <AlertTitle>No integrations available</AlertTitle>
                  <Link to={Path.Integrations}>Create integrations here</Link>
                </Alert>
              )}
              <ApiError {...errorProps} />
              <div className='Hunt-static-params'>
                <OverwatchOrganizationsAutocomplete
                  actions={ACTIONS}
                  elevated
                  multiple
                  required
                  helperText={`Select organization(s) to hunt. The hunt results will only be visible to ${preferredOrg.name}.`}
                />

                <TextFieldFormik required name='name' label='Job Name' className='Hunt-name' />

                <AutocompleteFormik
                  required
                  label='Max Search Window'
                  name='max_search_window_seconds'
                  disableUserAdditions
                  options={
                    values.frequency === FREQUENCY_TYPE.Once
                      ? [...maxHuntSearchWindowOptions]
                      : [...maxRecurringHuntSearchWindowOptions]
                  }
                  disabled={isActing}
                />
                <TextFieldFormik
                  required
                  label='Max Results Per Query'
                  name='max_results_per_query'
                  type='number'
                  disabled={isActing}
                />
              </div>
              {jobType === JobType.Hunt && (
                <>
                  <div className='frequency'>
                    <span>Frequency</span>
                    <RadioGroupFormik
                      name='frequency'
                      row
                      disabled={isActing}
                      options={[
                        { label: FREQUENCY_TYPE.Once, value: FREQUENCY_TYPE.Once },
                        { label: FREQUENCY_TYPE.Recurring, value: FREQUENCY_TYPE.Recurring }
                      ]}
                    />
                  </div>
                  <div className='cron'>
                    {!isEmpty(errors) && errors?.['cron_trigger'] && (
                      <Alert severity='warning'>
                        <AlertTitle>{errors?.['cron_trigger']}</AlertTitle>
                      </Alert>
                    )}
                    {values.frequency === FREQUENCY_TYPE.Recurring && (
                      <CronJobFormik name='cron_trigger' label='Schedule Time' />
                    )}
                  </div>
                </>
              )}
              {hasOrganizationIDs ? (
                <>
                  <DataGridFormik
                    className='Hunt-grid'
                    loading={isActing}
                    name='selectedIntegrations'
                    checkboxSelection={!isActing}
                    disableColumnSelector
                    disableColumnMenu
                    isRowSelectable={
                      jobType === JobType.IOC
                        ? (params: GridRowParams) => params.row.ioc_huntable
                        : (params: GridRowParams) => params.row.huntableDetections > 0
                    }
                    initialState={{
                      columns: {
                        columnVisibilityModel: {
                          name: true,
                          huntableDetections: jobType !== JobType.IOC,
                          incompatible: jobType !== JobType.IOC
                        }
                      }
                    }}
                    columns={[
                      {
                        field: 'name',
                        headerName: 'Integration',
                        hideable: false,
                        flex: 1,
                        renderCell: () => (isActing ? <Placeholder variant='text' width={250} /> : undefined)
                      },
                      {
                        field: 'huntableDetections',
                        headerName: 'Compatible',
                        align: 'right',
                        hideable: false,
                        renderCell: p =>
                          isActing ? (
                            <Placeholder variant='text' width={100} />
                          ) : (
                            <InlineAlert severity='success' icon={p.value === 0 ? false : undefined}>
                              {p.value}
                            </InlineAlert>
                          )
                      },
                      {
                        field: 'incompatible',
                        headerName: 'Incompatible',
                        align: 'right',
                        hideable: false,
                        renderCell: p =>
                          isActing ? (
                            <Placeholder variant='text' width={100} />
                          ) : p.value > 0 ? (
                            <InlineAlert severity='warning'>{p.value}</InlineAlert>
                          ) : null
                      }
                    ]}
                    rows={jobType === JobType.IOC ? sortedChoices : choices}
                    getRowId={row => row.guid}
                  />
                  {!isEmpty(jobSchema) && (
                    <SlimAccordion
                      className='advancedConfigurationToggle'
                      disabled={isActing}
                      details={<RjsfFormik name='extra_parameters' schema={jobSchema} />}
                      summary='Advanced Configuration'
                    />
                  )}
                </>
              ) : (
                <Alert severity='info' className='Hunt-noCompatibleIntegration'>
                  <AlertTitle>Select an organization to see compatible integrations</AlertTitle>
                </Alert>
              )}
            </HuntStyle>
          );
        }}
      </FormDialog>
    </>
  );
}
