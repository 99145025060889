import React from 'react';

import { IDETranslationStateProvider } from 'module/IDE';
import { Page } from 'module/Layout/Styled';

import { VersionContainer } from '../Analytic.style';
import { AnalyticRouterProps } from '../Analytic.type';
import { useAnalyticCatalog } from '../core/AnalyticProvider';
import { useAnalyticVersionCatalog } from '../core/AnalyticVersionProvider';
import AnalyticVersionHero from './AnalyticVersionHero';
import AnalyticVersionList from './AnalyticVersionList';
import AnalyticVersionOverview from './AnalyticVersionOverview';
import AnalyticVersionTranslation from './AnalyticVersionTranslation';

export default function AnalyticVersionComponent(props: AnalyticRouterProps): JSX.Element {
  const versionId = props.match.params.versionId;
  const [{ supplemental }] = useAnalyticCatalog();
  const { status, versions } = useAnalyticVersionCatalog();
  const version = versions.find(version => version.id.toString() === versionId);

  return (
    <IDETranslationStateProvider>
      <VersionContainer>
        <AnalyticVersionList
          open={true}
          versions={versions}
          versionStatus={status}
          guid={supplemental?.guid}
          parentGuid={supplemental?.parent?.guid}
        />
        <div className='Version-content'>
          <AnalyticVersionHero version={version} versionStatus={status} />
          <Page className='Version-details' matchHero>
            <AnalyticVersionOverview version={version} versionStatus={status} />
            <AnalyticVersionTranslation version={version} versionStatus={status} />
          </Page>
        </div>
      </VersionContainer>
    </IDETranslationStateProvider>
  );
}
