import React from 'react';

import Icon from 'snap-ui/Icon';
import Typography from 'snap-ui/Typography';
import { styled } from 'snap-ui/util';

import { IDEStatusState } from 'module/Analytic/core/IDE/IDEStatus';
import { useIDEState } from 'module/IDE';
import { ToggleLogPinButton } from 'module/IDE/core/ToggleLogPinButton';

import { Guid } from 'types/common';
import { LogRecord } from 'types/ide';

import LogContent from './LogContent';
import { LogListContainer, Pager, LogToolbar as _LogToolbar } from './MatchingLog.widgets';

const LogToolbar = styled(_LogToolbar)`
  padding: ${p => p.theme.spacing(6)};
  align-items: center;
  justify-content: space-between;
`;

export function PreviewLogs(props: {
  data: { count: number; preview: LogRecord[] };
  onLogClick(key: string, value: string): void;
  onPinLogChange?(guid: Guid): void;
  pins: Guid[];
  status: IDEStatusState;
}) {
  const [current, setCurrent] = React.useState(0);
  const log = props.data.preview[current];
  const { ideState } = useIDEState();

  return (
    <LogListContainer>
      <LogToolbar>
        <Pager count={props.data.preview?.length} current={current} onChange={setCurrent} />
        <Typography variant='caption'>
          <Icon.Info /> only the first 100 test logs are returned
        </Typography>
        {!!props.onPinLogChange && (
          <ToggleLogPinButton
            pinned={props.pins?.includes(log?.row_id)}
            guid={log?.row_id}
            onClick={props.onPinLogChange}
            disabled={!log}
          />
        )}
      </LogToolbar>
      <LogContent log={log} highlightProcessor={ideState.highlightProcessor} onLogClick={props.onLogClick} />
    </LogListContainer>
  );
}
