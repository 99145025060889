import React from 'react';

import { GridColDef } from 'snap-ui/DataGrid';
import Placeholder from 'snap-ui/Placeholder';

import { AlphaFeature, useAlphaSetting } from 'module/AlphaFeatures';
import CompatibilityBadge from 'module/Analytic/core/CompatibilityBadge';
import LogsourceBadge from 'module/Analytic/core/LogsourceBadge';
import SnapScoreBadge, { useSnapScore } from 'module/Analytic/core/SnapScoreBadge';
import ValidationBadge from 'module/Analytic/core/ValidationBadge';
import { WorkflowBadge } from 'module/Analytic/core/WorkflowState';
import { DeploymentStatusChip } from 'module/Card/Card.widgets';
import { PosturePlaceholder } from 'module/Layout/Placeholders';
import { ScoreBadge } from 'module/Widgets/Badge';

import { useAuth, useFeedExtra } from 'provider';

import { Artifact, ArtifactScore, ArtifactType } from 'types/common';

import { getPreferredOrgScore } from 'utilities/ArtifactUtils';

import { AnalyticDefensePostureContainer, NameContainer, TagContainer } from './DisplayGrid.helper';
import { hitsComparator, scoreComparator, validationComparator } from './DisplayGrid.util';

export default function useGridColumnsDetection() {
  const { supplementalCatalog, supplementalPending, getSupplemental, getDetection } = useFeedExtra();
  const { defaultOrgId } = useAuth();
  const { enableAlpha: enableSnapScore } = useAlphaSetting(AlphaFeature.SnapScore);

  const getScore = useSnapScore();

  const GridColumnsDetection: (GridColDef<Artifact> & { hideToStart?: boolean })[] = React.useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Name',
        hideable: false,
        width: 500,
        renderCell: cell => <NameContainer artifact={cell.row} topic={ArtifactType.Analytic} value={cell.value} />
      },
      {
        field: 'score',
        headerName: 'Score',
        hideable: true,
        valueGetter: p => {
          const supplemental = supplementalCatalog[p.row.guid];
          return getScore(supplemental);
        },
        sortingOrder: ['asc', 'desc'],
        filterable: false,
        width: 80,
        renderCell: cell => {
          if (supplementalPending) return <SnapScoreBadge.Placeholder display='grid' />;
          return <SnapScoreBadge score={cell.value} display='grid' />;
        }
      },
      {
        field: 'validation',
        headerName: 'Validation',
        hideable: true,
        valueGetter: p => {
          const countObj = getDetection(p.row.guid);
          return countObj;
        },
        sortingOrder: ['asc', 'desc'],
        sortComparator: validationComparator,
        filterable: false,
        width: 140,
        renderCell: cell => {
          if (supplementalPending) return <Placeholder variant='text' width={140} />;

          if (!cell.value) return null;
          return (
            <ValidationBadge
              validated={cell.value.validated}
              unvalidated={cell.value.unvalidated}
              validated_gaps={cell.value.validated_gaps}
            />
          );
        }
      },
      {
        field: 'severity',
        headerName: 'Severity',
        hideable: true,
        valueGetter: p => {
          if (supplementalPending) return '';
          const supplemental = supplementalCatalog[p.row.guid];

          if (!supplemental) return p.row.severity || ArtifactScore.UNKNOWN;

          return getPreferredOrgScore(defaultOrgId, supplemental.severities, p.row.severity);
        },
        sortingOrder: ['asc', 'desc'],
        sortComparator: scoreComparator,
        filterable: false,
        width: 130,
        renderCell: cell => {
          if (supplementalPending) return <Placeholder variant='text' width={140} />;
          return <ScoreBadge name='SEVERITY' score={cell.value} />;
        }
      },
      {
        field: 'rank',
        headerName: 'Confidence',
        hideable: true,
        valueGetter: p => {
          if (supplementalPending) return '';
          const supplemental = supplementalCatalog[p.row.guid];

          if (!supplemental) return p.row.rank || ArtifactScore.UNKNOWN;
          return getPreferredOrgScore(defaultOrgId, supplemental.ranks, p.row.rank);
        },
        sortingOrder: ['asc', 'desc'],
        sortComparator: scoreComparator,
        filterable: false,
        width: 130,
        renderCell: cell => {
          if (supplementalPending) return <Placeholder variant='text' width={140} />;
          return <ScoreBadge name='CONFIDENCE' score={cell.value} />;
        }
      },
      {
        field: 'logsource',
        headerName: 'Logsource',
        hideable: true,
        sortable: true,
        filterable: true,
        width: 200,
        renderCell: cell => (
          <LogsourceBadge
            value={cell.value}
            hideIfMissing
            languageId={
              supplementalPending
                ? undefined
                : supplementalCatalog[cell.row.guid]?.source_analytic_compilation_target_id
            }
            display='grid'
          />
        )
      },
      {
        field: 'compatibility',
        headerName: 'Compatibility',
        hideable: true,
        width: 200,
        renderCell: cell => {
          const supplemental = getSupplemental(cell.row.guid);
          return (
            <CompatibilityBadge
              sourceLanguageId={supplemental?.source_analytic_compilation_target_id}
              compatibleLanguages={cell.row.analytic_compilation_targets}
            />
          );
        }
      },
      {
        field: 'deployed',
        headerName: 'Deployed',
        hideable: true,
        valueGetter: p =>
          getSupplemental(p.row.guid)?.deployments?.some(deployment => deployment.organization_id === defaultOrgId),
        sortingOrder: ['asc', 'desc'],
        filterable: false,
        width: 200,
        renderCell: cell => {
          if (supplementalPending) <Placeholder variant='text' width={80} />;
          const supplemental = supplementalCatalog[cell.row.guid];

          return cell.value ? <DeploymentStatusChip supplemental={supplemental} /> : null;
        }
      },
      {
        field: 'workflow',
        headerName: 'Workflow',
        hideable: true,
        valueGetter: p =>
          supplementalCatalog[p.row.guid]?.states?.find(state => state.organization_id === defaultOrgId)?.state,
        sortingOrder: ['asc', 'desc'],
        filterable: false,
        width: 125,
        renderCell: cell => {
          if (supplementalPending) return <Placeholder variant='text' width={100} />;
          return cell.value ? <WorkflowBadge state={cell.value} /> : null;
        }
      },
      {
        field: 'hits',
        headerName: 'Detection Hits',
        valueGetter: p => {
          const count = getDetection(p.row.guid);
          if (!count) return undefined;
          return count;
        },
        sortComparator: hitsComparator,
        sortingOrder: ['desc', 'asc'],
        filterable: false,
        hideable: true,
        hideToStart: true,
        width: 300,
        renderCell: cell => {
          if (!cell.value) return <PosturePlaceholder />;
          return <AnalyticDefensePostureContainer count={cell.value} headless />;
        }
      },
      {
        field: 'actions',
        headerName: 'NIST 800-53 Controls',
        sortable: false,
        filterable: false,
        hideable: true,
        width: 250,
        renderCell: cell => {
          if (!cell.row.actions) return undefined;

          return <TagContainer name={cell.row.name} action={cell.row.actions.map(nistId => nistId.toString())} />;
        }
      },
      {
        field: 'datasource_names',
        headerName: 'Data Sources / Components',
        sortable: false,
        filterable: false,
        hideable: true,
        width: 350,
        renderCell: cell => {
          if (!cell.row.datasource_names) return undefined;
          return <TagContainer includeDataSources name={cell.row.name} datasource={cell.row.datasource_names} />;
        }
      }
    ],
    [defaultOrgId, getDetection, getScore, getSupplemental, supplementalCatalog, supplementalPending]
  );

  return GridColumnsDetection.filter(col => (col.field === 'score' ? enableSnapScore : true));
}
