import React from 'react';

import classnames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';

import { AutocompleteProps } from 'snap-ui/Autocomplete';
import { IntegrationIcon } from 'snap-ui/Icon';
import { ListItemIcon, ListItemText } from 'snap-ui/List';

import {
  AutocompleteSelectedIconContainer,
  IntegrationAutocompleteOptionContainer,
  StyledAutocomplete
} from './Integration.style';
import { IntegrationOption } from './Integration.type';

export type { IntegrationOption };

export function AutoCompleteOptionContent({ type, text, warning }: { type: string; text: string; warning?: boolean }) {
  return (
    <IntegrationAutocompleteOptionContainer
      className={classnames({
        warning
      })}
    >
      <ListItemIcon>
        <AutocompleteSelectedIconContainer>
          <IntegrationIcon type={type} />
        </AutocompleteSelectedIconContainer>
      </ListItemIcon>
      <ListItemText>{text}</ListItemText>
    </IntegrationAutocompleteOptionContainer>
  );
}

export type IntegrationAutocompleteProps = Omit<AutocompleteProps, 'multiple' | 'options' | 'onChange' | 'value'> & {
  options: IntegrationOption[];
  onChange(value: string): void;
  value: string;
};

function IntegrationAutocomplete({
  className,
  options: _options,
  onChange,
  value,
  ...props
}: IntegrationAutocompleteProps) {
  const integrationOptions = React.useMemo(() => {
    const options = uniqBy(_options, 'value')
      .filter(o => !isEmpty(o))
      .map(opt => {
        return {
          ...opt,
          content: <AutoCompleteOptionContent type={opt.type} text={opt.label} warning={opt.warning ?? false} />
        };
      });
    return options.sort((a, b) => {
      const groupComparison = a.group?.toLocaleLowerCase().localeCompare(b.group?.toLocaleLowerCase());
      if (groupComparison !== 0) {
        return groupComparison;
      }
      return a.label.toLocaleLowerCase().localeCompare(b.label.toLocaleLowerCase());
    });
  }, [_options]);

  const selectedOption = integrationOptions.find(opt => opt.value === value);

  return (
    <StyledAutocomplete
      className={classnames('IntegrationAutocomplete', className)}
      disableUserAdditions
      onChange={onChange}
      options={integrationOptions}
      startAdornment={
        value ? (
          <AutocompleteSelectedIconContainer>
            <IntegrationIcon type={selectedOption?.type} />
          </AutocompleteSelectedIconContainer>
        ) : null
      }
      value={value || ''}
      {...props}
    />
  );
}

export default IntegrationAutocomplete;
