import React, { ReactElement } from 'react';

import { faCirclePlus, faFileAlt, faShareAlt } from '@fortawesome/pro-solid-svg-icons';
import get from 'lodash/get';
import includes from 'lodash/includes';
import map from 'lodash/map';
import pull from 'lodash/pull';
import size from 'lodash/size';
import split from 'lodash/split';

import Button from 'snap-ui/Button';
import Icon from 'snap-ui/Icon';
import Tooltip from 'snap-ui/Tooltip';
import Typography from 'snap-ui/Typography';
import { styled, useTheme } from 'snap-ui/util';

import { getAnalytic } from 'apis/resources/analytic';

import Path from 'constants/paths';
import { DETAIL_FIELDS, LOG_DETAILS_FIELDS, MAX_PREVALENCE, PREVALENCE_FIELDS } from 'constants/processGraph';

import useSplunkLink from 'hooks/useSplunkLink';

import Can from 'module/Can';
import { Detection } from 'module/Detection/Detection.type';
import AnalyticIcon from 'module/Icons/AnalyticIcon';
import AttackIcon from 'module/Icons/AttackIcon';
import UndetectedThreatIcon from 'module/Icons/UndetectedThreatIcon';
import UnvalidatedAnalyticIcon from 'module/Icons/UnvalidatedAnalyticIcon';
import { RedMarkerUpdatePayload, Session } from 'module/Session/Session.type';
import Badge from 'module/Widgets/Badge';
import CopyButton from 'module/Widgets/CopyButton';
import DisabledButton from 'module/Widgets/DisabledButton';
import RedMarkerPopup from 'module/Widgets/RedMarkerPopup';
import { MarkerPayload } from 'module/Widgets/RedMarkerPopup/MarkerForm';

import { useAuth } from 'provider';

import { checkContentPermission, checkTaskPermission } from 'services/authService';
import {
  getMarkers,
  isBlueMarkerLonely,
  isRedMarkerLonely,
  sortSelectedBlueMarker
} from 'services/processGraphService';

import { ContentPermission, FunctionalPermission } from 'types/auth';
import { Artifact, ArtifactScore, Guid, Ident } from 'types/common';
import { CombinedCompositeMarker, RedMarkerExtended } from 'types/marker';
import { BadgeType, NodeDataType } from 'types/progressGraph';

import { getPreferredOrgScore } from 'utilities/ArtifactUtils';
import { splunkDashboardUrl } from 'utilities/SplunkUtils';

import SidebarPanel from './SidebarPanel';

type Props = {
  createAnalytic: (rowId, hostname) => void;
  selectedNode: NodeDataType;
  composite: CombinedCompositeMarker;
  detection: Detection;
  url: string;
  onMarkerUpdate: (marker: MarkerPayload) => Promise<void>;
  onMarkerDelete: (markerId: Ident, nodeData: NodeDataType) => Promise<void>;
  session: Session;
  hostname: string;
  onMarkerSave: (payload: MarkerPayload, nodeData: NodeDataType) => Promise<void>;
  setTime: (min: number, max: number) => void;
};

export const StyledNodeSidebar = styled('div')`
  .NodeSidebar-actions {
    display: flex;
    align-items: center;
  }

  .sideBar-title {
    font-size: 1.25rem;
    color: #bebebe;
    min-width: 160px;
  }
  .sidebar-section {
    display: flex;
    align-items: flex-start;
    gap: ${p => p.theme.spacing(1)};
    color: #bebebe;

    .title {
      width: 270px;
      font-size: 1.25rem;
    }
    .name {
      font-size: 0.875rem;
      width: 20px;
      display: flex;
      align-items: center;
      width: 270px;
      padding-right: ${p => p.theme.spacing(3)};

      .title-name {
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
      }

      .non-domino-icon {
        min-width: 25px;
      }
      i.blue.icon {
        height: auto;
        margin-left: -1px;
        margin-right: 9px;
      }
      &.attack {
        display: flex;
        color: ${p => p.theme.palette.common.white};
      }
    }
    .severity {
      min-width: 90px;
    }
  }
`;

const NodeSidebar = ({
  selectedNode,
  composite,
  detection,
  onMarkerSave,
  url,
  onMarkerUpdate,
  onMarkerDelete,
  session,
  hostname,
  createAnalytic,
  setTime
}: Props): ReactElement => {
  const { palette } = useTheme();
  const [activeIndices, setActiveIndices] = React.useState<number[]>([0, 1, 2, 3, 4, 5]);
  const [analytic, setAnalytic] = React.useState<Artifact>(null);
  const { hostLogDashboard } = useSplunkLink();
  const { permission, defaultOrgId } = useAuth();

  const selectedNodeBluePayload = React.useMemo(() => {
    const blue = sortSelectedBlueMarker(selectedNode, composite.blue);
    const first = blue.length > 0 ? blue[0] : null;
    return { blue, first };
  }, [selectedNode, composite.blue]);

  const selectedNodeRedPayload = React.useMemo(() => {
    const red = getMarkers(selectedNode, composite.red);
    const first = red.length > 0 ? red[0] : null;
    return { red, first };
  }, [selectedNode, composite.red]);

  function handleAccordionClick(titleProps): void {
    const index = titleProps;
    const currentIndices = [...activeIndices];
    if (includes(activeIndices, index)) {
      pull(currentIndices, index);
      setActiveIndices(currentIndices);
    } else {
      currentIndices.push(index);
      setActiveIndices(currentIndices);
    }
  }

  const canEdit = checkContentPermission(session, ContentPermission.Edit);
  const canSplunk = checkTaskPermission(permission, FunctionalPermission.SplunkUIAccess);
  const canCreate = checkTaskPermission(permission, FunctionalPermission.CreateAnalytic);

  const renderRedMarker = (marker: RedMarkerExtended) => {
    const star = isRedMarkerLonely(marker.id, detection) ? <UndetectedThreatIcon /> : <AttackIcon />;
    return canEdit ? (
      <RedMarkerPopup
        hit={marker}
        offset={get(selectedNode, 'start_offset', 0) / 1000}
        startTime={selectedNode.UtcTime}
        onAdd={({ attack_names, description, severity }) =>
          handleUpdateMarker(marker, { attack_names, description, severity })
        }
        onDelete={() => handleDeleteMarker(marker.id)}
        detectionName={marker.lonely ? 'UNDETECTED' : 'DETECTED'}
      />
    ) : (
      star
    );
  };

  const SIDEBAR_PANELS = [
    {
      title: 'Details',
      fields: DETAIL_FIELDS,
      values: selectedNode,
      timeFields: ['start_offset'],
      fieldActions: {
        UtcTime: (value: string): void => {
          const time = new Date(split(value, '.')[0]).getTime();
          setTime(time - 5000, time + 5000);
        }
      },
      actionHelpers: {
        UtcTime: 'Set time filter to 10 second range centered on this node',
        md5: 'Open hash in VirusTotal'
      }
    },
    {
      renderTitle: (): ReactElement =>
        selectedNodeBluePayload.blue.length === 0 ? null : (
          <>
            {/* style prop used as className is not overriding the Grid margin-right */}
            <div className='sidebar-section'>
              <Typography className='title' variant='h4'>
                Detections
              </Typography>
              <span className='severity'>Severity</span>
              <span className='confidence'>Confidence</span>
            </div>
          </>
        ),
      renderContent: (): ReactElement =>
        selectedNodeBluePayload.blue.length === 0 ? null : (
          <>
            {map(selectedNodeBluePayload.blue, blueMarker => {
              const firstAnalytic = blueMarker.analytics[0];
              const rank = getPreferredOrgScore(defaultOrgId, firstAnalytic.ranks, firstAnalytic.rank);
              const severity = getPreferredOrgScore(defaultOrgId, firstAnalytic.severities, firstAnalytic.severity);
              return (
                <Tooltip
                  placement='top-start'
                  wrap
                  onOpen={() => retrieveAnalytic(get(blueMarker, ['analytics', 0, 'guid']))}
                  onClose={(): void => setAnalytic(null)}
                  key={`${blueMarker.analytic_compilation_id}-${blueMarker.event.id}`}
                  title={
                    !analytic ? (
                      <>
                        <Icon.SpinnerProgress />
                        <span>Loading</span>
                      </>
                    ) : (
                      <div>
                        <span> {analytic.name}</span>
                        <span> {analytic.description}</span>
                      </div>
                    )
                  }
                >
                  <div className='sidebar-section'>
                    <div className='name'>
                      {isBlueMarkerLonely(blueMarker.row_id, detection) ? (
                        <UnvalidatedAnalyticIcon />
                      ) : (
                        <AnalyticIcon />
                      )}
                      <a
                        className='title-name'
                        href={`${Path.Detection}/${get(blueMarker, ['analytics', 0, 'guid'])}`}
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        {blueMarker.analytics[0].name}
                      </a>
                    </div>
                    <span className='severity'>
                      <Badge badgeSignature={severity} badgeName='SEVERITY' size='small' />
                    </span>

                    <Badge badgeSignature={rank} badgeName='CONFIDENCE' size='small' />
                  </div>
                </Tooltip>
              );
            })}
          </>
        )
    },
    {
      renderTitle: (): ReactElement =>
        selectedNodeRedPayload.red.length === 0 ? null : (
          <>
            {/* style prop used as className is not overriding the Grid margin-right */}

            <div className='sidebar-section'>
              <Typography className='title' variant='h4'>
                Attacks
              </Typography>
              <span>Severity</span>
            </div>
          </>
        ),
      renderContent: (): ReactElement =>
        selectedNodeRedPayload.red.length === 0 ? null : (
          <>
            {selectedNodeRedPayload.red.map(red => {
              return (
                <Tooltip
                  key={red.id}
                  placement='top-start'
                  wrap
                  title={
                    <div>
                      {red.attack_names.map((attack, i) => (
                        <span key={i}>{attack}</span>
                      ))}
                    </div>
                  }
                >
                  <div className='sidebar-section'>
                    <div className='name attack'>
                      {renderRedMarker(red)}
                      <p>
                        <strong>{red.attack_names?.[0]}</strong>
                        <em>
                          {' ' + (red.attack_names.length > 1 ? ` and ${red.attack_names.length - 1} others` : '')}
                        </em>
                        {red.description && (
                          <>
                            <br />
                            {red.description}
                          </>
                        )}
                      </p>
                    </div>
                    <span className='severity'>
                      <Badge badgeSignature={red.severity || ArtifactScore.UNKNOWN} badgeName='SEVERITY' size='small' />
                    </span>
                  </div>
                </Tooltip>
              );
            })}
          </>
        )
    },
    {
      title: 'Log Details',
      fields: LOG_DETAILS_FIELDS,
      values: selectedNode
    },
    {
      title: 'Prevalence',
      fields: PREVALENCE_FIELDS,
      values: selectedNode,
      badgeValue: get(selectedNode, 'prevalence', null) / MAX_PREVALENCE,
      badgeType: BadgeType.PREVALENCE,
      reverseSpacing: true
    }
  ];

  async function wrapHandleSaveMarkerWithSetters(run: () => Promise<void>) {
    await run().catch(e => console.warn('handle save marker', e.message, e.response?.data));
  }

  async function wrapHandleUpdateMarkerWithSetters(run: () => Promise<void>) {
    await run().catch(e => console.warn('handle update marker', e.message, e.response?.data));
  }

  async function handleSaveMarker(payload: MarkerPayload) {
    await wrapHandleSaveMarkerWithSetters(() => onMarkerSave(payload, selectedNode));
  }

  async function handleUpdateMarker(marker: RedMarkerExtended, updatePayload: RedMarkerUpdatePayload) {
    const payload: MarkerPayload = {
      id: marker.id,
      text: updatePayload.description,
      attack_names: updatePayload.attack_names,
      severity: updatePayload.severity
    };

    await wrapHandleUpdateMarkerWithSetters(() => onMarkerUpdate(payload));
  }

  async function handleDeleteMarker(markerId: Ident) {
    await onMarkerDelete(markerId, selectedNode).catch(e =>
      console.warn('handle delete marker', e.message, e.response?.data)
    );
  }

  function retrieveAnalytic(guid: Guid): void {
    if (guid) {
      getAnalytic(guid).then(result => {
        setAnalytic(result);
      });
    }
  }

  function renderProcessName(name: string): ReactElement {
    const titleComponent = <div className='sidebar-title no-wrap'>{name}</div>;
    if (size(name) <= 33) {
      return titleComponent;
    }
    return <Tooltip title={name}>{titleComponent}</Tooltip>;
  }

  return (
    <StyledNodeSidebar>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        {renderProcessName(get(selectedNode, 'process_name', 'N/A'))}
        {get(selectedNode, 'process_name', null) && (
          <div className='NodeSidebar-actions'>
            {!!selectedNode?.row_id && (
              <DisabledButton
                disabled={!selectedNode || !canCreate}
                title='Create a Detection'
                disabledTitle='You must become a community contributor to create a detection'
                ButtonProps={{
                  id: 'analytic-creation-link',
                  variant: 'text',
                  onClick: (): void => createAnalytic(selectedNode?.row_id, hostname)
                }}
              >
                <Icon icon={faCirclePlus} size='lg' color={palette.common.white} />
              </DisabledButton>
            )}
            {selectedNode.row_id && (
              <DisabledButton
                disabled={!canSplunk}
                title='View event logs for this process'
                disabledTitle='You must become a community contributor to view event logs'
                ButtonProps={{
                  id: 'splunk-dashboard-link',
                  variant: 'text',
                  onClick: () => {
                    const url = splunkDashboardUrl({
                      dashboard: hostLogDashboard,
                      session: session,
                      process_guid: `${get(selectedNode, 'ProcessGuid', '')}`
                    });
                    window?.open(url, '_blank', 'noopener noreferrer');
                  }
                }}
              >
                <Icon icon={faFileAlt} size='lg' color={canSplunk ? palette.common.white : palette.grey[600]} />
              </DisabledButton>
            )}
            <CopyButton
              value={url}
              messageTime={2000}
              tooltip='Share Graph URL'
              copyTooltip='Copied to Clipboard!'
              placement='top'
            >
              <Button id='copy-to-clipboard' variant='text' sx={{ minWidth: '48px' }}>
                <Icon icon={faShareAlt} size='lg' color={palette.common.white} />
              </Button>
            </CopyButton>
            <Can I={ContentPermission.Edit} this={session}>
              <Tooltip title='Label Attacks' wrap>
                <RedMarkerPopup onAdd={handleSaveMarker} offset={selectedNode['start_offset'] / 1000} />
              </Tooltip>
            </Can>
          </div>
        )}
      </div>
      {SIDEBAR_PANELS.filter(panel => panel !== null).map((panel, index) => (
        <SidebarPanel
          key={index}
          index={index}
          title={panel.title}
          badgeValue={panel.badgeValue}
          active={includes(activeIndices, index)}
          fields={panel.fields}
          values={panel.values}
          onClick={handleAccordionClick}
          timeFields={panel.timeFields}
          badgeType={panel.badgeType}
          fieldActions={panel.fieldActions}
          actionHelpers={panel.actionHelpers}
          renderTitle={panel.renderTitle} // Detection Name here
          renderContent={panel.renderContent}
        />
      ))}
    </StyledNodeSidebar>
  );
};

export default NodeSidebar;
