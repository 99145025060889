import React from 'react';

import Placeholder from 'snap-ui/Placeholder';
import { styled } from 'snap-ui/util';

import TruncateContainer from 'module/Layout/TruncateContainer';

import { Discriminator } from '.';
import { AccordionTagList, AccordionTagListProps, InlineTagList } from './TagTypeList';

const InlineContainer = styled('div')`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: ${p => p.theme.spacing(3)};
  padding: 0;

  .TruncateContainer > div {
    margin-bottom: 0;
    gap: ${p => p.theme.spacing(1)};
    max-height: calc(var(--rows) * 35px - 15px);
  }

  .Tag {
    color: ${p => p.theme.palette.grey[200]};
    border: none;
    max-height: 24px;
    max-width: 100%;
    font-size: 12px;
  }
`;

type InlineTagListWrapperProps = {
  children: JSX.Element;
  className?: string;
  rows?: number;
};

function InlineTagListWrapper({ children, className, rows = 2 }: InlineTagListWrapperProps) {
  return (
    <InlineContainer className={className}>
      <TruncateContainer rows={rows}>{children}</TruncateContainer>
    </InlineContainer>
  );
}

function InlineTagListPlaceholder() {
  return (
    <>
      <Placeholder className='Tag' variant='rectangular' width={80} />
      <Placeholder className='Tag' variant='rectangular' width={80} />
      <Placeholder className='Tag' variant='rectangular' width={80} />
      <Placeholder className='Tag' variant='rectangular' width={80} />
    </>
  );
}

export type TagListProps = Pick<AccordionTagListProps, 'onSubmit' | 'disabledQuickAddLists'> & {
  includeDataSources?: boolean;
  inline?: boolean;
  preview?: number;
  actor?: string[];
  attack?: string[];
  className?: string;
  inlineRows?: number;
  showPlaceholder?: boolean;
  software?: string[];
  vulnerability?: string[];
  datasource?: string[];
  action?: string[];
};

function InlineTags(props: TagListProps) {
  const { className, inlineRows, showPlaceholder, preview, includeDataSources } = props;

  return (
    <InlineTagListWrapper className={className} rows={inlineRows}>
      {showPlaceholder ? (
        <InlineTagListPlaceholder />
      ) : (
        <>
          <InlineTagList
            preview={preview}
            key={`attack-${props.attack?.length ?? 0}`}
            tags={props.attack}
            kind={Discriminator.Attack}
          />
          <InlineTagList
            preview={preview}
            key={`actor-${props.actor?.length ?? 0}`}
            tags={props.actor}
            kind={Discriminator.Actor}
          />
          <InlineTagList
            preview={preview}
            key={`software-${props.software?.length ?? 0}`}
            tags={props.software}
            kind={Discriminator.Software}
          />
          <InlineTagList
            preview={preview}
            key={`vulnerability-${props.vulnerability?.length ?? 0}`}
            tags={props.vulnerability}
            kind={Discriminator.Vulnerability}
          />
          {includeDataSources ? (
            <InlineTagList
              preview={preview}
              key={`datasource-${props.datasource?.length ?? 0}`}
              tags={props.datasource}
              kind={Discriminator.DataSource}
            />
          ) : null}
          <InlineTagList
            preview={preview}
            key={`action-${props.action?.length ?? 0}`}
            tags={props.action}
            kind={Discriminator.Action}
          />
        </>
      )}
    </InlineTagListWrapper>
  );
}

function TagListPlaceholder() {
  return (
    <>
      <Placeholder variant='text' width='100%' height={144} />
      <Placeholder variant='text' width='100%' height={144} />
      <Placeholder variant='text' width='100%' height={144} />
      <Placeholder variant='text' width='100%' height={144} />
    </>
  );
}

function AccordionTags(props: TagListProps) {
  const { showPlaceholder, disabledQuickAddLists = [], includeDataSources } = props;

  if (showPlaceholder) return <TagListPlaceholder />;
  return (
    <>
      <AccordionTagList
        key={`attack-${props.attack?.length ?? 0}`}
        title='ATT&CK Techniques'
        tags={props.attack}
        kind={Discriminator.Attack}
        onSubmit={!disabledQuickAddLists.includes(Discriminator.Attack) && props.onSubmit}
      />
      <AccordionTagList
        key={`actor-${props.actor?.length ?? 0}`}
        title='Actors'
        tags={props.actor}
        kind={Discriminator.Actor}
        onSubmit={!disabledQuickAddLists.includes(Discriminator.Actor) && props.onSubmit}
      />
      <AccordionTagList
        key={`software-${props.software?.length ?? 0}`}
        title='Software'
        tags={props.software}
        kind={Discriminator.Software}
        onSubmit={!disabledQuickAddLists.includes(Discriminator.Software) && props.onSubmit}
      />
      <AccordionTagList
        key={`vulnerability-${props.vulnerability?.length ?? 0}`}
        title='Vulnerabilities'
        tags={props.vulnerability}
        kind={Discriminator.Vulnerability}
        onSubmit={!disabledQuickAddLists.includes(Discriminator.Vulnerability) && props.onSubmit}
      />
      {includeDataSources ? (
        <AccordionTagList
          key={`datasource-${props.datasource?.length ?? 0}`}
          title='Data Sources and Components'
          tags={props.datasource}
          kind={Discriminator.DataSource}
          onSubmit={!disabledQuickAddLists.includes(Discriminator.DataSource) && props.onSubmit}
        />
      ) : null}
      <AccordionTagList
        key={`action-${props.action?.length ?? 0}`}
        title='NIST 800-53 Controls'
        tooltip='Mapped automatically from ATT&CK tags with the MITRE Engenuity ATT&CK to NIST 800-53 mappings'
        tags={props.action}
        kind={Discriminator.Action}
      />
    </>
  );
}

export default function TagList({ inline, ...props }: TagListProps) {
  if (inline) return <InlineTags {...props} />;
  return <AccordionTags {...props} />;
}
