import React from 'react';

import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';

import { Engage, Fingerprint, Widget } from 'lib/Engagement';

import { useAnalyticVersionCatalog } from 'module/Analytic/core/AnalyticVersionProvider';
import { useIDETranslationState } from 'module/IDE';
import { IDEAction, IDEState } from 'module/IDE/reducer';

import { useLanguageContext, useLogsourceContext } from 'provider';

import { Status } from 'storage';

import { AnalyticForm } from 'types/analytic';

import TranslateAnalytic from './TranslateAnalytic';
import { hasLogsource } from './TranslateAnalytic.util';
import useIDETranslateControls from './useIDETranslateControls';

const BACKENDS_WITH_METADTA = ['chronicle', 'pyhal'];

type DetectionFields = Pick<AnalyticForm, 'logsource' | 'detection'> &
  Partial<
    Pick<
      AnalyticForm,
      'title' | 'description' | 'actor_names' | 'attack_names' | 'software_names' | 'vulnerability_names'
    >
  >;

interface IDEWrapperProps {
  emitChange?(action: IDEAction): void;
  ideState: IDEState;
  fetchStatus?: Status;
  alwaysTranslateSigma?: boolean;
}

function getLogicValues(analyticForm: DetectionFields, backend_key?: string): DetectionFields {
  return {
    // always logic fields
    ...pick(analyticForm, ['logsource', 'detection'] as const),
    // pick meta fields if the backend includes metadata, or if none is specified (for storage of previous values)
    ...(!backend_key || BACKENDS_WITH_METADTA.includes(backend_key)
      ? pick(analyticForm, [
          'title',
          'description',
          'actor_names',
          'attack_names',
          'software_names',
          'vulnerability_names'
        ])
      : {})
  };
}

export default function IDEWrapper(props: IDEWrapperProps): JSX.Element {
  const { setSelectedLanguage: setProviderSelectedLanguage } = useIDETranslationState();
  const { status: logSourceStatus } = useLogsourceContext();
  const { allData: languages } = useLanguageContext();
  const { status: versionStatus } = useAnalyticVersionCatalog();
  const isLoading =
    props.fetchStatus === Status.pending || versionStatus === Status.pending || logSourceStatus === Status.pending;
  const previousDetectionFields = React.useRef<DetectionFields>();

  const previousLanguageId = React.useRef<number>();

  const shouldTranslate = React.useCallback(
    (languageId: number) => {
      const language = languages.find(l => l.id === languageId);
      if (props.ideState.isNative) return false;
      if (props.alwaysTranslateSigma) return true;

      // don't try to translate if the logsource is missing
      if (!hasLogsource(props.ideState)) {
        return false;
      }

      // if we selected a new language, definitely translate
      if (languageId !== previousLanguageId.current) {
        return true;
      }

      // don't fetch a translation if we haven't changed logic fields
      if (
        isEqual(
          getLogicValues(props.ideState.analyticForm, language.backend_key),
          getLogicValues(previousDetectionFields.current, language.backend_key)
        )
      ) {
        return false;
      }

      return true;
    },
    [languages, props.alwaysTranslateSigma, props.ideState]
  );

  const {
    integrationOptions,
    languageOptions,
    languageStatus,
    selectedIntegrationOptionValue,
    selectedLanguage,
    setSelectedIntegrationOptionValue,
    setSelectedLanguageId,
    translation,
    translationError,
    translationStatus
  } = useIDETranslateControls(props.ideState, shouldTranslate);

  const translationIsPending = translationStatus === Status.pending;

  React.useEffect(() => {
    setProviderSelectedLanguage(selectedLanguage);
  }, [selectedLanguage, setProviderSelectedLanguage]);

  React.useEffect(() => {
    previousLanguageId.current = selectedLanguage?.id;
  });

  React.useEffect(() => {
    previousDetectionFields.current = getLogicValues(props.ideState.analyticForm);
  });

  const handleEditAsNative = props.ideState.guid
    ? undefined // TODO: implement IDE state via router. Until then, Edit As Native is only available for fresh, unsaved detections
    : (languageId: number, raw: string) => {
        Engage.track(
          Fingerprint.of(Widget.TranslateAnalytic).withQualifier('convert to native').withData({
            guid: props.ideState.guid,
            languageId: languageId
          })
        );
        props.emitChange({ type: 'EditAsNativeUpdate', languageId, raw });
      };

  if (props.ideState.isNative) {
    return null;
  }

  if (!isLoading)
    return (
      <TranslateAnalytic
        analyticGuid={props.ideState.guid}
        analyticName={props.ideState.analyticForm.title}
        integrationOptions={integrationOptions}
        languageOptionStatus={languageStatus}
        languageOptions={languageOptions}
        onEditAsNative={handleEditAsNative}
        onIntegrationChange={setSelectedIntegrationOptionValue}
        onLanguageChange={setSelectedLanguageId}
        selectedIntegrationValue={selectedIntegrationOptionValue}
        selectedLanguage={selectedLanguage}
        translation={translation}
        translationError={translationError}
        translationIsPending={translationIsPending}
      />
    );

  return null;
}
