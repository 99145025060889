import React from 'react';

import { faChevronCircleLeft, faChevronCircleRight } from '@fortawesome/pro-regular-svg-icons';
import classnames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import { Autocomplete, getDisplayValue, Option } from 'snap-ui/Autocomplete';
import Button, { IconButton } from 'snap-ui/Button';
import CircularProgress from 'snap-ui/CircularProgress';
import FormLabel from 'snap-ui/FormLabel';
import IFrame from 'snap-ui/IFrame';
import Icon from 'snap-ui/Icon';
import Tooltip from 'snap-ui/Tooltip';
import Typography from 'snap-ui/Typography';

import useTitle from 'hooks/useTitle';

import { buildAdHocQueryLink } from 'module/Hunt/Hunt.util';
import { IntegrationAutocomplete } from 'module/Integration';
import { IntegrationAction } from 'module/Integration/Integration.type';
import { NotFound } from 'module/Util/Fallback';
import OverwatchOrganizationsAutocomplete from 'module/Widgets/OverwatchOrganizationsAutocomplete';
import { Highlight, SyntaxCopyWrapper, SyntaxEditor } from 'module/Widgets/SyntaxEditor';

import { useAuth } from 'provider/Account';
import { useIntegrationCatalog } from 'provider/Integration';

import { Status } from 'storage';

import { Guid } from 'types/common';

import { Container, Sidebar } from './AdHocQuery.style';
import { AdHocQueryProvider, useAdHocQueryContext } from './AdHocQueryProvider';
import AdHocQueryHistory from './QueryHistory';

const ACTIONS = [IntegrationAction.Deploy, IntegrationAction.Hunt];
function AdHocQuery() {
  useTitle('Run Ad Hoc Query | SnapAttack');

  const { status } = useAdHocQueryContext();
  const isExecuting = status === Status.pending;

  const [open, setOpen] = React.useState<boolean>(true);

  const { overwatch } = useIntegrationCatalog();
  const { canOverwatch, organizationIDs } = overwatch;
  const {
    executeQuery,
    integrationGuid,
    setIntegrationGuid,
    selectedLanguage,
    setSelectedLanguage,
    query,
    setQuery,
    jobGuid,
    languageTargets,
    integrationOptions,
    languageOptions
  } = useAdHocQueryContext();

  const selectedLanguageTarget = React.useMemo(() => {
    return languageTargets.find(target => target.id === selectedLanguage);
  }, [languageTargets, selectedLanguage]);

  function handleIntegrationChange(value: Guid): void {
    setIntegrationGuid(value);
  }

  function handleLanguageChange(value: string): void {
    setSelectedLanguage(parseInt(value));
  }

  function handleChangeQuery(value: string): void {
    setQuery(value);
  }

  function handleSearchClick(): void {
    executeQuery(selectedLanguageTarget?.backend_key, query);
  }

  function handleClearClick(): void {
    setQuery('');
  }

  const url = buildAdHocQueryLink(30, [jobGuid], false);

  return (
    <Container className={classnames({ 'has-sidebar': Boolean(jobGuid), 'sidebar-closed': !open })}>
      <Sidebar
        anchor='left'
        className='Sidebar'
        open={open}
        PaperProps={{ className: 'Sidebar-surface' }}
        variant='permanent'
      >
        {open ? (
          <>
            {jobGuid && (
              <div className='Sidebar-close'>
                <Tooltip arrow title='Close sidebar'>
                  <IconButton aria-label='Close sidebar' onClick={() => setOpen(false)}>
                    <Icon icon={faChevronCircleLeft} />
                  </IconButton>
                </Tooltip>
              </div>
            )}
            <Typography variant='h1'>Ad-Hoc Query</Typography>
            <div className='input-container'>
              <OverwatchOrganizationsAutocomplete elevated actions={ACTIONS} />
              {canOverwatch && <div className='grid-placeholder'></div>}
              {!isEmpty(organizationIDs) && (
                <>
                  <IntegrationAutocomplete
                    className='integration-selection'
                    elevated
                    label='Integration'
                    name='integration'
                    value={integrationGuid}
                    onChange={handleIntegrationChange}
                    options={integrationOptions}
                    disabled={isEmpty(integrationOptions)}
                    disableClearable
                    disableUserAdditions
                  />

                  <Autocomplete
                    elevated
                    label='Language'
                    name='language-selection'
                    onChange={handleLanguageChange}
                    options={languageOptions}
                    value={getDisplayValue(languageOptions, selectedLanguage?.toString())}
                    disabled={languageOptions.length <= 1}
                    disableGroupSelect
                    disableUserAdditions
                    disableClearable
                    isOptionEqualToValue={(option: Option, value: Option) => option.value === value.value}
                  />
                </>
              )}
            </div>

            <div className='query-container'>
              <FormLabel component='legend'>Query</FormLabel>
              <SyntaxCopyWrapper value={query}>
                <SyntaxEditor
                  onChange={handleChangeQuery}
                  highlight={Highlight[selectedLanguageTarget?.native_key]}
                  value={query}
                />
              </SyntaxCopyWrapper>
            </div>
            <div className='query-actions-container'>
              <Button disabled={isExecuting || !query} onClick={handleClearClick} variant='outlined'>
                Clear
              </Button>
              <Button disabled={isExecuting || !query || !selectedLanguageTarget} onClick={handleSearchClick}>
                {isExecuting ? <CircularProgress size={25} /> : 'Search'}
              </Button>
            </div>
            <AdHocQueryHistory className='query-history-container' />
          </>
        ) : (
          <div className='Sidebar-open'>
            <Tooltip arrow title='Open sidebar'>
              <IconButton aria-label='Open sidebar' onClick={() => setOpen(true)}>
                <Icon icon={faChevronCircleRight} />
              </IconButton>
            </Tooltip>
          </div>
        )}
      </Sidebar>
      <IFrame src={url} />
    </Container>
  );
}

export default function AdHocQueryWrapper() {
  const { user } = useAuth();
  if (!user.superuser) return <NotFound />;

  return (
    <AdHocQueryProvider>
      <AdHocQuery />
    </AdHocQueryProvider>
  );
}
