import Compiler from '../Detection';
import Processor from '../Processor';
import Token from '../Token';
import { Modifier } from '../const';
import fullTextSearch from './fullTextSearch';

export default function prerender(
  property: string[][],
  processor: Processor
): Promise<{ token: Token[]; field: string[] }> {
  return new Promise(resolve => {
    const t: Token[] = [];
    const f: string[] = [];

    /** I matter when there are multiple sections working on the same field where one matches and the other doesn't */
    function pushOrOverwriteFalseDuplicate(subject: Token) {
      const twin = t.findIndex(tok => tok.property === subject.property);
      if (twin === -1) {
        t.push(subject);
        f.push(subject.property);
      } else if (t[twin].satisfied === false && subject.satisfied === true) t[twin] = subject;
    }

    if (!processor) resolve({ token: t, field: f });
    const detection = processor.run();

    detection.token.forEach(tok => {
      if (tok.modifier === Modifier.full) {
        // A property derived by matching criteria
        const derived = fullTextSearch(property, f, tok);
        if (derived) pushOrOverwriteFalseDuplicate(derived);
      } else {
        const res = property.find(([subject]) => subject === tok.property);

        if (res) {
          const log = Array.isArray(res[1]) ? res[1] : [res[1]];
          log.forEach(l => {
            const c = new Compiler(l, tok).run();
            // A named property targeted for some criteria
            pushOrOverwriteFalseDuplicate({ ...tok, ...c });
          });
        }
      }
    });

    resolve({ token: t, field: f });
  });
}
