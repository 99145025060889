import React from 'react';

import {
  faCube,
  faDiamondExclamation,
  faHexagonCheck,
  faTriangleExclamation,
  IconDefinition
} from '@fortawesome/pro-regular-svg-icons';
import classNames from 'classnames';

import Icon from 'snap-ui/Icon';
import Tooltip, { TooltipProps } from 'snap-ui/Tooltip';
import { styled } from 'snap-ui/util';

import { Discriminator } from 'module/Tag/Tag.type';

import { ArtifactScore } from 'types/common';

import { LevelContainer } from '../Layout/Styled';

type Props = {
  badgeSignature: string;
  badgeName?: 'CONFIDENCE' | 'SEVERITY' | 'PRIORITY' | 'LOGSOURCE' | 'PLATFORM' | Discriminator;
  title?: string;
  icon?: IconDefinition;
  fluidWidth?: boolean;
  size?: 'small';
};

export function getIconByBadgeName(badgeName: Props['badgeName']): JSX.Element {
  switch (badgeName) {
    case 'CONFIDENCE':
      return <Icon icon={faHexagonCheck} />;
    case 'SEVERITY':
      return <Icon icon={faDiamondExclamation} />;
    case 'PRIORITY':
      return <Icon icon={faTriangleExclamation} />;
    case 'LOGSOURCE':
      return <Icon icon={faCube} />;
    default:
      return null;
  }
}

/**
 * @deprecated - This is doing too much. Use SimpleBadge or one of the other specific Badge implementations
 */
export default function Badge({ badgeSignature, title, badgeName, fluidWidth, size, ...props }: Props): JSX.Element {
  const icon = props.icon ? <Icon icon={props.icon} /> : getIconByBadgeName(badgeName);
  const isArtifactBadge = Object.values(ArtifactScore).includes(badgeSignature as ArtifactScore);

  return (
    <LevelContainer className='Badge'>
      <Tooltip title={title || ''} placement='top' arrow>
        <div
          className={classNames(
            'level',
            { 'no-width': fluidWidth, 'level level-graph': size },
            isArtifactBadge ? badgeSignature : ''
          )}
        >
          {icon}
          {!size && badgeSignature}
          {size && <div className='text-small'>{badgeSignature}</div>}
        </div>
      </Tooltip>
    </LevelContainer>
  );
}

const SimpleBadgeContainer = styled('div')`
  height: 1.5rem;
  min-width: 115px;
  max-width: 100%;
  display: flex;
  align-items: center;
  gap: ${p => p.theme.spacing(2)};
  border: 2px solid;
  border-radius: 5px;
  padding: ${p => p.theme.spacing(0, 2)};
  font-weight: bold;

  svg {
    height: 1rem;
  }

  .SimpleBadge-value {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export type SimpleBadgeProps = {
  className?: string;
  icon?: React.ReactElement;
  TooltipProps?: Omit<TooltipProps, 'children'>;
  value: string;
};

export function SimpleBadge({ className, icon, TooltipProps, value }: SimpleBadgeProps) {
  const content = (
    <SimpleBadgeContainer className={classNames('SimpleBadge', className)}>
      {icon}
      {!!value && <div className='SimpleBadge-value'>{value}</div>}
    </SimpleBadgeContainer>
  );

  return TooltipProps ? <Tooltip {...TooltipProps}>{content}</Tooltip> : content;
}

const ScoreBadgeContainer = styled(SimpleBadge)`
  text-transform: uppercase;

  &.${ArtifactScore.HIGHEST} {
    color: ${p => p.theme.palette.error.main};
  }
  &.${ArtifactScore.HIGH} {
    color: ${p => p.theme.palette.orange.main};
  }
  &.${ArtifactScore.MEDIUM} {
    color: ${p => p.theme.palette.warning.main};
  }
  &.${ArtifactScore.LOW} {
    color: ${p => p.theme.palette.success.main};
  }
  &.${ArtifactScore.LOWEST} {
    color: ${p => p.theme.palette.info.main};
  }
  &.${ArtifactScore.UNKNOWN} {
    color: ${p => p.theme.palette.grey[500]};
  }
`;

export function ScoreBadge({
  className,
  name,
  score,
  TooltipProps
}: {
  className?: string;
  name: 'CONFIDENCE' | 'SEVERITY';
  score: ArtifactScore;
  TooltipProps?: SimpleBadgeProps['TooltipProps'];
}) {
  const icon = getIconByBadgeName(name);
  return (
    <ScoreBadgeContainer
      icon={icon}
      value={score}
      className={classNames(className, 'ScoreBadge', score)}
      TooltipProps={TooltipProps}
    />
  );
}
