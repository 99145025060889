import React from 'react';

import { getReverseMap } from 'module/Analytic/AnalyticTuning/AnalyticTuning.api';
import { ReverseFieldMap } from 'module/Analytic/AnalyticTuning/AnalyticTuning.types';
import { ErrorProps } from 'module/ApiError';

import { useAsync, Status } from 'storage';

import { AnalyticForm } from 'types/analytic';
import { Ident } from 'types/common';

type ReverseMapInterface = {
  errorProps?: ErrorProps;
  isPending: boolean;
  map: ReverseFieldMap;
};

export default function useReverseMap(languageId: Ident, analyticForm: AnalyticForm): ReverseMapInterface {
  const { data, errorProps, run, status } = useAsync<ReverseFieldMap>({});
  const { product, category, service } = analyticForm?.logsource || {};

  React.useEffect(() => {
    if (languageId && (category || service)) {
      run(getReverseMap(languageId, { product, category, service }));
    }
  }, [languageId, product, category, service, run]);

  return {
    errorProps,
    isPending: status === Status.pending,
    map: data
  };
}
