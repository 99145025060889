import React from 'react';

import Placeholder from 'snap-ui/Placeholder';

import { IDEAnalyticTranslate, ViewAnalyticTranslate } from 'module/TranslateAnalytic';

import { Status } from 'storage';

import { AnalyticVersion } from '../Analytic.type';
import { useAnalyticCatalog } from '../core/AnalyticProvider';
import useIDEStateFromVersion from './useIDEStateFromVersion';

type AnalyticVersionTranslateProps = {
  version: AnalyticVersion;
  versionStatus: Status;
};

export default function AnalyticVersionTranslate({
  version,
  versionStatus
}: AnalyticVersionTranslateProps): JSX.Element {
  const ideState = useIDEStateFromVersion(version);
  const [{ analytic, supplemental }] = useAnalyticCatalog();
  const showPlaceholder = versionStatus === Status.pending || !ideState.raw;

  if (showPlaceholder) return <Placeholder variant='rectangular' height={200} />;

  return ideState.isNative ? (
    <ViewAnalyticTranslate
      analytic={{ ...analytic, raw: version?.raw }}
      analyticGuid={ideState.guid}
      supplemental={supplemental}
    />
  ) : (
    <IDEAnalyticTranslate alwaysTranslateSigma ideState={ideState} />
  );
}
