import React from 'react';

import { useFormikContext } from 'formik';
import get from 'lodash/get';

import { FieldsLayout } from 'snap-ui/Layout';

import { AgentInterface } from 'aso/useAgents';

import { AttackScript } from 'module/AttackScript/AttackScript.type';

import { BASProductName, LaunchBASFormValues } from 'types/bas';
import { Guid } from 'types/common';

import AgentField from './AgentField';
import Disclaimer from './Disclaimer';
import ScriptField from './ScriptField';

type LauncherFormProps = {
  scripts: AttackScript[];
  onScriptChange?(scriptGuid: Guid): void;
  agentInterface: AgentInterface;
};

const supportedProducts = [BASProductName.AtomicRedTeam, BASProductName.SnapAttack];
export default function LauncherForm({ scripts, onScriptChange, agentInterface }: LauncherFormProps): JSX.Element {
  const formik = useFormikContext<LaunchBASFormValues>();
  const script = scripts.find(script => script.guid === get(formik.values, 'script_id'));

  React.useEffect(() => {
    if (onScriptChange) {
      onScriptChange(formik.values.script_id);
    }
  }, [formik.values.script_id, onScriptChange]);

  return (
    <FieldsLayout>
      {scripts.length > 1 && <ScriptField scripts={scripts} />}
      {supportedProducts.includes(script?.product_name) && (
        <>
          <AgentField agentInterface={agentInterface} name='agent_id' target_platforms={script.platforms} />
          <Disclaimer />
        </>
      )}
    </FieldsLayout>
  );
}
