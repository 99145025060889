import React from 'react';

import { AnalyticLanguage } from 'types/analytic';

type IDETranslationStateContextValue = {
  selectedLanguage: AnalyticLanguage;
  setSelectedLanguage(language: AnalyticLanguage): void;
};

const IDETranslationStateContext = React.createContext<IDETranslationStateContextValue>(null);
IDETranslationStateContext.displayName = 'IDETranslationStateContext';

export function IDETranslationStateProvider({ children }: React.ComponentPropsWithoutRef<'div'>): JSX.Element {
  const [selectedLanguage, setSelectedLanguage] = React.useState<AnalyticLanguage>();
  return (
    <IDETranslationStateContext.Provider value={{ selectedLanguage, setSelectedLanguage }}>
      {children}
    </IDETranslationStateContext.Provider>
  );
}

export function useIDETranslationState(): IDETranslationStateContextValue {
  const value = React.useContext(IDETranslationStateContext);
  if (!value) {
    throw new Error('useIDETranslationState must be used inside IDETranslationStateProvider');
  }
  return value;
}
