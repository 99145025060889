import React from 'react';

import { faFlaskGear, faListAlt } from '@fortawesome/pro-solid-svg-icons';

import Button from 'snap-ui/Button';
import Icon from 'snap-ui/Icon';
import Link from 'snap-ui/Link';
import { TabItem } from 'snap-ui/Tabs';
import Typography from 'snap-ui/Typography';

import { Path } from 'constants/paths';

import { AnalyticDetectionSummaryState } from 'module/Detection/useAnalyticDetectionSummary';
import { Processor } from 'module/HighlightDetection';
import AnalyticGapIcon from 'module/Icons/AnalyticGapIcon';
import UnvalidatedAnalyticIcon from 'module/Icons/UnvalidatedAnalyticIcon';
import ValidatedAnalyticIcon from 'module/Icons/ValidatedAnalyticIcon';
import { Backfill } from 'module/Session/Session.type';
import MarkerBadge from 'module/Widgets/MarkerBadge';

import { Status } from 'storage';

import { AnalyticLogState } from 'types/analytic';
import { Guid } from 'types/common';

import Loader from './Loader';
import LogList from './LogList';
import { LogRoot } from './MatchingLog.widgets';
import useAssociatedLog from './useAssociatedLog';

type MatchingLogsProps = {
  analyticGuid: Guid;
  detectionSummary: AnalyticDetectionSummaryState;
  highlightProcessor: Processor;
  onLogClick?(key: string, value: string): void;
  onPinLogChange?(guid: Guid): void;
  onTest?(): void;
  params?: object;
  pins?: Guid[];
  analyticBackfill?: Backfill;
};

function MatchingLogs({
  analyticGuid,
  detectionSummary,
  highlightProcessor,
  onLogClick,
  onPinLogChange,
  onTest,
  pins
}: MatchingLogsProps): React.ReactElement {
  const { summary, status: detectionStatus } = detectionSummary;
  const [logTab, setLogTab] = React.useState<AnalyticLogState>();

  const logCount = React.useMemo(
    () => summary[logTab === AnalyticLogState.VALIDATED_GAPS ? 'validated_gaps' : logTab],
    [logTab, summary]
  );

  React.useEffect(() => {
    // default log tab to highest detection quality
    if (detectionStatus === Status.resolved || detectionStatus === Status.idle) {
      if (summary.validated) setLogTab(AnalyticLogState.VALIDATED);
      else if (summary.validated_gaps) setLogTab(AnalyticLogState.VALIDATED_GAPS);
      else if (summary.unvalidated) setLogTab(AnalyticLogState.UNVALIDATED);
    }
  }, [detectionStatus, summary]);

  const [currentLog, setCurrentLog] = React.useState(0);

  const { log, status: logStatus } = useAssociatedLog(analyticGuid, detectionSummary, logTab, currentLog);

  const showTopLevelLoader = detectionStatus === Status.pending;

  const tabs: TabItem[] = [];
  if (summary.validated) {
    tabs.push({
      value: AnalyticLogState.VALIDATED,
      label: (
        <MarkerBadge
          count={summary.validated || 0}
          icon={<ValidatedAnalyticIcon />}
          label={`${summary.validated || 0} Validated Detections`}
        />
      ),
      content: null
    });
  }
  if (summary.validated_gaps) {
    tabs.push({
      value: AnalyticLogState.VALIDATED_GAPS,
      label: (
        <MarkerBadge
          count={summary.validated_gaps}
          icon={<AnalyticGapIcon />}
          label={`${summary.validated_gaps} Detection Gaps`}
        />
      ),
      content: null
    });
  }
  if (summary.unvalidated) {
    tabs.push({
      value: AnalyticLogState.UNVALIDATED,
      label: (
        <MarkerBadge
          count={summary.unvalidated || 0}
          icon={<UnvalidatedAnalyticIcon />}
          label={`${summary.unvalidated || 0} Unvalidated Detections`}
        />
      ),
      content: null
    });
  }

  return (
    <Loader loading={showTopLevelLoader}>
      {summary.total > 0 ? (
        <LogList
          activeLogNumber={currentLog}
          activeTabKey={logTab}
          log={log}
          logContentProps={{ highlightProcessor: highlightProcessor, onLogClick }}
          logCount={logCount}
          logStatus={logStatus}
          onLogChange={handleLogChange}
          onPinLogChange={onPinLogChange}
          onTabChange={handleLogTabChange}
          pins={pins}
          tabs={tabs}
        />
      ) : (
        <LogRoot>
          <Icon className='RootLog-icon' icon={faListAlt} />
          <Typography variant='h4' component='div'>
            No Threat Library Hits
          </Typography>
          {onTest ? (
            <>
              <Typography textAlign='center'>
                Test your detection against the SnapAttack threat library. Testing requires a saved detection.
              </Typography>
              <Button onClick={onTest} variant='outlined' startIcon={<Icon icon={faFlaskGear} color='primary' />}>
                Test Now
              </Button>
            </>
          ) : (
            <p>
              <Link primary to={Path.ThreatCreate}>
                Create a new threat
              </Link>{' '}
              or{' '}
              <Link primary to={Path.ThreatUploadReset}>
                upload an existing threat capture
              </Link>{' '}
              to validate this detection in SnapAttack.
            </p>
          )}
        </LogRoot>
      )}
    </Loader>
  );

  function handleLogChange(nextLogNumber: number): void {
    if (nextLogNumber >= 0 && nextLogNumber < logCount) {
      setCurrentLog(nextLogNumber);
    }
  }

  function handleLogTabChange(state: AnalyticLogState): void {
    setCurrentLog(0);
    setLogTab(state);
  }
}

export default MatchingLogs;
