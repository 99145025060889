import React from 'react';

import { faEdit } from '@fortawesome/pro-solid-svg-icons';
import { useHistory } from 'react-router-dom';

import { ConfirmDialog } from 'snap-ui/Dialog';
import Placeholder from 'snap-ui/Placeholder';

import Path from 'constants/paths';

import { LauncherButton, LauncherDialog, LauncherInterface } from 'module/BAS/Launcher/useLauncherInterface';
import Can from 'module/Can/Can';
import {
  AddArtifactToCollectionButton,
  AddArtifactToCollectionDialog,
  AddArtifactToCollectionInterface
} from 'module/Curation';
import { BurgerDelete, BurgerLink, Menu, Title } from 'module/Layout/Artifact.widgets';
import { MoveOrgDialog, MoveOrgModalButton, MoveOrgModalInterface } from 'module/Organization';

import { ContentPermission } from 'types/auth';
import { BASProductName } from 'types/bas';
import { ArtifactType } from 'types/common';

import { AttackScript } from '../AttackScript.type';
import { AttackScriptPlatforms, AttackScriptSimulated } from '../AttackScript.widgets';
import { useAttackScriptCatalog } from '../AttackScriptProvider';
import {
  AddAnalyticToAttackScriptButton,
  AddAnalyticToAttackScriptDialog,
  AddAnalyticToAttackScriptInterface
} from './AddAnalyticToAttackScriptInterface';
import {
  AddSessionsToAttackScriptButton,
  AddSessionsToAttackScriptDialog,
  AddSessionsToAttackScriptInterface
} from './AddSessionsToAttackScriptInterface';
import AttackScriptSeverity from './AttackScriptSeverity';

export default function AttackScriptViewHeader() {
  const { attackScript, isPending } = useAttackScriptCatalog();
  if (isPending) return <AttackScriptHeaderPlaceholder />;
  return (
    <>
      <Title
        className='AttackScriptHeader-title'
        headerText={attackScript.name || 'Attack Script'}
        contentType={ArtifactType.AttackScript}
      />
      <div className='AttackScriptHeader-badges'>
        <AttackScriptPlatforms />
        <AttackScriptSimulated />
      </div>
      <div className='AttackScriptHeader-actions'>
        <AttackScriptSeverity />
        <AttackScriptViewHeaderMenu />
      </div>
    </>
  );
}

function AttackScriptViewHeaderMenu(): JSX.Element {
  const { push } = useHistory();
  const { attackScript, supplemental, deleteValidation } = useAttackScriptCatalog();

  const [isConfirming, setIsConfirming] = React.useState(false);

  async function handleDelete(): Promise<void> {
    setIsConfirming(false);
    await deleteValidation();
    push(`${Path.Feed}?topic=${ArtifactType.AttackScript}`);
  }

  const handleConfirmDelete = () => {
    setIsConfirming(true);
  };

  return (
    <AddSessionsToAttackScriptInterface>
      <AddArtifactToCollectionInterface>
        <AddAnalyticToAttackScriptInterface>
          <MoveOrgModalInterface type={ArtifactType.AttackScript}>
            <LauncherInterface>
              <div className='AttackScriptHeader-menu'>
                <Menu>
                  <CanEditWrapper attackScript={attackScript}>
                    <BurgerLink
                      key='edit'
                      to={{
                        pathname: `${Path.AttackScriptEdit}/${attackScript.guid}`,
                        state: {
                          item: attackScript,
                          supplementalItem: supplemental
                        }
                      }}
                      title='Edit'
                      icon={faEdit}
                    />
                  </CanEditWrapper>
                  <LauncherButton scripts={[attackScript]} />
                  <CanEditWrapper attackScript={attackScript}>
                    <>
                      <AddSessionsToAttackScriptButton />
                      <AddAnalyticToAttackScriptButton />
                    </>
                  </CanEditWrapper>
                  <AddArtifactToCollectionButton />
                  <MoveOrgModalButton />
                  <CanEditWrapper attackScript={attackScript}>
                    <BurgerDelete onClick={handleConfirmDelete} />
                  </CanEditWrapper>
                </Menu>
              </div>
              <AddSessionsToAttackScriptDialog attackScript={attackScript} />
              <AddAnalyticToAttackScriptDialog attackScript={attackScript} />
              <AddArtifactToCollectionDialog type={ArtifactType.AttackScript} guid={attackScript.guid} />
              <ConfirmDialog
                DialogProps={{ open: isConfirming, onClose: () => setIsConfirming(false) }}
                ConfirmProps={{ onClick: handleDelete, children: 'Delete' }}
                title='Delete forever'
                SecondaryProps={{ children: 'Cancel' }}
              >
                Are you sure you want to delete this attack script?
              </ConfirmDialog>
              <LauncherDialog scripts={[attackScript]} />
              <MoveOrgDialog artifact={attackScript} supplemental={supplemental} />
            </LauncherInterface>
          </MoveOrgModalInterface>
        </AddAnalyticToAttackScriptInterface>
      </AddArtifactToCollectionInterface>
    </AddSessionsToAttackScriptInterface>
  );
}

function CanEditWrapper({
  children,
  attackScript
}: {
  children: React.ReactElement;
  attackScript: AttackScript;
}): JSX.Element {
  return (
    <>
      {[BASProductName.SnapAttack, BASProductName.AtomicRedTeam].includes(attackScript.product_name) && (
        <Can I={ContentPermission.Edit} this={attackScript}>
          {children}
        </Can>
      )}
    </>
  );
}

export function AttackScriptHeaderPlaceholder() {
  return (
    <>
      <div className='AttackScriptHeader-title'>
        <Placeholder variant='rectangular' height={40} width={600} />
      </div>
      <div className='AttackScriptHeader-badges'>
        <Placeholder variant='rectangular' height={24} width={130} />
      </div>
      <div className='AttackScriptHeader-actions'>
        <Placeholder variant='rectangular' height={47.91} width={131.91} />
        <Placeholder variant='circular' height={56} width={56} />
      </div>
    </>
  );
}
