import { z } from 'zod';

import { BASProductName, OSName } from 'module/BAS/BAS.type';
import { SupplementalArtifact } from 'module/Search';

import { Status } from 'storage';

import { ArtifactScore, Visibility } from 'types/common';
import { IdentitySchema, Permitted, Version } from 'types/common.zod';

// schema of `atomic_tests` here https://github.com/redcanaryco/atomic-red-team/blob/master/atomic_red_team/spec.yaml
export type AtomicTestScript = {
  name: string;
  description: string;
  supported_platforms: OSName[]; // min length 1
  executor: {
    name: string;
    elevation_required: boolean;
    command: string;
    cleanup_command: string;
  };
  // optional fields:
  input_arguments?: Record<
    string,
    {
      description: string;
      type: 'Path' | 'Url' | 'String' | 'Integer' | 'Float';
      default: string;
    }
  >;
  dependency_executor_name?: string;
  dependencies?: Record<
    string,
    {
      description: string;
      prereq_command: string;
      get_prereq_command: string;
    }
  >;
};

// schema defined in the readme here: https://github.com/snapattack/atomic-red-team-AttackScript/
export type AtomicTestAttackScript = {
  exit_code?: number;
  output?: string;
  follow_up?: {
    command: string;
    exit_code?: number;
    output?: string;
  };
};

export const AttackScriptFormSchema = z.object({
  organization_id: z.string(),
  attack_name: z.string().min(1, 'MITRE ATT&CK is required'),
  attack_names: z.array(z.string()),
  actor_names: z.array(z.string()),
  vulnerability_names: z.array(z.string()),
  software_names: z.array(z.string()),
  references: z.array(z.string().url()),
  script: z.string().min(1, 'Attack Script is required'),
  validation: z.string(),
  simulated: z.boolean()
});

export type AttackScriptFormInput = Required<z.infer<typeof AttackScriptFormSchema>>;

export const AttackScriptSchema = IdentitySchema.extend({
  // Artifact Fields
  simulated: z.boolean(),
  short_id: z.string(),
  visibility: z.nativeEnum(Visibility),
  owner_id: z.number(),
  small_image: z.string(),
  large_image: z.string(),
  logsource: z.string(),
  preview: z.boolean(),
  created_by_id: z.number(),
  creation: z.string(),
  description: z.string(),
  modified: z.string(),
  modified_by_id: z.number(),
  organization_id: z.number(),

  // Tags
  attack_name: z.string(),
  attack_names: z.array(z.string()),
  actor_names: z.array(z.string()),
  vulnerability_names: z.array(z.string()),
  software_names: z.array(z.string()),

  // Other
  product_id: z.string(),
  product_version: z.string(),
  product_name: z.nativeEnum(BASProductName),
  platforms: z.array(z.nativeEnum(OSName)),
  references: z.array(z.string()).nullable(),
  sessions: z.array(IdentitySchema).nullable(),
  script_yaml: z.string(),
  validation_yaml: z.string(),
  severity: z.nativeEnum(ArtifactScore),
  permission: Permitted,
  versions: z.array(Version)
});

export type AttackScript = Required<z.infer<typeof AttackScriptSchema>>;

export type AttackScriptSupplemental = Pick<
  SupplementalArtifact,
  | 'guid'
  | 'severities'
  | 'source_analytic_compilation_target_id'
  | 'name'
  | 'deployable'
  | 'deployments'
  | 'deployable_integrations'
  | 'huntable_integrations'
  | 'ranks'
  | 'states'
>;

export interface AttackScriptState {
  attackScript: AttackScript;
  supplemental: AttackScriptSupplemental;
  isPending: boolean;
  statuses: Status[];
  error: any;
  setSeverity(ArtifactScore): void;
  setAttackScript: (value: AttackScript) => void;
  deleteValidation(): Promise<void>;
}

export interface AttackScriptRouterState {
  item: AttackScript;
  supplementalItem: AttackScriptSupplemental;
}
