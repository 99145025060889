import React from 'react';

import { faFileLines, faSitemap } from '@fortawesome/pro-solid-svg-icons';
import { Link } from 'react-router-dom';

import { ActionIconButton } from 'snap-ui/Button';
import { SimpleTabs, Tab, TabItem } from 'snap-ui/Tabs';
import Tooltip from 'snap-ui/Tooltip';
import Typography from 'snap-ui/Typography';

import Path from 'constants/paths';

import useSplunkLink from 'hooks/useSplunkLink';

import Can from 'module/Can';
import { ToggleLogPinButton } from 'module/IDE/core/ToggleLogPinButton';

import { Status } from 'storage';

import { SigmaLogsource } from 'types/analytic';
import { FunctionalPermission } from 'types/auth';
import { Guid } from 'types/common';
import { MarkerEvent } from 'types/marker';

import { formatQueryString } from 'utilities/SearchParam';
import { splunkDashboardUrl } from 'utilities/SplunkUtils';

import Loader from './Loader';
import LogContent, { LogContentProps } from './LogContent';
import {
  LinkActionButtonComponent,
  LogContainer,
  LogHeader,
  LogListContainer,
  LogToolbar,
  Pager
} from './MatchingLog.widgets';

type LogListProps = {
  activeLogNumber: number;
  activeTabKey: string;
  log: MarkerEvent;
  logContentProps: Partial<LogContentProps>;
  logCount: number;
  logStatus: Status;
  onLogChange: (logIndex: number) => void;
  onPinLogChange?(guid: Guid): void;
  onTabChange: (tabKey: string) => void;
  pins?: Guid[];
  tabs: TabItem[];
};

function LogList(props: LogListProps): React.ReactElement {
  const { hostLogDashboard } = useSplunkLink();
  const { log = { host: { machine: {}, session: {} } } as MarkerEvent } = props;
  const hostId = log.host.guid;
  const hostname = log.host.machine.name;
  const sessionGuid = log.host.session.guid;
  const rowID = log.row_id;
  const timestamp = log.timestamp;
  const logContents = log.raw;
  const logsource: SigmaLogsource = {
    product: log?.sigma_product,
    service: log?.sigma_service,
    category: log?.sigma_category
  };

  const handleTabChange = (_event: React.SyntheticEvent<Element, Event>, value: any) => {
    props.onTabChange && props.onTabChange(value);
  };

  return (
    <LogListContainer>
      <LogToolbar>
        <SimpleTabs value={props.activeTabKey || props.tabs[0]?.value} className='tabs' onChange={handleTabChange}>
          {props.tabs.map(tab => (
            <Tab key={tab.value} value={tab.value} label={tab.label} />
          ))}
        </SimpleTabs>
        <Pager current={props.activeLogNumber} count={props.logCount} onChange={props.onLogChange} />
        <div className='content-menu'>
          {props.onPinLogChange && (
            <ToggleLogPinButton pinned={props.pins?.includes(rowID)} guid={rowID} onClick={props.onPinLogChange} />
          )}
          <Can I={FunctionalPermission.SplunkUIAccess}>
            <Can.Grant>
              <Tooltip arrow placement='top' title='View full event log' wrap>
                <Link
                  target='_blank'
                  to={{
                    pathname: splunkDashboardUrl({
                      dashboard: hostLogDashboard,
                      session: { guid: sessionGuid },
                      neartime: timestamp,
                      row_id: rowID,
                      logsource: logsource
                    })
                  }}
                  component={props => (
                    <LinkActionButtonComponent {...props} aria-label='View full event log' icon={faFileLines} />
                  )}
                />
              </Tooltip>
            </Can.Grant>
            <Can.Block>
              <Tooltip arrow placement='top' title='Event logs are only available to contributors' wrap>
                <ActionIconButton
                  aria-label='Event logs are only available to contributors'
                  icon={faFileLines}
                  disabled
                />
              </Tooltip>
            </Can.Block>
          </Can>
          <Tooltip arrow placement='top' title='View in Process Graph' wrap>
            <Link
              target='_blank'
              to={{
                pathname: Path.ProcessGraph,
                search: formatQueryString({
                  hostname,
                  hostId,
                  threatId: sessionGuid,
                  searchQuery: rowID,
                  searchField: 'row_id'
                })
              }}
              component={props => (
                <LinkActionButtonComponent
                  {...props}
                  aria-label='View in Process Graph'
                  icon={faSitemap}
                  IconProps={{ rotation: 270 }}
                />
              )}
            />
          </Tooltip>
        </div>
      </LogToolbar>

      <LogContainer>
        <Loader loading={props.logStatus === Status.pending && !props.log}>
          <LogHeader>
            <Typography variant='h4'>
              Threat:{' '}
              <Link rel='noopener noreferrer' target='_blank' to={`${Path.Threat}/${sessionGuid}`}>
                {log?.host?.session?.name}
              </Link>
            </Typography>
          </LogHeader>
          <LogContent {...props.logContentProps} log={logContents} />
        </Loader>
      </LogContainer>
    </LogListContainer>
  );
}

export default LogList;
