import React from 'react';

import { faThumbTack } from '@fortawesome/pro-regular-svg-icons';
import { faAlignLeft, faFlaskGear } from '@fortawesome/pro-solid-svg-icons';
import isEmpty from 'lodash/isEmpty';
import { useLocation } from 'react-router-dom';

import Alert, { AlertTitle } from 'snap-ui/Alert';
import { BackdropLoaderContainer } from 'snap-ui/BackdropLoader';
import Button from 'snap-ui/Button';
import Icon from 'snap-ui/Icon';
import { TextPlaceholders } from 'snap-ui/Placeholder';
import Typography from 'snap-ui/Typography';
import { styled } from 'snap-ui/util';

import useRawLog from 'aso/useRawLog';

import { URL_PARAMS_SCHEMA } from 'constants/ide';

import { useIDEState } from 'module/IDE';
import LogContent from 'module/MatchingLog/LogContent';
import { LogRoot, LogToolbar, Pager } from 'module/MatchingLog/MatchingLog.widgets';

import { Status } from 'storage/Storage.type';

import { Guid } from 'types/common';
import { IDERouteGen } from 'types/route';

import { parseQueryString } from 'utilities/SearchParam';

import { ToggleLogPinButton } from './ToggleLogPinButton';

const Root = styled(BackdropLoaderContainer)`
  .Backdrop {
    display: flex;
    flex-direction: column;
    gap: ${p => p.theme.spacing(3)};
    min-height: 300px;
  }

  .LogToolbar {
    padding: ${p => p.theme.spacing(6)};
    justify-content: space-between;
    align-items: center;
  }
`;

const Segment = styled('div', { name: 'TargetLog-segment' })`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 200px;
  gap: ${p => p.theme.spacing(3)};

  > * {
    width: fit-content;
  }

  .MuiAlert-root {
    width: 75%;
  }

  .TargetLog-icon {
    height: 3.75rem;
  }
`;

interface TargetLogProps {
  onLogClick(key: string, value: string): void;
  onPinLogChange?(guid: Guid): void;
  onTest?(): void;
}

export default function TargetLog({ onLogClick, onPinLogChange, onTest }: TargetLogProps): JSX.Element {
  const { search } = useLocation();
  const { ideState, dispatch } = useIDEState();
  const params: IDERouteGen['search'] = parseQueryString(search, URL_PARAMS_SCHEMA);
  const [eventIndex, setEventIndex] = React.useState(0);
  const rowId = ideState.pinned_events?.[eventIndex];
  const { data: log, status: logStatus, error: logError } = useRawLog(ideState.pinned_events?.[eventIndex]);

  const enableLogClick = !!(
    ideState.analyticForm.logsource?.type &&
    (ideState.analyticForm.logsource?.category || ideState.analyticForm.logsource?.service)
  );

  const isNewDetectionWithPinnedLog = !ideState.guid && !!params.row_id;

  React.useEffect(() => {
    // set the logsource on load if the event has the info
    const targetHasLogsource = !!log.sigma_product || !!log.sigma_service || !!log.sigma_category;
    const detectionHasLogsource = !isEmpty(ideState.analyticForm.logsource);
    if (targetHasLogsource && !detectionHasLogsource) {
      dispatch({
        type: 'FormUpdateAction',
        analyticForm: {
          logsource: {
            product: log.sigma_product,
            service: log.sigma_service,
            category: log.sigma_category
          }
        },
        refresh: true
      });
    }
  }, [dispatch, log, ideState.analyticForm.logsource]);

  React.useEffect(() => {
    if (isNewDetectionWithPinnedLog) {
      dispatch({ type: 'ModPinnedEventUpdate', pinned_events: [params.row_id] });
    }
  }, [isNewDetectionWithPinnedLog, params.row_id]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!rowId) {
    return (
      <LogRoot>
        <Icon className='RootLog-icon' icon={faAlignLeft} />
        <Typography variant='h4' component='div'>
          No Target Logs
        </Typography>
        {onTest && (
          <>
            <Typography textAlign='center'>
              You can <Icon icon={faThumbTack} className='RootLog-example' /> logs from the threat library to save them
              as target logs.
            </Typography>
            <Button onClick={onTest} variant='outlined' startIcon={<Icon icon={faFlaskGear} color='primary' />}>
              Test Now
            </Button>
          </>
        )}
      </LogRoot>
    );
  }

  if (logStatus === Status.pending) {
    return <TextPlaceholders />;
  }

  if (logError) {
    return (
      <Segment>
        <Alert severity='error'>
          <AlertTitle>Oops!</AlertTitle>
          {logError}
        </Alert>
      </Segment>
    );
  }

  return (
    <Root>
      <LogToolbar>
        <Pager count={ideState.pinned_events?.length || 0} current={eventIndex} onChange={setEventIndex} />
        {!!onPinLogChange && (
          <ToggleLogPinButton onClick={onPinLogChange} guid={rowId} pinned={!!rowId} autopinned={!ideState.guid} />
        )}
      </LogToolbar>
      <LogContent
        log={log}
        disableLogClick={!enableLogClick}
        onLogClick={onLogClick}
        highlightProcessor={ideState.highlightProcessor}
      />
    </Root>
  );
}
