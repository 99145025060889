import React from 'react';

import { faHexagonCheck as faHexagonCheckOutlined } from '@fortawesome/pro-regular-svg-icons';
import {
  faHexagonCheck as faHexagonCheckSolid,
  faHexagonXmark as faHexagonXmarkSolid
} from '@fortawesome/pro-solid-svg-icons';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import unescape from 'lodash/unescape';
import upperCase from 'lodash/upperCase';
import ReactDOMServer from 'react-dom/server';

import { StandardChip } from 'snap-ui/Chip';
import Icon from 'snap-ui/Icon';
import { ExternalLink } from 'snap-ui/Link';
import Placeholder from 'snap-ui/Placeholder';
import Tooltip from 'snap-ui/Tooltip';
import Typography from 'snap-ui/Typography';
import { styled } from 'snap-ui/util';

import { FEED_TYPES, STATE_LABEL_COLOR } from 'constants/feed';

import { AlphaFeature, CanAlpha } from 'module/AlphaFeatures';
import LogsourceBadge from 'module/Analytic/core/LogsourceBadge';
import SnapScoreBadge, { useSnapScore } from 'module/Analytic/core/SnapScoreBadge';
import ValidationBadge, { LockedValidationBadge } from 'module/Analytic/core/ValidationBadge';
import { WorkflowBadge } from 'module/Analytic/core/WorkflowState';
import { isLandingType } from 'module/Collection/Collection.util';
import { AnalyticDefensivePosture, SessionDefensivePosture } from 'module/DefensivePosture';
import { DetectionSummary } from 'module/Detection';
import { SortOrder } from 'module/Feed/Feed.type';
import { useFilterRegistry } from 'module/GlobalFilter';
import { LandingArtifact } from 'module/Landing/Landing.type';
import { filterOutSelf, getTagIDs } from 'module/Landing/Landing.util';
import { FriendlyLandingType } from 'module/Landing/Landing.widgets';
import { getArtifactIcon } from 'module/Layout/Artifact.helper';
import { IntelReferencePlaceholder, PosturePlaceholder, TagsPlaceholder } from 'module/Layout/Placeholders';
import { SupplementalArtifact } from 'module/Search';
import { Discriminator, isTacticArtifact } from 'module/Tag';
import TagList from 'module/Tag/TagList';
import { CardBadgeThreatProfile } from 'module/ThreatProfile';
import Badge from 'module/Widgets/Badge';
import CollectionCounter from 'module/Widgets/CollectionCounter';
import ExternalImage from 'module/Widgets/ExternalImage';
import HasThreatBadge from 'module/Widgets/HasThreatBadge';
import { MarkdownRead } from 'module/Widgets/Markdown';
import { MarkdownText } from 'module/Widgets/Markdown/MarkdownRead';
import PlatformBadge from 'module/Widgets/PlatformBadge';
import { IntelReferenceCounts } from 'module/Widgets/ReferenceCounts';
import { SimulatedBadge } from 'module/Widgets/SimulatedBadge/SimulatedBadge';

import { useAuth, useIntegrationCatalog, useUserCatalog, useUserConfig } from 'provider';

import { Status } from 'storage';

import { Artifact, ArtifactScore, ArtifactType, Guid, Visibility } from 'types/common';

import { getPreferredOrgScore } from 'utilities/ArtifactUtils';
import { timeAgo } from 'utilities/TimeUtils';

import { CardHeaderContainer, CardSubsection } from './Card.style';
import { cleanHTML, getCardImageUrl, getCurrentOrgState } from './Card.util';
import CardDeleteButton from './CardDeleteButton';
import CardDeleteIntelButton from './CardDeleteIntelButton';
import CardPublishIntelButton from './CardPublishIntelButton';

export function CreatedBy({ item }: { item: Artifact }): JSX.Element {
  const { users } = useUserCatalog();
  if (isLandingType(item)) return <>Created {timeAgo(item.creation)}</>;
  return (
    <>
      {item?.original_author ||
        item?.author ||
        users.find(u => u.id === item?.created_by_id)?.name ||
        'SnapAttack User'}{' '}
      | Created {timeAgo(item.creation)}
    </>
  );
}

export function ModifiedBy({ item }: { item: Artifact }): JSX.Element {
  const { users } = useUserCatalog();
  if (isLandingType(item)) return <>Updated {timeAgo(item.modified)}</>;
  return (
    <>
      {users.find(u => u.id === item?.modified_by_id)?.name || 'SnapAttack User'} | Updated {timeAgo(item.modified)}
    </>
  );
}

export function CardHeader({
  topic,
  item,
  supplemental,
  status,
  onDelete
}: {
  topic: ArtifactType;
  item: Artifact;
  supplemental: SupplementalArtifact;
  status: Status;
  onDelete(id: Guid): void;
}) {
  const isLanding = isLandingType(item);
  const { permission: organizations } = useAuth();
  const { sortOrder } = useUserConfig();

  return (
    <CardHeaderContainer className='CardHeader'>
      <Typography variant='h5'>
        {getArtifactIcon(isLanding ? (item.type as Discriminator) : topic)}
        {isLanding ? <FriendlyLandingType item={item} /> : FEED_TYPES[topic].text} |{' '}
        {item && (
          <>
            {organizations.find(o => o.id === item.organization_id)?.name || 'Private Organization'} |{' '}
            {sortOrder === SortOrder.creation ? <CreatedBy item={item} /> : <ModifiedBy item={item} />}
          </>
        )}
        {!item && <Placeholder variant='text' width={200} />}
      </Typography>
      <CardState topic={topic} item={item} supplemental={supplemental} status={status} onDelete={onDelete} />
    </CardHeaderContainer>
  );
}

export function CardDescription({ item }: { item: Artifact }) {
  const noHighlights = isEmpty(item.description_highlights);
  if (noHighlights) return <MarkdownRead className='CardDescription' value={item.description || ''} raw />;

  const description = item.description_highlights.slice()[0];
  const descriptionHTML = ReactDOMServer.renderToString(<MarkdownText value={description} raw />);
  const cleanedHTML = cleanHTML(unescape(descriptionHTML));

  return <div className='CardDescription' dangerouslySetInnerHTML={{ __html: cleanedHTML }} />;
}

export function CardTags({ item }: { item: Artifact }) {
  const filterFn = filterOutSelf(item?.name);
  if (
    isEmpty(item?.actor_names) &&
    isEmpty(item?.software_names) &&
    isEmpty(item?.vulnerability_names) &&
    isEmpty(item?.attack_names) &&
    isEmpty(item?.datasource_names)
  )
    return null;

  let title = 'Associated Actors, Software, Vulnerabilities, and TTPs';
  if (item?.type === Discriminator.Software) {
    title = 'Associated Actors and TTPs';
  } else if (item?.type === Discriminator.Actor) {
    title = 'Associated Software and TTPs';
  }
  return item ? (
    <CardSubsection title={title}>
      <TagList
        inline
        className='CardTagsContainer'
        actor={item.actor_names?.filter(filterFn)}
        attack={item.attack_names?.filter(filterFn)}
        action={item.actions?.map(nistId => nistId.toString())}
        datasource={item.datasource_names}
        software={item.software_names?.filter(filterFn)}
        vulnerability={item.vulnerability_names?.filter(filterFn)}
      />
    </CardSubsection>
  ) : (
    <CardSubsection title={title}>
      <TagsPlaceholder className='CardTagsContainer' />
    </CardSubsection>
  );
}

export function CardImage({ item, topic }: { item: Artifact; topic: ArtifactType }) {
  const { permission: organizations } = useAuth();

  const [source, alternate] = getCardImageUrl(item, topic, organizations);
  return (
    (item && (
      <div className='CardImage'>
        <ExternalImage src={source} altSrc={alternate} alt='item logo' />
      </div>
    )) || <Placeholder className='CardImage' variant='rectangular' />
  );
}

/**
 * Posture | Reference data
 */
export function CardCounter({
  detection,
  item,
  supplemental,
  topic
}: {
  detection: DetectionSummary;
  item: Artifact;
  supplemental: SupplementalArtifact;
  topic: ArtifactType;
}) {
  switch (topic) {
    case ArtifactType.Intel: {
      if (!supplemental) return <IntelReferencePlaceholder className='CardCounter' />;
      const count = (supplemental.reference_counts?.reference_count ?? 0) + (supplemental.indicator_count ?? 0);
      if (!count) return null;
      return (
        <div className='CardCounter'>
          <IntelReferenceCounts
            references={supplemental.reference_counts?.reference_count}
            iocs={supplemental.indicator_count}
          />
        </div>
      );
    }
    case ArtifactType.Session:
      if (!detection) return <PosturePlaceholder className='CardCounter' />;
      return <SessionDefensivePosture className='CardCounter' count={detection} />;
    case ArtifactType.Analytic:
      if (!detection) return <PosturePlaceholder className='CardCounter' />;
      return <AnalyticDefensivePosture className='CardCounter' count={detection} />;
    case ArtifactType.Collection:
      return (
        <CardSubsection title='Associated Content'>
          <CollectionCounter item={item} />
        </CardSubsection>
      );
    default:
      return null;
  }
}

export function CardBadges({
  topic,
  count,
  item,
  supplemental,
  supplementalPending,
  preview
}: {
  count: DetectionSummary;
  item: Artifact;
  supplemental: SupplementalArtifact;
  supplementalPending: boolean;
  preview: boolean;
  topic: ArtifactType;
}) {
  const { defaultOrgId } = useAuth();
  const getScore = useSnapScore();

  if (!item)
    return (
      <div className='CardBadges'>
        <Placeholder variant='text' width={80} />
        <Placeholder variant='text' width={80} />
        <Placeholder variant='text' width={80} />
      </div>
    );
  switch (topic) {
    case ArtifactType.Session: {
      const severity = item.severity || ArtifactScore.UNKNOWN;
      const rank = (item as Artifact).rank;

      return (
        <div className='CardBadges'>
          <Badge badgeSignature={severity} title={severity + ` Severity`} badgeName='SEVERITY' fluidWidth />
          {rank && <Badge badgeSignature={rank} title={rank + ` Confidence`} badgeName='CONFIDENCE' fluidWidth />}
        </div>
      );
    }
    case ArtifactType.Analytic: {
      const score = getScore(supplemental);
      const severity = getPreferredOrgScore(defaultOrgId, supplemental?.severities, item.severity);
      const rank = getPreferredOrgScore(defaultOrgId, supplemental?.ranks, item.rank);
      return (
        <div className='CardBadges'>
          <CanAlpha feature={AlphaFeature.SnapScore}>
            {supplementalPending ? (
              <SnapScoreBadge.Placeholder display='card' />
            ) : (
              <SnapScoreBadge score={score} display='card' />
            )}
          </CanAlpha>
          {count ? (
            <ValidationBadge
              validated={count.validated}
              unvalidated={count.unvalidated}
              validated_gaps={count.validated_gaps}
            />
          ) : preview ? (
            <LockedValidationBadge />
          ) : (
            <Placeholder height={24} width={66} variant='rectangular' />
          )}
          {supplementalPending ? (
            <>
              <Placeholder variant='rectangular' height={24} width={80} />
              <Placeholder variant='rectangular' height={24} width={80} />
            </>
          ) : (
            <>
              <Badge badgeSignature={severity} title={severity + ` Severity`} badgeName='SEVERITY' fluidWidth />
              <Badge badgeSignature={rank} title={rank + ` Confidence`} badgeName='CONFIDENCE' fluidWidth />
            </>
          )}
          <LogsourceBadge
            value={item.logsource}
            languageId={supplemental?.source_analytic_compilation_target_id}
            display='card'
          />
        </div>
      );
    }
    case ArtifactType.AttackScript: {
      const severity = getPreferredOrgScore(defaultOrgId, supplemental?.severities, item.severity);

      const { platforms, has_threat_capture, simulated } = item;

      return (
        <div className='CardBadges'>
          <Badge badgeSignature={severity} title={severity + ` Severity`} badgeName='SEVERITY' fluidWidth />
          <PlatformBadge platforms={platforms} />
          <HasThreatBadge value={has_threat_capture} />
          <SimulatedBadge simulated={simulated} />
        </div>
      );
    }
    default:
      return null;
  }
}

export function CardState({
  item,
  supplemental,
  status,
  onDelete,
  topic
}: {
  item: Artifact;
  supplemental: SupplementalArtifact;
  status: Status;
  onDelete(id: Guid): void;
  topic: ArtifactType;
}) {
  const { user, defaultOrgId } = useAuth();
  const feedType = FEED_TYPES[topic];
  const preview = item?.preview;
  const [visibility, setVisibility] = React.useState<Visibility>(item.visibility);

  switch (topic) {
    case ArtifactType.Intel: {
      return (
        <div className='CardState'>
          {user?.superuser && visibility === Visibility.Draft && (
            <>
              <CardPublishIntelButton guid={item.guid} title={item.name} setVisibility={setVisibility} />
              <CardDeleteIntelButton guid={item.guid} title={item.name} setVisibility={setVisibility} />
            </>
          )}
          {visibility && visibility !== Visibility.Published && (
            <StandardChip label={upperCase(Visibility[visibility])} color={get(STATE_LABEL_COLOR, visibility)} />
          )}
          {onDelete && (
            <CardDeleteButton
              name={item.name}
              guid={item.guid}
              readonly={item.readonly}
              type={feedType}
              onDelete={onDelete}
            />
          )}
          {preview && <Icon.Lock />}
        </div>
      );
    }
    case ArtifactType.Session: {
      return (
        <div className='CardState'>
          {visibility && visibility !== Visibility.Published && (
            <StandardChip label={upperCase(Visibility[visibility])} color={get(STATE_LABEL_COLOR, visibility)} />
          )}
          {onDelete && (
            <CardDeleteButton
              name={item.name}
              guid={item.guid}
              readonly={item.readonly}
              type={feedType}
              onDelete={onDelete}
            />
          )}
          {preview && <Icon.Lock />}
        </div>
      );
    }
    case ArtifactType.Analytic: {
      const workflowState = getCurrentOrgState(defaultOrgId, supplemental?.states)?.state;

      return (
        <div className='CardState'>
          {visibility && visibility !== Visibility.Published && (
            <StandardChip label={upperCase(Visibility[visibility])} color={get(STATE_LABEL_COLOR, visibility)} />
          )}
          {status === Status.pending ? (
            <Placeholder variant='text' width={80} />
          ) : (
            <>
              <DeploymentStatusChip supplemental={supplemental} />
              {workflowState && <WorkflowBadge state={workflowState} />}
            </>
          )}
          {onDelete && (
            <CardDeleteButton
              name={item.name}
              guid={item.guid}
              readonly={item.readonly}
              type={feedType}
              onDelete={onDelete}
            />
          )}
          {preview && <Icon.Lock />}
        </div>
      );
    }
    case ArtifactType.AttackScript: {
      return (
        <div className='CardState'>
          {onDelete && (
            <CardDeleteButton
              name={item.name}
              guid={item.guid}
              readonly={item.readonly}
              type={feedType}
              onDelete={onDelete}
            />
          )}
          {preview && <Icon.Lock />}
        </div>
      );
    }
    case ArtifactType.Collection: {
      if (!isLandingType(item)) return null;
      if (item.type === Discriminator.Attack && isTacticArtifact(item)) return null;
      const tagIDs = getTagIDs(item as LandingArtifact, item.type as Discriminator);
      return (
        <div className='CardState'>
          <CardBadgeThreatProfile tagIDs={tagIDs} />
        </div>
      );
    }
    default:
      return preview ? (
        <div className='CardState'>
          <Icon.Lock />
        </div>
      ) : null;
  }
}

const LockedOverlayContainer = styled('div')`
  z-index: 1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: ${p => p.theme.palette.surface.cardHover};
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  opacity: 0;
  padding: ${p => p.theme.spacing(1, 0)};

  svg {
    font-size: 6.5rem;
  }

  .copy {
    text-align: center;
  }
`;

export const LockedOverlay = () => (
  <LockedOverlayContainer className='LockedOverlayContainer'>
    <Icon.Lock />
    <div className='copy'>
      This item is only available to subscribers.
      <br />
      Please{' '}
      <ExternalLink primary href={'https://www.snapattack.com/contact/'}>
        contact us
      </ExternalLink>{' '}
      to discuss subscription plans.
    </div>
  </LockedOverlayContainer>
);

const DeploymentStatusTooltipContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-content: center;
  & > div {
    display: flex;
    gap: ${p => p.theme.spacing(2)};
    align-items: center;
  }
`;

export function DeploymentStatusChip({ supplemental }: { supplemental: SupplementalArtifact }): JSX.Element {
  const { defaultOrgId } = useAuth();
  const { integrations } = useIntegrationCatalog();
  const { values: feedFilterValues } = useFilterRegistry();

  const deployment = supplemental?.deployments?.find(deployment => deployment.organization_id === defaultOrgId);

  if (isEmpty(deployment)) return null;

  const mappedIntegrations = deployment.integrations
    .map(integration => integrations.deployable.find(deployable => integration.guid === deployable.guid))
    .filter(Boolean);

  if (isEmpty(mappedIntegrations)) return null;

  function filterSelected({ guid }): boolean {
    if (isEmpty(feedFilterValues?.deployedEnvironment)) return true;
    return (feedFilterValues?.deployedEnvironment as string[]).some(deployedEnv => deployedEnv === guid);
  }

  const failed = deployment.failed_deployment
    ?.filter(filterSelected)
    ?.map(failed => mappedIntegrations.find(integration => integration.guid === failed.guid))
    ?.filter(Boolean);

  const pending = deployment.pending_deployment
    ?.filter(filterSelected)
    ?.map(pending => mappedIntegrations.find(integration => integration.guid === pending.guid))
    ?.filter(Boolean);

  const success = deployment.success_deployment
    ?.filter(filterSelected)
    ?.map(success => mappedIntegrations.find(integration => integration.guid === success.guid))
    ?.filter(Boolean);

  const title = (
    <DeploymentStatusTooltipContainer>
      {!isEmpty(failed) &&
        failed.map(({ name }) => (
          <div key={`failed_${name}`}>
            <Icon aria-label={`deployment error to ${name}`} color='error' icon={faHexagonXmarkSolid} />
            {name}
          </div>
        ))}
      {!isEmpty(pending) &&
        pending.map(({ name }) => (
          <div key={`pending_${name}`}>
            <Icon aria-label={`deployment pending to ${name}`} color='success' icon={faHexagonCheckOutlined} />
            {name}
          </div>
        ))}
      {!isEmpty(success) &&
        success.map(({ name }) => (
          <div key={`success_${name}`}>
            <Icon
              aria-label={`deployed to ${name}`}
              color={deployment.is_current ? 'success' : 'warning'}
              icon={faHexagonCheckSolid}
            />
            {name}
          </div>
        ))}
    </DeploymentStatusTooltipContainer>
  );

  if (!isEmpty(failed))
    return (
      <Tooltip arrow title={title}>
        <StandardChip color='error' label='DEPLOYMENT ERROR' />
      </Tooltip>
    );
  if (!isEmpty(pending))
    return (
      <Tooltip arrow title={title}>
        <StandardChip color='success' label='DEPLOYMENT QUEUED' variant='outlined' />
      </Tooltip>
    );
  if (!deployment.is_current)
    return (
      <Tooltip arrow title={title}>
        <StandardChip color='warning' label='DEPLOYMENT OUTDATED' />
      </Tooltip>
    );
  if (!isEmpty(success))
    return (
      <Tooltip arrow title={title}>
        <StandardChip color='success' label='DEPLOYED' />
      </Tooltip>
    );

  return null;
}
