import omit from 'lodash/omit';
import { z } from 'zod';

import { StepDetail } from 'snap-ui/Stepper';

import { MandiantSeverityStatus } from 'module/Landing/Landing.type';
import { Discriminator } from 'module/Tag';
import { TagWeight } from 'module/ThreatProfile';

export const ExploitationStates = omit(MandiantSeverityStatus, 'Yes');

export const ExploitationStateOrderedList = [
  ExploitationStates.NoKnown,
  ExploitationStates.Anticipated,
  ExploitationStates.Available,
  ExploitationStates.Confirmed,
  ExploitationStates.Wide
] as const;

export const FIT_VECTOR_DEFAULT = {
  actor: [0, 0.25, 0.5, 0.7, 0.85, 0.95, 1],
  attack: [0, 0.25, 0.5, 0.7, 0.85, 0.95, 1],
  software: [0, 0.4, 0.65, 0.85, 0.95, 0.99, 1],
  vulnerability: [0, 0.4, 0.65, 0.85, 0.95, 0.99, 1]
};

export const ThreatProfilePayloadSchema = z.object({
  industries: z.array(z.string()),
  regions: z.array(z.string()),
  scale: z.number(),
  motivations: z.array(z.string()),
  applications: z.array(z.string()),

  platforms: z.array(z.string()),
  threat_maturity: z.number().min(0).max(1),
  confidence_min: z.number().min(0).max(1),
  severity_min: z.number().min(0).max(1),
  vulnerability_cvss_min: z.number().min(0).max(10),
  vulnerability_epss_min: z.number().min(0).max(1),
  vulnerability_exploit_min: z.nativeEnum(ExploitationStates),
  vulnerability_exploit_wild: z.boolean(),
  vulnerability_exploit_zero_day: z.boolean(),
  observation_age_max: z.string(), // ISO8601 Duration
  fit: z.object({
    actor: z.array(z.number()),
    attack: z.array(z.number()),
    software: z.array(z.number()),
    vulnerability: z.array(z.number())
  })
});

export type ThreatProfilePayload = Required<z.infer<typeof ThreatProfilePayloadSchema>>;

export const ThreatProfileResponseSchema = ThreatProfilePayloadSchema.extend({
  organization_id: z.number()
});

export type ThreatProfileResponse = Required<z.infer<typeof ThreatProfileResponseSchema>>;

export const ThreatProfileSchema = ThreatProfileResponseSchema.omit({
  scale: true,
  threat_maturity: true,
  confidence_min: true,
  severity_min: true,
  vulnerability_cvss_min: true,
  vulnerability_epss_min: true,
  vulnerability_exploit_min: true,
  vulnerability_exploit_wild: true,
  vulnerability_exploit_zero_day: true,
  observation_age_max: true,
  fit: true
}).extend({
  scale: z.array(z.string()),
  severity_thresholds: z.array(z.number()).max(2),
  confidence_thresholds: z.array(z.number()).max(2),

  // vulnerability risk tolerance
  risk_tolerance: z.array(z.number()).max(2),

  // vulnerability characteristics
  exploitation_state: z.array(z.number()).max(2),
  exploited_zero_day: z.string(),
  exploited_in_wild: z.string(),

  // exploit likelihood tolerance
  exploit_likelihood: z.array(z.number()).max(2),

  // threat recency priority
  threat_recency_threshold: z.number(),

  // last observed
  last_observed_threshold: z.number(), // months

  // fit vectors
  fit: z.record(z.nativeEnum(Discriminator), z.record(z.nativeEnum(TagWeight), z.number()))
});

export type ThreatProfile = Required<z.infer<typeof ThreatProfileSchema>>;

export type ThreatProfileStepDetail = StepDetail & {
  keys?: (keyof ThreatProfile)[];
};
