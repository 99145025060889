import { z } from 'zod';

import { GridSortModel } from 'snap-ui/DataGrid';

import { Page } from 'module/Feed/Feed.type';

import { ErrorProps, Status } from 'storage';

import { Language } from 'types/analytic';
import { Ident } from 'types/common';
import { ArtifactScore, Guid } from 'types/common';
import { IdentitySchema, TrackedSchema } from 'types/common.zod';

export type HuntFormInput = Partial<z.infer<typeof HuntFormSchema>>;

export const HuntFormSchema = z.object({
  name: z.string().nonempty(),
  selectedIntegrations: z.array(z.string()).min(1, 'Expected at least 1 selection'),
  cron_trigger: z.string().optional(),
  max_results_per_query: z.number(),
  max_search_window_seconds: z.string(),
  frequency: z.string(),
  extra_parameters: z.record(z.string(), z.unknown()).optional()
});

export enum JobType {
  Hunt = 'Hunt',
  Rank = 'Rank',
  IOC = 'IOC',
  Import = 'Import',
  Test = 'Test'
}

export const JobTypeTableMap: Record<JobType | 'All', string> = {
  All: 'All',
  [JobType.Hunt]: 'Hunt',
  [JobType.Rank]: 'Confidence Tailoring',
  [JobType.IOC]: 'IOC',
  [JobType.Import]: 'Imported',
  [JobType.Test]: 'Test'
} as const;

export type JobOverviewFilters = {
  sort?: string;
  org_id?: number;
  name?: string;
  integration_guid?: Guid;
  days?: number;
};

export type JobOverviewFeed = Page<JobOverview>;

export type JobOverviewFeedAsync = JobOverviewFeed & {
  status: Status;
  error: ErrorProps;
};

export enum JobStatus {
  Pending = 'Pending',
  Started = 'Started',
  Failed = 'Failed',
  CompletedWithErrors = 'CompletedWithErrors',
  Success = 'Success',
  Canceled = 'Canceled',
  Paused = 'Paused'
}

export type AdHocQueryPayload = {
  organization_id: number;
  type: JobType;
  integrations: string[];
  adhoc_queries: {
    language: Language['backend_key'];
    search: string;
  }[];
};

export type PostJobsInput =
  | {
      organization_id?: number;
      name?: string;
      guids: string[];
      parameters: Record<string, unknown>;
      type: JobType;
      expire_time?: string;
      integrations: string[];
    }
  | AdHocQueryPayload;

export const JobSchema = TrackedSchema.extend({
  name: z.string(),
  permission: z.array(z.string()),
  items: z.array(IdentitySchema),
  parameters: z.record(z.string(), z.unknown()),
  deleted: z.boolean(),
  type: z.nativeEnum(JobType),
  integration: TrackedSchema,
  status: z.nativeEnum(JobStatus),
  status_detail: z.string().optional(),
  creation: z.string(),
  modified: z.string()
});

export const JobGroupSchema = TrackedSchema.extend({
  name: z.string(),
  permission: z.array(z.string()),
  jobs: z.array(TrackedSchema),
  schedule_id: z.number().optional(),
  status: z.nativeEnum(JobStatus)
});

export const JobGroupOutputSchema = z.array(JobGroupSchema);

export const JobHuntOverviewSchema = z.object({
  hunt_name: z.string(),
  date: z.string(),
  status: z.nativeEnum(JobStatus),
  detections: z.number(),
  hits: z.number().nullable(),
  integration: z.string(),
  job_guid: z.string()
});

export const JobOverviewDetailItemSchema = IdentitySchema.extend({
  hit_count: z.number().optional(),
  item_state_detail: z.string().optional(),
  item_error: z.string().optional(),
  integration_name: z.string(),
  integration_guid: z.string().optional()
});

export type JobOverviewDetailItem = z.infer<typeof JobOverviewDetailItemSchema>;

export const JobOverviewSchema = z.object({
  name: z.string(),
  creation: z.string(),
  modified: z.string(),
  analytic_job_group_id: z.number(),
  analytic_job_group_guid: z.string(),
  analytic_job_guid: z.string(),
  analytic_job_id: z.number(),
  total_hits: z.number(),
  schedule_id: z.number().optional(),
  type: z.nativeEnum(JobTypeTableMap),
  created_by: z.string(),
  created_by_id: z.number(),
  integration_guid: z.string(),
  integration_name: z.string(),
  job_status: z.nativeEnum(JobStatus),
  job_status_detail: z.string(),
  job_item_count: z.number(),
  detections_completed: z.number(),
  dispatch_completed: z.number(),
  detections_dispatched: z.number(),
  detections_failed: z.number(),
  progress_percent: z.number(),
  estimated_remaining_duration: z.string(), //ISO8601 Duration (Time)
  parameters: z.record(z.string(), z.unknown()),
  jobsByGroup: z.array(z.string()).optional(),
  organization_id: z.number(),
  complete: z.boolean(),
  stopped: z.boolean(),
  can_pause: z.boolean(),
  can_resume: z.boolean(),
  can_cancel: z.boolean(),
  is_external: z.boolean(),
  expired: z.boolean()
});

export const JobOverviewDetailSchema = JobOverviewSchema.extend({
  item_detail: z.array(JobOverviewDetailItemSchema),
  complete: z.boolean(),
  stopped: z.boolean(),
  can_pause: z.boolean(),
  can_resume: z.boolean(),
  can_cancel: z.boolean(),
  is_external: z.boolean(),
  expired: z.boolean()
});

export type Job = z.infer<typeof JobSchema>;
export type JobGroup = z.infer<typeof JobGroupSchema>;
export type JobOverview = z.infer<typeof JobOverviewSchema>;
export type JobOverviewDetail = z.infer<typeof JobOverviewDetailSchema>;
export const JobsOverviewDetailSchema = z.array(JobOverviewDetailSchema);

export const JobsOutputSchema = z.array(JobSchema);

export const JobResultSchema = z.object({
  dispatch_count: z.number(),
  dispatch_completed: z.number(),
  dispatch_errors: z.any(),
  total_job_duration: z.number(),
  total_event_count: z.number(),
  host_count: z.number(),
  modified: z.string()
});

export type JobResult = z.infer<typeof JobResultSchema>;

export const JobGroupResultSchema = TrackedSchema.extend({
  name: z.string(),
  bas_job_id: z.string(),
  job_hit_count: z.number(),
  count: z.number(),
  error: z.string(),
  integration: z.string(),
  integration_name: z.string(),
  old_rank: z.nativeEnum(ArtifactScore),
  logsource: z.string(),
  diff: z.number().optional(),
  groupGuid: z.array(z.string()).optional(),
  newRank: z.nativeEnum(ArtifactScore)
});

export const JobGroupResultOutputSchema = z.array(JobGroupResultSchema);

export type JobGroupResult = z.infer<typeof JobGroupResultOutputSchema>;

export type TaskState = {
  typeFilter: JobType | 'All';
  orgFilter: Ident;
  nameFilter: string;
  name: string;
  sortModel: GridSortModel;
  pageParams: {
    page: number;
    size: number;
  };
};

export type TaskAction =
  | { type: 'SET_TYPE'; payload: JobType | 'All' }
  | { type: 'SET_PAGE'; payload: number }
  | { type: 'SET_ORG_FILTER'; payload: Ident }
  | { type: 'SET_NAME_FILTER'; payload: string }
  | { type: 'SET_DEBOUNCED_NAME'; payload: string }
  | { type: 'SET_SORT_MODEL'; payload: GridSortModel }
  | { type: 'RESET_FILTER'; payload: JobType | 'All' };
