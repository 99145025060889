import React from 'react';

import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

import {
  deleteIntegration,
  getDeploymentSchemaCatalog,
  getIntegrations,
  postCloneIntegration,
  postIntegration,
  postIntegrationDeployment,
  putIntegration
} from 'module/Integration/Integration.api';
import { Integration, IntegrationAction } from 'module/Integration/Integration.type';
import {
  CreateIntegrationRequest,
  DeploymentSchemaCatalog,
  IntegrationFormData
} from 'module/Integration/Integration.type';

import { useAuth } from 'provider';

import { checkEntitlement, checkTaskPermission } from 'services/authService';

import { ErrorProps, Status, useAsync } from 'storage';

import { FunctionalPermission, Organization } from 'types/auth';
import { ContentPermission } from 'types/auth';
import { Guid } from 'types/common';

import { filterIntegrationsByOrg, filterIntegrationsByPermission } from './Integration.util';

export function integrationHasActionableTarget(integration: Integration, actions: IntegrationAction[]): boolean {
  const hasHunt = actions.includes(IntegrationAction.Hunt) ? !isEmpty(integration.hunt_targets) : false;
  const hasDeploy = actions.includes(IntegrationAction.Deploy) ? !isEmpty(integration.deployment_targets) : false;
  const hasSearch = actions.includes(IntegrationAction.Search) ? !isEmpty(integration.search_targets) : false;

  return hasHunt || hasDeploy || hasSearch;
}

export type IntegrationCatalog = {
  errorProps?: ErrorProps;
  integrations: {
    all: Integration[];
    getIntegrationsByOrgIDs(orgIDs: number[]): Integration[];
    preferred_org_aware: Integration[];
    editable: Integration[];
    deployable: Integration[];
    huntable: Integration[];
    searchable: Integration[];
    preferredOrgSearchable: Integration[];
  };
  overwatch: {
    canOverwatch: boolean;
    isOverwatching: boolean;
    organizationIDs: number[];
    setOrganizationIDs: React.Dispatch<React.SetStateAction<number[]>>;
    getOverwatchOrganizations: (actions: IntegrationAction[]) => Organization[];
  };
  deploymentSchema: DeploymentSchemaCatalog;
  error?: string;
  refresh(): void;
  create(payload: IntegrationFormData): Promise<void>;
  update(guid: Guid, payload: IntegrationFormData): Promise<void>;
  remove(guid: Guid): Promise<void>;
  clone(guid: Guid, payload: CreateIntegrationRequest): Promise<void>;
  sync(guids: Guid[]): Promise<void[]>;
  status: Status;
  taskStatus: Status;
  syncStatus: Status;
  deploymentSchemaStatus: Status;
};

const registered = (integration: Integration) => integration.is_registered;

export default function useIntegrations(): IntegrationCatalog {
  const { user, defaultOrgId, permission } = useAuth();

  const { data, errorProps, run, status } = useAsync<Integration[]>([]);
  const { status: taskStatus, task } = useAsync();
  const { status: syncStatus, task: syncTask } = useAsync();
  const {
    data: deploymentSchema,
    run: deploymentSchemaRun,
    status: deploymentSchemaStatus
  } = useAsync<DeploymentSchemaCatalog>();

  const preferredOrg = permission.find(org => org.id === defaultOrgId);
  const permitted = checkTaskPermission([preferredOrg], FunctionalPermission.CrossOrganizationJob);
  const entitled = checkEntitlement(preferredOrg?.entitlements, FunctionalPermission.CrossOrganizationJob);
  const canOverwatch = permitted && entitled;

  const [organizationIDs, setOrganizationIDs] = React.useState<number[]>([]);

  React.useEffect(() => {
    deploymentSchemaRun(getDeploymentSchemaCatalog());
  }, [deploymentSchemaRun]);

  const refresh = React.useCallback(() => {
    if (user.email) {
      run(getIntegrations());
    }
  }, [run, user.email]);

  React.useEffect(() => {
    refresh();
  }, [refresh]);

  React.useEffect(() => {
    if (defaultOrgId) setOrganizationIDs([defaultOrgId]);
  }, [defaultOrgId]);

  const create = React.useCallback(
    (payload: IntegrationFormData) => {
      return task(postIntegration(payload).then(refresh));
    },
    [refresh, task]
  );

  const update = React.useCallback(
    (guid: Guid, payload: IntegrationFormData) => {
      return task(putIntegration(guid, payload).then(refresh));
    },
    [refresh, task]
  );

  const remove = React.useCallback(
    (guid: Guid) => {
      return task(deleteIntegration(guid).then(refresh));
    },
    [refresh, task]
  );

  const clone = React.useCallback(
    (guid: Guid, payload: CreateIntegrationRequest) => {
      return task(postCloneIntegration(guid, payload).then(refresh));
    },
    [refresh, task]
  );

  const sync = React.useCallback(
    (guids: Guid[]) => {
      const promises = guids.map(guid => syncTask(postIntegrationDeployment(guid)));
      return Promise.all(promises);
    },
    [syncTask]
  );

  const preferred_org_aware = React.useMemo(() => filterIntegrationsByOrg(data, [defaultOrgId]), [defaultOrgId, data]);

  const editable = React.useMemo(() => filterIntegrationsByPermission(data, ContentPermission.Edit), [data]);

  const deployable = React.useMemo(
    () => preferred_org_aware.filter(registered).filter(integration => !isEmpty(integration.deployment_targets)),
    [preferred_org_aware]
  );

  const huntable = React.useMemo(
    () => preferred_org_aware.filter(registered).filter(integration => !isEmpty(integration.hunt_targets)),
    [preferred_org_aware]
  );

  const preferredOrgSearchable = React.useMemo(
    () => preferred_org_aware.filter(registered).filter(integration => !isEmpty(integration.search_targets)),
    [preferred_org_aware]
  );

  const getIntegrationsByOrgIDs = React.useCallback(
    (orgIDs: number[]) => filterIntegrationsByOrg(data, orgIDs).filter(registered),
    [data]
  );

  const getOverwatchOrganizations = React.useCallback(
    (actions: IntegrationAction[]) =>
      permission
        .filter(org => checkTaskPermission([org], FunctionalPermission.CreateAnalyticJob))
        .filter(org =>
          getIntegrationsByOrgIDs([org.id]).some(integration => integrationHasActionableTarget(integration, actions))
        ),
    [getIntegrationsByOrgIDs, permission]
  );

  // can use community things
  const searchable = React.useMemo(
    () =>
      data
        .filter(integration => !isEmpty(integration.search_targets))
        .filter(integration => Boolean(integration.url_template)),
    [data]
  );

  return {
    integrations: {
      all: data,
      getIntegrationsByOrgIDs,
      preferred_org_aware,
      editable,
      deployable,
      huntable,
      searchable,
      preferredOrgSearchable
    },
    overwatch: {
      canOverwatch,
      isOverwatching: canOverwatch && !isEqual(organizationIDs, [defaultOrgId?.toString()]),
      organizationIDs,
      setOrganizationIDs,
      getOverwatchOrganizations
    },
    deploymentSchema,
    errorProps,
    refresh,
    create,
    update,
    remove,
    clone,
    sync,
    status,
    taskStatus,
    syncStatus,
    deploymentSchemaStatus
  };
}
