import React from 'react';

import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';

import { Option } from 'snap-ui/Autocomplete';

import { IntegrationOption, IntegrationTarget } from 'module/Integration/Integration.type';

import { useIntegrationCatalog } from 'provider/Integration';

import { Guid, Ident } from 'types/common';

import { AdHocQueryInterface, AdHocQueryJobHistory } from './AdHocQuery.type';
import { useAdHocQueryHistory } from './useAdHocQueryHistory';

const AdHocQueryContext = React.createContext<AdHocQueryInterface>(null);
AdHocQueryContext.displayName = 'AdHocQueryContext';

function AdHocQueryProvider({ children }: React.PropsWithChildren<Record<never, never>>): JSX.Element {
  const { integrations, overwatch } = useIntegrationCatalog();
  const { organizationIDs, setOrganizationIDs } = overwatch;

  const [integrationGuid, setIntegrationGuid] = React.useState<Guid>(null);
  const [languageTargets, setLanguageTargets] = React.useState<IntegrationTarget[]>([]);
  const [selectedLanguage, setSelectedLanguage] = React.useState<Ident>(null);
  const [query, setQuery] = React.useState<string>(null);
  const [jobGuid, setJobGuid] = React.useState<Guid>(null);

  const integrationOptions: IntegrationOption[] = React.useMemo(
    () =>
      integrations.all
        .filter(integration => organizationIDs.some(orgId => orgId === integration.organization_id))

        .map<IntegrationOption>(integration => {
          const warning = isEmpty(integration.hunt_targets) && isEmpty(integration.deployment_targets);
          return {
            label: warning ? `${integration.name} (no integrations)` : integration.name,
            value: integration.guid,
            type: integration.type,
            warning
          };
        }),
    [integrations.all, organizationIDs]
  );

  const languageOptions: Option[] = React.useMemo(
    () =>
      languageTargets.map(language => ({
        value: language.id?.toString(),
        content: language.name,
        label: language.name
      })),
    [languageTargets]
  );

  React.useEffect(() => {
    const integration = integrations.all.find(i => i.guid === integrationGuid);

    if (integration) {
      const targets = uniqBy([...integration.deployment_targets, ...integration.hunt_targets], 'id');
      setLanguageTargets(targets);
    } else {
      setLanguageTargets([]);
    }
  }, [integrationGuid, integrations.all]);

  React.useEffect(() => {
    if (!integrationGuid && !isEmpty(integrationOptions)) {
      setIntegrationGuid(integrationOptions[0].value);
    }
  }, [integrationGuid, integrationOptions, setIntegrationGuid]);

  React.useEffect(() => {
    if (!isEmpty(languageTargets)) {
      const selectedLanguageTarget = languageTargets.find(target => target.id === selectedLanguage);

      if (!selectedLanguage || !selectedLanguageTarget) {
        setSelectedLanguage(languageTargets[0].id);
      } else {
        setSelectedLanguage(selectedLanguageTarget.id);
      }
    }
  }, [languageTargets, selectedLanguage]);

  const handleHistoryClick = React.useCallback(
    (queryHistory: AdHocQueryJobHistory) => {
      if (isEmpty(queryHistory)) return;
      const language = languageTargets.find(
        target => target.backend_key === queryHistory.payload.adhoc_queries[0].language
      );

      setOrganizationIDs([queryHistory.payload.organization_id]);
      setIntegrationGuid(queryHistory.payload.integrations[0]);
      setSelectedLanguage(language.id);
      setQuery(queryHistory.payload.adhoc_queries[0].search);
      setJobGuid(queryHistory.jobGuid);
    },
    [languageTargets, setIntegrationGuid, setJobGuid, setOrganizationIDs, setQuery, setSelectedLanguage]
  );

  const adHocQueryHistoryBag = useAdHocQueryHistory(organizationIDs[0], integrationGuid);
  const value: AdHocQueryInterface = React.useMemo(
    () => ({
      ...adHocQueryHistoryBag,
      handleHistoryClick,
      integrationGuid,
      setIntegrationGuid,
      selectedLanguage,
      setSelectedLanguage,
      query,
      setQuery,
      jobGuid,
      setJobGuid,
      languageTargets,
      integrationOptions,
      languageOptions
    }),
    [
      adHocQueryHistoryBag,
      handleHistoryClick,
      integrationGuid,
      integrationOptions,
      jobGuid,
      languageOptions,
      languageTargets,
      query,
      selectedLanguage
    ]
  );
  return <AdHocQueryContext.Provider value={value}>{children}</AdHocQueryContext.Provider>;
}

function useAdHocQueryContext() {
  const value = React.useContext(AdHocQueryContext);
  if (!value) throw new Error('useAdHocQueryContext must be used within a AdHocQueryContext');
  return value;
}

export { AdHocQueryProvider, useAdHocQueryContext };
