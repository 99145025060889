import React from 'react';

import { faSquareUp } from '@fortawesome/pro-regular-svg-icons';
import { useFormikContext } from 'formik';

import Button from 'snap-ui/Button';
import Icon from 'snap-ui/Icon';
import Typography from 'snap-ui/Typography';

import { BurgerCancel, BurgerPublish, BurgerReset, Menu, Publish, Title } from 'module/Layout/Artifact.widgets';

import { simpleParse } from 'utilities/YamlUtils';

import { AtomicTestScript, AttackScriptFormInput } from '../AttackScript.type';
import { AttackScriptPlatforms } from '../AttackScript.widgets';
import AttackScriptSeverity from '../AttackScriptView/AttackScriptSeverity';
import { AttackScriptHeaderPlaceholder } from '../AttackScriptView/AttackScriptViewHeader';

type Props = {
  onCancel(): void;
  onPublish(): void;
  permissionBlocked?: boolean;
  isActing: boolean;
};

export default function AttackScriptEditHeader(props: Props) {
  const { onCancel, onPublish, permissionBlocked, isActing } = props;
  const { dirty, isValid, resetForm, values } = useFormikContext<AttackScriptFormInput>();

  const [parsed] = React.useMemo(() => {
    return simpleParse<AtomicTestScript>(values.script);
  }, [values.script]);

  if (isActing) return <AttackScriptHeaderPlaceholder />;
  return (
    <>
      <Title className='AttackScriptHeader-title' headerText={parsed?.name} />
      <AttackScriptPlatforms />
      <div className='AttackScriptHeader-actions'>
        <AttackScriptSeverity />
        {permissionBlocked ? (
          <>
            <Typography variant='body2'>You do not have permission to edit this attack script.</Typography>
            <Button color='error' onClick={onCancel}>
              Cancel
            </Button>
          </>
        ) : (
          <>
            <Publish onClick={onPublish} disabled={!dirty || !isValid} startIcon={<Icon icon={faSquareUp} />}>
              Publish
            </Publish>
            <Menu dirty={dirty}>
              <BurgerCancel onClick={onCancel} />
              <BurgerPublish onClick={onPublish} disabled={!dirty || !isValid} />
              <BurgerReset onClick={resetForm} disabled={!dirty} />
            </Menu>
          </>
        )}
      </div>
    </>
  );
}
