import React from 'react';

import { faAngleRight, faMinus, faMinusCircle } from '@fortawesome/pro-solid-svg-icons';
import classnames from 'classnames';

import Icon from 'snap-ui/Icon';
import Tooltip from 'snap-ui/Tooltip';
import { styled } from 'snap-ui/util';

import { ABEventName, analyticBuilderEE } from 'events/analyticBuilder';

import { DetectionResultField, Token } from 'module/HighlightDetection';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  enableAddCondition?: boolean;
  field: string;
  ident: string;
  parent?: string;
  token: Token.Model;
  value: string;
  onLogClick?(key: string, value: string): void;
};

function getIconClasses(token: Token.Model): string {
  return `highlight-caret ${!token.inclusion ? 'excluded' : !token.satisfied ? 'unsatisfied' : ''}`;
}

function getIcon(parent: string, token: Token.Model) {
  if ((parent && token.satisfied) || (token.inclusion && token.satisfied)) return faAngleRight;
  if (!parent && !token.inclusion) return faMinusCircle;
  if (!token.satisfied) return faMinus;
}

const PropertyName = styled('span')`
  color: ${p => p.theme.palette.primary.main};
  font-weight: 400;

  &:after {
    content: ':';
  }
`;

export default function LogContentRow({
  enableAddCondition,
  field,
  ident,
  onLogClick,
  parent,
  token = {} as Token.Model,
  value,
  ...others
}: Props) {
  const compare = parent || ident;

  let popupContent;
  if (onLogClick && enableAddCondition) {
    popupContent = `Add ${compare} to detection logic`;
  } else if (!enableAddCondition) {
    popupContent = 'Select a log source to get started';
  }

  return (
    <div className={classnames('LogContentRow', { clickable: !!onLogClick })} {...others}>
      <dt className={classnames(`${field === compare ? `highlight` : ''}`, parent ? 'parent' : '')}>
        {token.property === compare ? (
          <Tooltip arrow placement='top-start' title={Token.represent(token)} wrap>
            <Icon className={getIconClasses(token)} icon={getIcon(parent, token)} />
          </Tooltip>
        ) : null}
        {popupContent ? (
          <Tooltip arrow placement='top-start' title={popupContent} wrap>
            <PropertyName>
              <a onClick={addLogToCondition(compare, value)}>{ident}</a>
            </PropertyName>
          </Tooltip>
        ) : (
          <PropertyName className='property-name'>{ident}</PropertyName>
        )}
      </dt>
      <dd>{field === compare ? <DetectionResultField token={token} /> : <pre>{value}</pre>}</dd>
    </div>
  );

  function addLogToCondition(key: string, value: string): () => void {
    return function (): void {
      if (onLogClick) onLogClick(key, value);
      else analyticBuilderEE.emit(ABEventName.AddCondition, { field: key, value });
    };
  }
}
