import React from 'react';

import { faCirclePlus } from '@fortawesome/pro-regular-svg-icons';
import { faCaretUp, faPen } from '@fortawesome/pro-solid-svg-icons';
import classnames from 'classnames';

import Accordion, { AccordionDetails, AccordionSummary, AccordionSummaryActionsWrapper } from 'snap-ui/Accordion';
import { ActionIconButton } from 'snap-ui/Button';
import Chip from 'snap-ui/Chip';
import Icon from 'snap-ui/Icon';
import Tooltip from 'snap-ui/Tooltip';
import Typography from 'snap-ui/Typography';
import { styled } from 'snap-ui/util';

import { pluralizeLandingType } from 'module/Landing/Landing.util';
import TruncateContainer from 'module/Layout/TruncateContainer';
import { Discriminator, Tag, reduceToBaseTags, sortTag } from 'module/Tag';

import { useNistTagCatalog } from 'provider/Nist';

import { renderValueWithLabel } from 'utilities/TextUtils';

import { InlineTagForm } from './TagInlineForm';

const Container = styled(Accordion, { name: 'AccordionTagListAccordion' })`
  background-color: inherit;
  margin: ${p => p.theme.spacing(6, 0)};

  .AccordionTagList-accordionDetails,
  .TruncateContainer {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: ${p => p.theme.spacing(3)};
  }

  .TruncateContainer:not(:last-of-type) {
    margin-bottom: ${p => p.theme.spacing(3)};
    padding: 0;
  }

  .Tag:hover {
    color: inherit;
  }

  .Tag {
    border: 1px solid ${p => p.theme.palette.secondary.dark};
  }

  .Tag.${Discriminator.Actor} {
    border: 1px solid ${p => p.theme.palette.yellow.main};
  }

  .Tag.${Discriminator.Attack} {
    border: 1px solid ${p => p.theme.palette.mitre.main};
  }

  .Tag.${Discriminator.Software}, .Tag.${Discriminator.Software}.tool {
    border: 1px solid ${p => p.theme.palette.secondary.contrastText};
  }

  .Tag.${Discriminator.Software}.malware {
    border: 1px solid ${p => p.theme.palette.orange.main};
  }

  .Tag.${Discriminator.Vulnerability} {
    border: 1px solid ${p => p.theme.palette.mint.main};
  }

  .Tag.${Discriminator.DataSource} {
    border: 1px solid ${p => p.theme.palette.cyan.main};
  }

  .Tag.sigma,
  .Tag.other,
  .Tag.${Discriminator.Action} {
    border: 1px solid ${p => p.theme.palette.blue.main};
  }
`;

const StyledChip = styled(Chip)`
  .MuiChip-label {
    display: flex;
    gap: ${p => p.theme.spacing(2)};
    align-items: center;
    line-height: 1;
  }
`;

export type AccordionTagListProps = {
  className?: string;
  kind: Discriminator;
  onSubmit?: InlineTagForm['onSubmit'];
  disabledQuickAddLists?: Discriminator[];
  tags: string[];
  title: string;
  tooltip?: string;
};

export function AccordionTagList(props: AccordionTagListProps) {
  const { className, kind, title, tooltip, onSubmit } = props;
  const [isEditing, setIsEditing] = React.useState(false);
  const { searchNistTags } = useNistTagCatalog();
  const isNist = kind === Discriminator.Action;
  const tags = isNist ? searchNistTags(props.tags) : reduceToBaseTags(props.tags, kind).sort(sortTag);
  const [expanded, setExpanded] = React.useState(tags?.length > 0);

  /**
   * handleClick handleChange handleClose will attempt to think for the user
   * by expanding accordion and toggling editing as necessary if there are no tags
   */

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setIsEditing(!isEditing);
    setExpanded(!isEditing ? true : tags?.length === 0 ? false : expanded);
  };

  const handleChange = () => {
    if (tags?.length === 0 && !expanded) setIsEditing(true);
    else if (expanded) setIsEditing(false);
    setExpanded(!expanded);
  };

  const handleClose = () => {
    setIsEditing(false);
    setExpanded(tags?.length === 0 ? false : expanded);
  };

  if ((!tags || tags.length === 0) && !onSubmit) return null;

  return (
    <Container
      className={classnames('AccordionTagList', className)}
      expanded={expanded}
      onChange={handleChange}
      elevation={0}
    >
      {title && (
        <AccordionSummaryActionsWrapper>
          <Typography variant='h4' id='accordionTagList-label'>
            {title}{' '}
            {tooltip && (
              <Tooltip title={tooltip} placement='right' arrow wrap>
                <Icon.Info size='xs' />
              </Tooltip>
            )}
          </Typography>
          {onSubmit && !isNist && (
            <Tooltip arrow placement='right' title={`Edit ${kind} tags`}>
              <ActionIconButton
                icon={faPen}
                size='small'
                color='stealth'
                aria-label={`Edit ${kind} tags`}
                onClick={handleClick}
              />
            </Tooltip>
          )}
          <AccordionSummary expandIcon={<Icon icon={faCaretUp} />} aria-controls='accordionTagList-content' />
        </AccordionSummaryActionsWrapper>
      )}
      <AccordionDetails
        className='AccordionTagList-accordionDetails'
        id='accordionTagList-content'
        aria-labelledby='accordionTagList-label'
      >
        {isEditing ? (
          <InlineTagForm kind={kind} onClose={handleClose} onSubmit={onSubmit} values={props.tags} />
        ) : (
          <TruncateContainer rows={1}>
            {tags.map(tag => (typeof tag === 'function' ? React.createElement(tag) : <Tag key={tag.name} tag={tag} />))}
          </TruncateContainer>
        )}
      </AccordionDetails>
    </Container>
  );
}

export type InlineTagListProps = {
  tags: string[];
  kind: Discriminator;
  preview?: number;
};
export function InlineTagList({ tags, kind, preview }: InlineTagListProps) {
  const { searchNistTags } = useNistTagCatalog();
  const isNist = kind === Discriminator.Action;
  if (!tags || tags.length === 0) return null;
  const baseTags = isNist ? searchNistTags(tags) : reduceToBaseTags(tags, kind).sort(sortTag);

  const display = baseTags.slice(0, preview);
  const remaining = baseTags.length - display.length;

  return (
    <>
      {display.map(tag => (
        <Tag key={tag.name} tag={tag} />
      ))}
      {remaining > 0 && (
        <Tooltip
          arrow
          title={renderValueWithLabel(remaining, `more ${kind}`, `more ${pluralizeLandingType(kind)}`)}
          placement='top'
        >
          <StyledChip
            size='small'
            label={
              <>
                <Icon icon={faCirclePlus} /> <span>{remaining}</span>
              </>
            }
          />
        </Tooltip>
      )}
    </>
  );
}
