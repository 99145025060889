import React, { useEffect } from 'react';

import isEmpty from 'lodash/isEmpty';

import { Host, Session } from 'module/Session/Session.type';

import { Status, useAsync } from 'storage';

import { Permitted } from 'types/auth';
import { Artifact, Guid } from 'types/common';

import { getSession, getSessionHosts, getSessionPermissions, getSessionReferences } from './Session.api';
import { SessionReferences } from './Session.type';

export interface SessionState {
  session: Session;
  setSession(s: Session): void;
  isPending: boolean;
  statuses: Status[];
  error: {
    session: any;
    references: any;
    permissions: any;
    hosts: any;
  };
  refresh(): void;
  preview: boolean;
}

export default function useSession(
  guid: Guid,
  artifact?: Artifact,
  refs?: SessionReferences,
  perms?: Permitted,
  host?: Host[]
): SessionState {
  const {
    data: session,
    run: sessionRun,
    status: sessionStatus,
    setData: setSession,
    error: sessionError
  } = useAsync<Artifact>();

  const {
    data: references,
    run: referencesRun,
    status: referencesStatus,
    setData: setReferences,
    error: referencesError
  } = useAsync<SessionReferences>();

  const {
    data: permissions,
    run: permissionsRun,
    status: permissionsStatus,
    setData: setPermissions,
    error: permissionsError
  } = useAsync<Permitted>();

  const {
    data: hosts,
    run: hostsRun,
    status: hostsStatus,
    setData: setHosts,
    error: hostsError
  } = useAsync<Host[]>([]);

  useEffect(() => {
    if (!guid) {
      setSession(null);
    } else if (sessionStatus === Status.pending) {
      // do nothing
    } else if (isEmpty(artifact)) {
      sessionRun(getSession(guid));
    } else {
      setSession(artifact);
    }
    // eslint-disable-next-line
  }, [guid]);

  useEffect(() => {
    if (!guid) {
      setReferences(null);
    } else if (referencesStatus === Status.pending) {
      // do nothing
    } else if (isEmpty(refs)) {
      referencesRun(getSessionReferences(guid));
    } else {
      setReferences(refs);
    }
    // eslint-disable-next-line
  }, [guid]);

  useEffect(() => {
    if (!guid) {
      setPermissions(null);
    } else if (permissionsStatus === Status.pending) {
      //do nothing
    } else if (isEmpty(perms)) {
      permissionsRun(getSessionPermissions(guid));
    } else {
      setPermissions(perms);
    }
    // eslint-disable-next-line
  }, [guid]);

  useEffect(() => {
    if (!guid) {
      setHosts([]);
    } else if (hostsStatus === Status.pending) {
      //do nothing
    } else if (isEmpty(host)) {
      hostsRun(getSessionHosts(guid));
    } else {
      setHosts(host);
    }
    // eslint-disable-next-line
  }, [guid]);

  const refresh = React.useCallback(() => {
    sessionRun(getSession(guid), true);
    referencesRun(getSessionReferences(guid), true);
    permissionsRun(getSessionPermissions(guid), true);
    hostsRun(getSessionHosts(guid), true);
  }, [guid, sessionRun, referencesRun, permissionsRun, hostsRun]);

  return {
    session:
      sessionError?.response?.status === 402
        ? sessionError.response.data
        : {
            ...session,
            ...references,
            hosts,
            ...permissions
          },
    isPending: [sessionStatus, referencesStatus, permissionsStatus, hostsStatus].includes(Status.pending),
    statuses: [sessionStatus, referencesStatus, permissionsStatus, hostsStatus],
    error: {
      session: sessionError,
      references: referencesError,
      permissions: permissionsError,
      hosts: hostsError
    },
    refresh,
    preview: sessionError?.response?.status === 402,
    setSession
  };
}
