import React from 'react';

import { faMinusSquare, faPlusSquare } from '@fortawesome/pro-regular-svg-icons';
import { faMinusCircle } from '@fortawesome/pro-solid-svg-icons';
import get from 'lodash/get';

import { ActionIconButton } from 'snap-ui/Button';
import Icon from 'snap-ui/Icon';
import Tooltip from 'snap-ui/Tooltip';
import { styled } from 'snap-ui/util';

import { Prerender, Processor, Token } from 'module/HighlightDetection';

import { useMountedRef } from 'storage';

import { LogRecord } from 'types/ide';

import LogContentRow from './LogContentRow';

const LogContentRoot = styled('dl')`
  margin-left: ${p => p.theme.spacing(8)};

  .LogContentRow.clickable dt {
    cursor: pointer;
  }

  dt {
    display: inline-block;
    color: ${p => p.theme.palette.primary.main};
    font-weight: 600;

    .button {
      padding: 0;

      &:after {
        content: ':';
      }

      &:hover {
        box-shadow: none !important;
        color: ${p => p.theme.palette.text.secondary} !important; /** TODO confirm */
      }
    }

    &.highlight {
      position: relative;
      color: ${p => p.theme.palette.success.main} !important;

      .property-name {
        color: ${p => p.theme.palette.success.main} !important;
      }

      &.parent button {
        // resetting the color for sub detections
        color: ${p => p.theme.palette.primary.main} !important;

        &:after {
          content: ':';
        }
      }

      .highlight-caret {
        position: absolute;
        left: -25px;
        top: 5px;
        color: ${p => p.theme.palette.success.main};
      }

      .highlight-caret.excluded {
        color: ${p => p.theme.palette.error.main};
      }

      .highlight-caret.unsatisfied {
        color: ${p => p.theme.palette.warning.main};
      }

      &.mod-40 {
        // Modifier.full
        .button {
          font-style: italic;
        }
      }
    }
  }

  dd {
    display: inline-block;
    margin-left: ${p => p.theme.spacing(2)};
    word-break: break-word;

    span {
      &.outline {
        border: 1px solid ${p => p.theme.palette.success.main};
      }

      &.outline.unsatisfied {
        border: 1px solid ${p => p.theme.palette.warning.main};
      }
      &.outline.excluded {
        border: 1px solid ${p => p.theme.palette.error.main};
      }
    }
  }

  .log-collapse {
    display: flex;

    & > dt {
      flex: 1;
      display: flex;
      align-items: center;

      &:after {
        content: '';
        height: 1px;
        background: ${p => p.theme.palette.text.secondary};
        flex: 1;
        margin-left: ${p => p.theme.spacing(2)};
      }
    }

    & > .button {
      color: ${p => p.theme.palette.text.secondary} !important;
      margin-right: ${p => p.theme.spacing(9)};
      padding: ${p => p.theme.spacing(1)};
      margin-left: ${p => p.theme.spacing(1)};
    }
  }
`;

const EXCLUDE_FIELD = ['host', 'Message'];

const FIELD_ORDER = [
  'EventID',
  'UtcTime',
  'ProcessName',
  'Image',
  'CommandLine',
  'ParentProcessName',
  'ParentImage',
  'ParentCommandLine',
  'User',
  'IntegrityLevel',
  'Hashes',
  'process_name',
  'process_path',
  'parent_process_path',
  'command_line',
  'start_time',
  'hashes'
];

function getFieldRank(field: string, order: string[]): number {
  // sort by the order above, then alphabetically if the field
  // is not one of those specified above for priority sorting
  const rank = order.indexOf(field);
  return rank === -1 ? order.length + field.toLowerCase().charCodeAt(0) : rank;
}

export type LogContentProps = {
  hideFieldHelper?: boolean;
  log: LogRecord | string;
  disableLogClick?: boolean;
  highlightProcessor?: Processor;
  onLogClick?(key: string, value: string): void;
};

function LogContent(props: LogContentProps): React.ReactElement {
  const [property, setProperty] = React.useState<string[][]>([]);

  const mounted = useMountedRef();

  const [detection, setDetection] = React.useState<{
    token: Token.Model[];
    field: string[];
  }>({ token: [], field: [] });

  const [logCollapse, toggleLogCollapse] = React.useReducer((state, key) => ({ ...state, [key]: !state[key] }), {});

  React.useEffect(() => {
    let log = props.log || ({} as LogRecord);

    if (typeof log === 'string') {
      try {
        log = JSON.parse(log) as LogRecord;
      } catch (e) {
        console.error('Trying to parse LogRecord from string', e);
      }
    }

    const mapped = Object.entries(log).filter(([key]) => key !== '_raw' && key !== 'splunk_raw');

    setProperty(mapped);
  }, [props.log]);

  React.useEffect(() => {
    async function fetchData() {
      if (property.length > 0) {
        const response = await Prerender(property, props.highlightProcessor);
        if (mounted.current) setDetection(response);
      }
    }
    fetchData();
  }, [property, props.highlightProcessor]); // eslint-disable-line react-hooks/exhaustive-deps

  // combine our highlighted fields and default field ordering into a unique list
  const order = Array.from(new Set([...detection.field, ...FIELD_ORDER]));
  return (
    <LogContentRoot className='LogContent'>
      {property
        .filter(([key]) => !key.startsWith('_') && !EXCLUDE_FIELD.includes(key))
        .sort((a, b) => {
          const aRank = getFieldRank(a[0], order);
          const bRank = getFieldRank(b[0], order);
          return aRank - bRank;
        })
        .map(([key, value]) => {
          if (Array.isArray(value)) {
            const subDetection = {
              field: detection.field.filter(f => f === key),
              token: detection.token.filter(t => t.property === key)
            };

            const inclusion = get(subDetection, ['token', 0, 'inclusion']);

            return (
              <div key={key}>
                <div style={{ display: 'flex' }}>
                  <dt className={subDetection.field.includes(key) ? 'highlight' : ''}>
                    {inclusion === false && (
                      <>
                        <Tooltip arrow placement='top-start' title='Missing from condition expression' wrap>
                          <Icon icon={faMinusCircle} />
                        </Tooltip>
                        &nbsp; &nbsp;
                      </>
                    )}
                    {key}
                  </dt>
                  <div className='log-collapse'>
                    <ActionIconButton
                      aria-label='collapse section'
                      icon={logCollapse[key] ? faPlusSquare : faMinusSquare}
                      size='small'
                      onClick={(): void => toggleLogCollapse(key)}
                    />
                  </div>
                </div>
                {!logCollapse[key] &&
                  value.map((v, sub) => (
                    <LogContentRow
                      key={`${key}-${v}`}
                      enableAddCondition={!props.disableLogClick}
                      ident={`${sub}`}
                      field={subDetection.field[sub]}
                      parent={key}
                      token={subDetection.token[sub]}
                      value={v}
                      style={{ marginLeft: '2em' }}
                      onLogClick={props.onLogClick}
                    />
                  ))}
              </div>
            );
          } else {
            const subject = detection.field.indexOf(key);
            return (
              <LogContentRow
                key={key}
                enableAddCondition={!props.disableLogClick}
                ident={key}
                field={detection.field[subject]}
                token={detection.token[subject]}
                value={value}
                onLogClick={props.onLogClick}
              />
            );
          }
        })}
    </LogContentRoot>
  );
}

export default LogContent;
