import React from 'react';

import {
  faHexagonCheck as faHexagonCheckOutlined,
  faHexagon as faHexagonOutlined,
  faHexagonXmark as faHexagonXmarkOutlined,
  IconDefinition
} from '@fortawesome/pro-regular-svg-icons';
import {
  faHexagonCheck as faHexagonCheckSolid,
  faHexagonXmark as faHexagonXmarkSolid
} from '@fortawesome/pro-solid-svg-icons';

import { Integration } from 'module/Integration/Integration.type';
import { SupplementalArtifact } from 'module/Search/Search.type';

import { AnalyticDeploymentDetail, DeploymentIntegration } from 'types/analytic';

import { renderValueWithLabel } from 'utilities/TextUtils';

import { getCompatibleIntegration } from './util';

export function isCustomerManaged(integration: Integration): boolean {
  return integration.type === 'CustomerManaged';
}

export function isNotDeployable(integration: Integration, deployables: Integration[]): boolean {
  return !deployables?.some(deployment => deployment.id === integration.id);
}

export function isSupplementallyDeployable(integration: Integration, supplemental: SupplementalArtifact): boolean {
  return supplemental?.deployable_integrations?.some(sd => sd.guid === integration.guid);
}

export function createDeployableIntegration(
  integration: Integration,
  analytic: SupplementalArtifact,
  orgDeployments: AnalyticDeploymentDetail[]
): DeploymentIntegration {
  return {
    ...integration,
    deployment: findDeploymentForIntegration(integration, orgDeployments),
    compatible: getCompatibleIntegration(analytic, integration)
  };
}

export function findDeploymentForIntegration(integration: Integration, orgDeployments: AnalyticDeploymentDetail[]) {
  return orgDeployments?.find(dep => dep.integrations?.some(i => i.guid === integration.guid));
}

export function getDeploymentStatus(integration: DeploymentIntegration): {
  color?: 'success' | 'error';
  icon: IconDefinition;
  message: string;
  action?: string;
  tooltip?: string;
} {
  const { guid: integrationGuid, deployment, compatible } = integration;
  if (deployment?.success_deployment?.some(({ guid }) => guid === integrationGuid))
    return { color: 'success', icon: faHexagonCheckSolid, message: 'Deployed', action: 'undeploy' };
  if (deployment?.pending_deployment?.some(({ guid }) => guid === integrationGuid))
    return { color: 'success', icon: faHexagonCheckOutlined, message: 'Queued', action: 'undeploy' };
  if (deployment?.failed_deployment?.some(({ guid }) => guid === integrationGuid)) {
    const error_msg = deployment?.status?.find(({ integration }) => integration === integrationGuid)?.error_message;
    return {
      color: 'error',
      icon: faHexagonXmarkSolid,
      message: 'Error',
      action: 'undeploy',
      tooltip: error_msg
    };
  }
  if (compatible) return { icon: faHexagonOutlined, message: 'Not deployed', action: 'deploy' };

  return { icon: faHexagonXmarkOutlined, color: 'error', message: 'Not compatible' };
}

export function VersionMessage({ version }: { version: number }) {
  return version > 0 ? (
    <span className='versionLineage'>
      {renderValueWithLabel(version, 'Version')} Behind
      <br />
      Click To See Version Changes
    </span>
  ) : null;
}
