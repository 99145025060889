import React from 'react';

import { Link } from 'react-router-dom';

import Checkbox from 'snap-ui/Checkbox';
import Chip from 'snap-ui/Chip';
import Placeholder from 'snap-ui/Placeholder';
import Table, { TablePlaceholder } from 'snap-ui/Table';
import TableSortLabel from 'snap-ui/TableSortLabel';
import { styled } from 'snap-ui/util';

import Path from 'constants/paths';

import useTableControls from 'hooks/useTableControls';

import ValidationBadge from 'module/Analytic/core/ValidationBadge';
import { useDetectionCount } from 'module/Detection';
import DetectionFeedMenu from 'module/Feed/DetectionFeedMenu';
import { DisplayKind } from 'module/Feed/Feed.type';
import { useSupplementalItems } from 'module/Search';
import { TableSearchInformationalMessage } from 'module/Setting/core/Core.style';
import { ScoreBadge } from 'module/Widgets/Badge';
import TablePagination from 'module/Widgets/TablePagination';
import TableToolbar from 'module/Widgets/TableToolbar';

import { FeedExtraProvider, useAuth } from 'provider';

import { Status } from 'storage';

import { AnalyticLogState } from 'types/analytic';
import { Artifact, ArtifactScore, ArtifactType } from 'types/common';
import { SortDirection } from 'types/filter';

import { getPreferredOrgScore } from 'utilities/ArtifactUtils';
import { NOOP } from 'utilities/FunctionUtils';

import { useCampaignRecommend } from '../CampaignReport/useCampaignRecommend';
import { Container } from './CampaignRecommenderList.style';
import { ColumnComponentProps } from './common';

const StyledChip = styled(Chip)`
  min-width: 100px;
  height: 1.55rem;
  border-radius: 3px;
  color: ${p => p.theme.palette.common.black};
  width: 100px;
  background-color: ${p => p.theme.palette.success.main};
`;

function filterFn(query: string, item: Artifact): boolean {
  return item.name?.toLowerCase().includes(query?.toLowerCase());
}

function CampaignRecommenderList({ campaignGuid }: { campaignGuid: string }) {
  const { defaultOrgId } = useAuth();

  const { data: recommendDetections, getRecommendation, status: recommendStatus } = useCampaignRecommend(campaignGuid);
  const [selectedDetections, setSelectedDetections] = React.useState<Artifact[]>(recommendDetections);

  const {
    createSortHandler,
    data: visibleRows,
    order,
    setPage,
    setQuery,
    page,
    pageTotal
  } = useTableControls(recommendDetections, filterFn, { field: 'name', direction: SortDirection.asc }, 10);

  const { detection: detectionCatalog, status: detectionCatalogStatus } = useDetectionCount(
    ArtifactType.Analytic,
    recommendDetections
  );
  const { supplemental: supplementalCatalog, status: supplementalStatus } = useSupplementalItems(
    ArtifactType.Analytic,
    recommendDetections
  );

  const [allAttachedItemsCheckbox, setAllAttachedItemsCheckbox] = React.useState(false);

  const isRecommendLoading = recommendStatus === Status.pending;

  React.useEffect(() => {
    getRecommendation();
  }, [getRecommendation]);

  function handleToggleAllCheckbox(_event: React.SyntheticEvent<Element, Event>, checked: boolean) {
    setSelectedDetections(checked ? recommendDetections : []);
    setAllAttachedItemsCheckbox(checked);
  }
  const handleCheckboxChange = React.useCallback(
    (event: React.SyntheticEvent<Element, Event>, checked: boolean) => {
      const item = recommendDetections.find(i => i.guid === event.currentTarget.id);
      const newSelectedDetectionItems = checked
        ? [...selectedDetections, item]
        : selectedDetections.filter(i => i.guid !== item.guid);
      setSelectedDetections(newSelectedDetectionItems);
      setAllAttachedItemsCheckbox(newSelectedDetectionItems.length === recommendDetections.length ? true : false);
    },
    [recommendDetections, selectedDetections]
  );
  const visibleAttachedItems = React.useMemo(
    () => selectedDetections.filter(item => visibleRows.find(pageItem => pageItem.guid === item.guid)),
    [visibleRows, selectedDetections]
  );
  const columns = [
    <Checkbox
      key='check-all'
      onChange={handleToggleAllCheckbox}
      checked={allAttachedItemsCheckbox}
      intermediate={selectedDetections.length > 0 && selectedDetections.length !== visibleAttachedItems.length}
    />,
    <TableSortLabel key='name' field='name' label='Name' sortHandler={createSortHandler('name')} order={order} />,
    'Validation State',
    'Confidence',
    'Severity',
    'Deployed'
  ];
  const row = [
    {
      component: ({ detection }: ColumnComponentProps) => {
        return (
          <Checkbox
            id={detection.guid}
            onChange={handleCheckboxChange}
            value={detection.guid}
            checked={selectedDetections.some(s => s.guid === detection.guid)}
          />
        );
      }
    },
    {
      component: ({ detection }: ColumnComponentProps) => {
        return (
          <div className='tableNameField'>
            <Link to={`${Path.Detection}/${detection.guid}`}>{detection.name}</Link>
          </div>
        );
      }
    },
    {
      component: ({ detection, print }: ColumnComponentProps) => {
        const supplemental = detectionCatalog[detection?.guid];
        if (supplementalStatus === Status.pending) return <Placeholder variant='text' height={30} width={100} />;
        if (!supplemental) return null;
        function getBadgeState() {
          if (supplemental.validated > 0) return AnalyticLogState.VALIDATED;
          else if (supplemental.validated_gaps > 0) return AnalyticLogState.VALIDATED_GAPS;
          else if (supplemental.unvalidated > 0) return AnalyticLogState.UNVALIDATED;
          else if (supplemental.validated === 0 && supplemental.validated_gaps === 0 && supplemental.unvalidated === 0)
            return AnalyticLogState.UNTESTED;
        }
        return print ? (
          <span>{getBadgeState()}</span>
        ) : (
          <ValidationBadge
            validated={supplemental.validated}
            unvalidated={supplemental.unvalidated}
            validated_gaps={supplemental.validated_gaps}
          />
        );
      }
    },
    {
      component: ({ detection, print }: ColumnComponentProps) => {
        const supplemental = supplementalCatalog[detection.guid];
        if (supplementalStatus === Status.pending) return <Placeholder variant='text' height={30} width={100} />;

        const score = getPreferredOrgScore(defaultOrgId, supplemental?.ranks, detection?.rank);

        return print ? <span>{score}</span> : <ScoreBadge className='scoreBadge' name='CONFIDENCE' score={score} />;
      }
    },
    {
      component: ({ detection, print }: ColumnComponentProps) => {
        const supplemental = supplementalCatalog[detection.guid];
        if (supplementalStatus === Status.pending) return <Placeholder variant='text' height={30} width={100} />;
        if (!supplemental)
          return (
            <ScoreBadge className='scoreBadge' name='SEVERITY' score={detection.severity || ArtifactScore.UNKNOWN} />
          );

        const score = getPreferredOrgScore(defaultOrgId, supplemental.ranks, detection.rank);
        return print ? <span>{score}</span> : <ScoreBadge name='SEVERITY' score={score} />;
      }
    },
    {
      component: ({ detection, print }: ColumnComponentProps) => {
        const supplemental = supplementalCatalog[detection.guid]?.deployments?.some(
          deployment => deployment.organization_id === defaultOrgId
        );
        if (supplementalStatus === Status.pending) return <Placeholder variant='text' height={30} width={100} />;
        return supplemental && print ? (
          <span>DEPLOYED</span>
        ) : supplemental && !print ? (
          supplemental && <StyledChip className='standard-badge' label='DEPLOYED' />
        ) : (
          <div></div>
        );
      }
    }
  ];

  return (
    <FeedExtraProvider
      detection={detectionCatalog}
      supplemental={supplementalCatalog}
      detectionPending={detectionCatalogStatus === Status.pending}
      supplementalPending={supplementalStatus === Status.pending}
    >
      <div className='CampaignReport-section'>
        <Container className='no-print' elevation={5}>
          <TableToolbar onSearchChange={setQuery} placeholder='Search by detection name'>
            <DetectionFeedMenu
              feedTotal={visibleRows.length}
              hasActivity={false}
              displayKind={DisplayKind.Grid}
              onDisplayKindChange={() => NOOP}
              selectionModel={
                selectedDetections.length > 0
                  ? selectedDetections.map(i => i.guid)
                  : recommendDetections.map(d => d.guid)
              }
            />
          </TableToolbar>

          <Table
            className='recommenderTable'
            columns={columns}
            component='div'
            rows={visibleRows.map(detection => {
              return row.map(component => {
                return {
                  component: React.createElement(component['component'], {
                    detection
                  })
                };
              });
            })}
          />
          {isRecommendLoading && <TablePlaceholder count={10} height={30} />}
          {!isRecommendLoading && visibleRows?.length === 0 && (
            <TableSearchInformationalMessage>
              There are no recommended detections for this attack simulation.
            </TableSearchInformationalMessage>
          )}
          <TablePagination page={page} changePage={setPage} numPages={pageTotal} zeroIndex />
        </Container>
        <div className='CampaignReport-section print-only'>
          <Container elevation={5}>
            <Table
              className='recommenderTable'
              columns={columns}
              component='div'
              rows={(selectedDetections.length > 0 ? selectedDetections : recommendDetections).map(detection => {
                return row.map(component => {
                  return {
                    component: React.createElement(component['component'], {
                      detection,
                      print: true
                    })
                  };
                });
              })}
            />
          </Container>
        </div>
      </div>
    </FeedExtraProvider>
  );
}

export default CampaignRecommenderList;
