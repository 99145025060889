import React from 'react';

import Paper from 'snap-ui/Paper';
import { styled } from 'snap-ui/util';

import SliderFormik from 'module/Form/SliderFormik';

import ThreatProfileStep from '../ThreatProfileStep';

const Container = styled(Paper, { name: 'SliderContainer' })`
  padding: 60px;
  .MuiSlider-markLabel {
    text-align: center;
  }
`;

const marks = [
  {
    value: 3, // months
    label: (
      <>
        90
        <br />
        days
      </>
    )
  },
  {
    value: 6, // months
    label: (
      <>
        6<br />
        months
      </>
    )
  },
  {
    value: 12, // months
    label: (
      <>
        1<br />
        Year
      </>
    )
  },
  {
    value: 2 * 12, // months
    label: (
      <>
        2<br />
        Years
      </>
    )
  },
  {
    value: 3 * 12, // months
    label: (
      <>
        3<br />
        Years
      </>
    )
  },
  {
    value: 5 * 12, //months
    label: (
      <>
        5<br />
        Years
      </>
    )
  },
  {
    value: 7 * 12, //months
    label: (
      <>
        All
        <br />
        Time
      </>
    )
  }
];

const lastMarkValue = marks[marks.length - 1].value;

export default function ThreatLastObserved(): JSX.Element {
  return (
    <ThreatProfileStep
      title='What is your last observed threshold for attacker tools and tradecraft?'
      description='Attacker tradecraft is in a constant state of evolution, driven by the need to bypass increasingly sophisticated security measures and remain undetected. As threat actors develop new tactics and techniques, they may abandon previously employed methods that have become less effective or are more likely to be detected by modern security solutions. Therefore, if an actor has not been observed using a specific technique for an extended period, it may no longer be relevant or necessitate dedicated detection mechanisms.'
      content={
        <Container>
          <SliderFormik
            name='last_observed_threshold'
            className='LastObservedThreshold'
            min={3}
            marks={marks}
            step={null}
            max={lastMarkValue}
            valueLabelDisplay='off'
          />
        </Container>
      }
    />
  );
}
