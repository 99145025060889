import React from 'react';

import { AxiosError } from 'axios';
import classnames from 'classnames';
import { Formik, FormikProps } from 'formik';
import get from 'lodash/get';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { object, string } from 'yup';

import { Alert, AlertTitle } from 'snap-ui/Alert';
import BackdropLoader from 'snap-ui/BackdropLoader';

import useCompositeMarker from 'aso/useCompositeMarker';

import Path from 'constants/paths';

import useLocationState from 'hooks/useLocationState';

import { Engage, Fingerprint } from 'lib/Engagement';

import { canI } from 'module/Can';
import { DetectionStatsResources } from 'module/Detection';
import useDetectionStats from 'module/Detection/useDetectionStats';
import { standardFormikBaseProps } from 'module/Form';
import TextFieldFormik from 'module/Form/TextFieldFormik';
import { updateMachineDisplayName, updateSessionMeta } from 'module/Session/Session.api';
import { SessionDetailsPlaceholder, decomposeSession } from 'module/Session/Session.helper';
import * as SessionTypes from 'module/Session/Session.type';
import useSession from 'module/Session/useSession';
import { draftJSToMarkdownRawParser } from 'module/Widgets/Markdown/Markdown.util';

import { useAuth, useUserCatalog } from 'provider';

import { orgIdOrDefault } from 'services/organizationService';

import { ContentPermission } from 'types/auth';
import * as CommonTypes from 'types/common';
import { Artifact as ArtifactModel, Visibility } from 'types/common';

import HostSelector from '../HostSelector';
import { SessionEditRouterState } from '../Session.type';
import SessionCore from '../SessionCore';
import useSessionHost from '../useSessionHost';
import ReviewSessionForm from './ReviewSessionForm';
import SessionEditHeader from './SessionEditHeader';

export type SessionEditForm = {
  organization_id: string;
  owner: string;
  name: string;
  visibility: Visibility;
  description: string;
  attack_names: string[];
  actor_names: string[];
  software_names: string[];
  vulnerability_names: string[];
  references: string[];
  displayNames: { [index: number]: string };
};

type Props = RouteComponentProps<{ guid: string }> & {
  className?: string;
};

export default function SessionEdit(props: Props): React.ReactElement {
  const { replace } = useHistory();
  const { permission: organizations, defaultOrgId } = useAuth();
  const { users } = useUserCatalog();
  const sessionGuid = props.match.params.guid;

  const { item, permissions, references, hosts } = useLocationState<SessionEditRouterState>() || {};

  const { session, isPending, error: sessionErrors } = useSession(sessionGuid, item, references, permissions, hosts);
  const host = useSessionHost(session.hosts);
  const hasHostGraph = host.graph && host.graph.length > 0;
  const startTime = get<SessionTypes.Host, 'start', string>(
    host,
    'start',
    get<SessionTypes.Session, 'creation'>(session, 'creation')
  );
  const detectionStats = useDetectionStats(DetectionStatsResources.session, sessionGuid);
  const composite = useCompositeMarker(
    sessionGuid,
    detectionStats.detection,
    host.start,
    host.is_victim ? host.machine?.name : ''
  );

  const [error, setError] = React.useState<string>(null);
  const [displayState, setDisplayState] = React.useState(CommonTypes.DisplayState.Ready);
  const videoRef = React.useRef<HTMLVideoElement>();

  React.useEffect(() => {
    if (session.guid && session.name) {
      Engage.track(
        Fingerprint.load(Path.Threat).withData({
          guid: session.guid,
          name: session.name,
          artifact_organization_id: session.organization_id,
          artifact_organization_name: organizations?.find(o => o.id === session.organization_id)?.name
        })
      );
      Engage.trackPersonIncrement(`view ${Path.Threat}`, 1);
    }
  }, [session.guid, session.name, organizations, session.organization_id]);

  const displayNames = React.useMemo(() => {
    const mapping = session?.hosts?.reduce(
      (prev, curr) => ({
        ...prev,
        [curr.machine.id]: curr.machine.display_name || ''
      }),
      {}
    );
    return mapping;
  }, [session?.hosts]);

  if (session.visibility === Visibility.Deleted) {
    redirectToViewPage(session, true);
  }

  const canIEditSession = canI(ContentPermission.Edit, session);

  return (
    <Formik<SessionEditForm>
      {...standardFormikBaseProps}
      initialValues={{
        organization_id: orgIdOrDefault(session.organization_id, defaultOrgId).toString(),
        name: session.name || '',
        owner: users.find(user => user.id === session.created_by_id)?.name,
        description: draftJSToMarkdownRawParser(session.description) || '',
        references: session.references || [],
        attack_names: session.attack_names || [],
        actor_names: session.actor_names || [],
        software_names: session.software_names || [],
        vulnerability_names: session.vulnerability_names || [],
        visibility: session.visibility || Visibility.Draft,
        displayNames
      }}
      onSubmit={handleSubmit}
      validationSchema={object().shape({
        name: string().required('Threat name is required'),
        description: string().required('Description is required')
      })}
    >
      {({ submitForm, ...others }: FormikProps<SessionEditForm>): React.ReactElement => {
        function handleCreatePublish() {
          // submit form (save session), then publish session, then redirect to view
          setDisplayState(CommonTypes.DisplayState.Loading);
          setError(null);
          others.setValues({ ...others.values, visibility: Visibility.Published });
          return submitForm()
            .then((resp: any) => {
              if (resp) redirectToViewPage(resp);
              else setDisplayState(CommonTypes.DisplayState.Ready);
            })
            .catch(error => {
              handleApiError(error);
              setDisplayState(CommonTypes.DisplayState.Ready);
            });
        }
        function handleCreateDraft() {
          // submit form (save session), then redirect to view
          setDisplayState(CommonTypes.DisplayState.Loading);
          setError(null);
          others.setValues({ ...others.values, visibility: Visibility.Draft });
          return submitForm()
            .then((resp: any) => {
              if (resp) redirectToViewPage(resp);
              else setDisplayState(CommonTypes.DisplayState.Ready);
            })
            .catch(error => {
              handleApiError(error);
              setDisplayState(CommonTypes.DisplayState.Ready);
            });
        }

        return (
          <SessionCore
            className={props.className}
            data={session}
            error={
              sessionErrors.session || sessionErrors.permissions || sessionErrors.references || sessionErrors.hosts
            }
            host={host}
            hasHostGraph={hasHostGraph}
            composite={composite}
            detectionStats={detectionStats}
            meta={
              <SessionEditHeader
                isDraft={session.visibility === Visibility.Draft}
                onCancel={handleCancel}
                onDraft={() => handleCreateDraft()}
                onPublish={() => handleCreatePublish()}
                permissionBlocked={!canIEditSession}
                session={session}
              />
            }
            startTime={startTime}
            sessionGuid={sessionGuid}
            videoRef={videoRef}
            preview={false}
            hostSelector={
              <HostSelector
                activeHostName={host.machine.name}
                hosts={session.hosts}
                getCurrentTime={() => videoRef.current?.currentTime | 0 || 0}
              >
                {({ onSelectorClick, getButtonColor, isHostActive, hosts }) =>
                  hosts.map(
                    (h, index) =>
                      h.machine.id in others.values.displayNames && (
                        <div
                          key={h.id}
                          id={h.machine.name}
                          data-active={isHostActive(h)}
                          className={classnames('item', getButtonColor(h), {
                            active: isHostActive(h)
                          })}
                          onClick={onSelectorClick}
                        >
                          <TextFieldFormik
                            variant='outlined'
                            label={`Host machine ${index + 1}`}
                            name={`displayNames.${h.machine.id}`}
                          />
                        </div>
                      )
                  )
                }
              </HostSelector>
            }
          >
            {isPending ? (
              <SessionDetailsPlaceholder />
            ) : (
              <>
                <BackdropLoader title='Saving...' open={displayState === CommonTypes.DisplayState.Loading} fixed />
                {!!error && (
                  <Alert severity='error'>
                    <AlertTitle>Error</AlertTitle>
                    {error}
                  </Alert>
                )}
                <ReviewSessionForm permissionBlocked={!canIEditSession} />
              </>
            )}
          </SessionCore>
        );
      }}
    </Formik>
  );

  async function handleSubmit(meta: SessionEditForm): Promise<CommonTypes.Tracked> {
    const payload = { ...meta };
    delete payload.displayNames;
    delete payload.owner;
    delete payload.organization_id;
    delete payload.attack_names;
    const result = await updateSessionMeta(sessionGuid, payload as unknown as Partial<CommonTypes.Artifact>);

    const displayNamePromises = Object.entries(meta.displayNames).map(([key, value]) =>
      updateMachineDisplayName(key as unknown as CommonTypes.Ident, value)
    );

    await Promise.all(displayNamePromises);
    return result;
  }

  function handleCancel() {
    replace(`${Path.Threat}/${sessionGuid}`, session ? decomposeSession(session) : null);
  }

  function handleApiError(error: AxiosError): void {
    setError(get(error, 'response.data.message', 'Oops! Something went wrong'));
  }

  function redirectToViewPage(resp: ArtifactModel, replace = false): void {
    const action = replace ? props.history.replace : props.history.push;
    action(`${Path.Threat}/${sessionGuid}`, {
      item: resp
    });
  }
}
