import { Option } from 'snap-ui/Autocomplete';

import { IDEState } from 'module/IDE/reducer';
import { Integration, SchemaSupported } from 'module/Integration/Integration.type';
import { IntegrationOption } from 'module/Integration/IntegrationAutocomplete';

import { Ident } from 'types/common';

export function hasLogsource(ideState: IDEState): boolean {
  const logsource = ideState.analyticForm.logsource;
  return !!logsource?.product && (!!logsource?.category || !!logsource?.service);
}

export function getLanguageByIntegration(languageOptions: Option[], integration: Integration): Option[] {
  if (integration === undefined || integration == null) {
    return [] as Option[];
  }
  const selectedOptions = languageOptions.filter(l =>
    integration?.deployment_targets?.some(target => target.id.toString() === l.value)
  );
  return selectedOptions;
}

export function getTargets(
  integration: Integration,
  languageTargetTypes?: ('deployment' | 'hunt' | 'search')[]
): Integration['deployment_targets'] {
  if (!languageTargetTypes) {
    return [
      ...(integration?.deployment_targets ?? []),
      ...(integration?.hunt_targets ?? []),
      ...(integration?.search_targets ?? [])
    ];
  }

  return languageTargetTypes.flatMap(target => integration?.[`${target}_targets`] ?? []);
}

export function getIntegrationValueFromLanguageId(
  languageId: Ident,
  integrationOptions: IntegrationOption[],
  integrations: Integration[],
  supportedPlatforms: SchemaSupported
): string {
  return integrationOptions.find(option => {
    if (option.value.includes('language-')) {
      return option.value.replace('language-', '') === languageId.toString();
    }

    const integration = integrations.find(i => i.id.toString() === option.value);
    if (integration) return getTargets(integration).some(target => target.id === languageId);

    const platform = supportedPlatforms[option.type];
    if (platform) {
      return (
        platform.deploy.includes(languageId) ||
        platform.hunt.includes(languageId) ||
        platform.search.includes(languageId)
      );
    }
  })?.value;
}
