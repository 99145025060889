import React from 'react';

import { useField } from 'formik';

import Typography from 'snap-ui/Typography';
import { styled } from 'snap-ui/util';

import TextFieldFormik from 'module/Form/TextFieldFormik';

import { ArtifactScore } from 'types/common';

const Container = styled('div')`
  display: flex;
  gap: ${p => p.theme.spacing(6)};
  align-items: center;
  .levelName {
    flex-basis: 10%;
  }
  .message {
    margin: 0;
    font-size: 0.8125rem;
  }
`;

function BulkConfidenceThresholdInput({
  level,
  priorThreshold
}: {
  level: ArtifactScore;
  priorThreshold: number;
  isDisable?: boolean;
}) {
  const [field] = useField(level);
  const isDisabled = level === ArtifactScore.LOWEST;
  const prefix = 'Detections with ';
  const suffix = ' will be labelled "' + level + '" confidence';
  let message = '';

  if (level === ArtifactScore.HIGHEST) {
    if (field.value === 0) {
      message = prefix + '0 hits' + suffix;
    } else {
      message = prefix + '0-' + field.value + ' hits' + suffix;
    }
  } else if (level === ArtifactScore.LOWEST) {
    message = prefix + (priorThreshold + 1) + ' and above hits' + suffix;
  } else {
    message = prefix + (priorThreshold + 1) + '-' + field.value + ' hits' + suffix;
  }

  return (
    <Container>
      <Typography className='levelName' variant='h4'>
        {level}
      </Typography>
      <TextFieldFormik
        type='number'
        label='Max Number'
        InputProps={{ inputProps: { min: 0 } }}
        name={level}
        value={isDisabled ? priorThreshold : field.value}
        disabled={isDisabled}
        required
      />
      <p className='message'>{message}</p>
    </Container>
  );
}

export default BulkConfidenceThresholdInput;
