import React from 'react';

import {
  faCloudArrowUp,
  faDial,
  faEdit,
  faEllipsisVertical,
  faFileCircleInfo,
  faSplit,
  faTrash
} from '@fortawesome/pro-solid-svg-icons';
import { Link } from 'react-router-dom';

import { GridColDef } from 'snap-ui/DataGrid';
import { MenuButton, MenuTrigger } from 'snap-ui/Menu';
import Placeholder from 'snap-ui/Placeholder';
import TableErrorMessage from 'snap-ui/TableErrorMessage';

import Path from 'constants/paths';

import { getDeployDisabledTooltip } from 'module/Analytic/core/MetadataPanel/util';
import { BurgerClicker, BurgerLink } from 'module/Layout/Artifact.widgets';
import Badge from 'module/Widgets/Badge';

import { formatQueryString } from 'utilities/SearchParam';
import { timeAgo } from 'utilities/TimeUtils';

import { AsyncInterface, DeployedFeeds } from '../';
import { OutdatedAugment } from './Tables.type';

type Col<T = AsyncInterface['items'][number]> = GridColDef<T>;

export const detectionName: Col = {
  field: 'detection.name',
  headerName: 'Detection',
  valueGetter: params => params.row.detection.name,
  renderCell: params => <Link to={`${Path.Detection}/${params.row.detection.guid}`}>{params.row.detection.name}</Link>,
  flex: 2,
  minWidth: 300
};

export const detectionUrl: Col = {
  field: 'detectionUrl',
  headerName: 'Detection URL',
  valueGetter: params => `${window.location.origin}${Path.Detection}/${params.row.detection.guid}`
};

export const integrationName: Col = {
  field: 'integration.name',
  headerName: 'Integration',
  valueGetter: params => params.row.integration?.name,
  flex: 1,
  minWidth: 200
};

export const errorMessage: Col<DeployedFeeds['deploymentErrors']['items'][number]> = {
  field: 'error_message',
  headerName: 'Error Message',
  renderCell: params => <TableErrorMessage message={params.row.error_message} />,
  minWidth: 375
};

export const errorActions = (onUndeploy: (id: number) => void): Col => ({
  field: 'actions',
  headerName: 'Actions',
  renderHeader: () => null,
  resizable: false,
  sortable: false,
  maxWidth: 50,
  renderCell: params => (
    // TODO: check for permission to edit
    <MenuTrigger trigger={<MenuButton aria-label='Deployment Menu' icon={faEllipsisVertical} />}>
      <BurgerLink
        icon={faEdit}
        title='Edit Detection'
        to={{ pathname: Path.IDE, search: formatQueryString({ detection: params.row.detection.guid }) }}
      />
      <BurgerLink icon={faSplit} title='Edit Field Mappings' to={{ pathname: Path.LanguageConfig }} />
      <BurgerClicker icon={faTrash} title='Undeploy Detection' onClick={() => onUndeploy(params.row.deploymentId)} />
    </MenuTrigger>
  )
});

export const confidence: Col<DeployedFeeds['needTuning']['items'][number]> = {
  field: 'confidence',
  headerName: 'Confidence',
  renderCell: params => <Badge badgeSignature={params.row.confidence} badgeName='CONFIDENCE' />,
  minWidth: 125
};

export const tuningActions = (onUndeploy: (id: number) => void): Col => ({
  field: 'actions',
  headerName: 'Actions',
  renderHeader: () => null,
  resizable: false,
  sortable: false,
  maxWidth: 50,
  renderCell: params => (
    // TODO: check for permission to edit and tune
    <MenuTrigger trigger={<MenuButton aria-label='Tuning Menu' icon={faEllipsisVertical} />}>
      <BurgerLink
        icon={faEdit}
        title='Edit Detection'
        to={{ pathname: Path.IDE, search: formatQueryString({ detection: params.row.detection.guid }) }}
      />
      <BurgerLink
        icon={faDial}
        title='Tune Detection'
        to={{
          pathname: `${Path.Detection}/${params.row.detection.guid}/tuning`
        }}
        target='_blank'
        rel='noopener noreferrer'
      />
      <BurgerClicker icon={faTrash} title='Undeploy Detection' onClick={() => onUndeploy(params.row.deploymentId)} />
    </MenuTrigger>
  )
});

export const versionsBehind: Col<DeployedFeeds['outdatedDeployments']['items'][number] & OutdatedAugment> = {
  field: 'versions_behind',
  headerName: 'Versions Behind',
  valueGetter: params => params.row.deltas.versions || 0,
  renderCell: params =>
    params.row.deltas.versions === null ? <Placeholder variant='text' width={15} /> : params.row.deltas.versions,
  minWidth: 115
};

export const timeBehind: Col<DeployedFeeds['outdatedDeployments']['items'][number] & OutdatedAugment> = {
  field: 'time_behind',
  headerName: 'Time Behind',
  valueGetter: params => params.row.deltas.timeInMs,
  renderCell: params =>
    params.row.deltas.timeInMs === null ? (
      <Placeholder variant='text' width={45} />
    ) : (
      timeAgo(new Date(Date.now() - params.row.deltas.timeInMs)).replace(' ago', '')
    ),
  minWidth: 115
};

export const outdatedActions = (
  onDeploy: (id: number) => void
): Col<DeployedFeeds['outdatedDeployments']['items'][number] & OutdatedAugment> => ({
  field: 'actions',
  headerName: 'Actions',
  renderHeader: () => null,
  resizable: false,
  sortable: false,
  maxWidth: 50,
  renderCell: params => (
    <MenuTrigger trigger={<MenuButton aria-label='Deployment Menu' icon={faEllipsisVertical} />}>
      <BurgerLink
        disabled={!params.row.latest || !params.row.integration || !params.row.integration.compatible}
        icon={faFileCircleInfo}
        title='Review and Redeploy'
        to={{
          pathname: `${Path.Detection}/${params.row.detection.guid}/version/${params.row.versionId}/diff/${params.row.latest?.id}`,
          search: formatQueryString({ deployedEnvironment: params.row.integration?.guid })
        }}
        TooltipProps={
          params.row.integration && !params.row.integration?.compatible
            ? {
                title: getDeployDisabledTooltip(params.row.integration)
              }
            : undefined
        }
      />
      <BurgerClicker icon={faCloudArrowUp} title='Redeploy Now' onClick={() => onDeploy(params.row.deploymentId)} />
    </MenuTrigger>
  )
});

export const COMMON_GRID_CONFIG = {
  columnVisibilityModel: { [detectionUrl.field]: false },
  disableColumnSelector: true,
  getRowId: item => item.deploymentId + (item.integration?.id || Math.random()),
  hideFooter: true
};
