import { FormikTouched } from 'formik';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import sum from 'lodash/sum';

import { TagWeight } from 'module/ThreatProfile';

import { chunkOverlap } from 'utilities/ArrayUtils';
import { toPrecisionDecimal } from 'utilities/NumberUtil';
import { monthsToISODuration } from 'utilities/TimeUtils';

import { ExploitationStateOrderedList, ThreatProfile, ThreatProfilePayload } from './ThreatProfileWizard.type';

export function requiresUpdate(
  keys: (keyof ThreatProfile)[],
  values: ThreatProfile,
  touched: FormikTouched<ThreatProfile>,
  initial: ThreatProfile
): boolean {
  return keys.some(key => touched[key] && !isEqual(initial[key], values[key]));
}

export function transformValue(key: keyof ThreatProfile, values: ThreatProfile): Partial<ThreatProfilePayload> {
  switch (key) {
    case 'scale':
      return { scale: collapseScale(values[key]) };

    case 'risk_tolerance':
      return { vulnerability_cvss_min: values[key][0] };

    case 'confidence_thresholds':
      return {
        confidence_min: values[key][0]
      };

    case 'severity_thresholds':
      return {
        severity_min: values[key][0]
      };

    case 'exploitation_state':
      return { vulnerability_exploit_min: ExploitationStateOrderedList[values[key][0]] };

    case 'exploited_zero_day':
      return {
        vulnerability_exploit_zero_day: values[key] === 'yes'
      };

    case 'exploited_in_wild':
      return {
        vulnerability_exploit_wild: values[key] === 'yes'
      };

    case 'exploit_likelihood':
      return {
        vulnerability_epss_min: toPrecisionDecimal(values[key][0] / 100, 2)
      };

    case 'threat_recency_threshold':
      return {
        threat_maturity: toPrecisionDecimal(values[key] / 100, 2)
      };

    case 'last_observed_threshold': {
      const months = values[key];

      return {
        observation_age_max: monthsToISODuration(months)
      };
    }

    case 'fit': {
      const vectors = values[key];
      const fitVectors = convertBucketSizesToFitVectors(vectors as Record<string, Record<TagWeight, number>>);
      if (validateFitVectors(fitVectors)) {
        return { [key]: fitVectors };
      }
      break;
    }
    default:
      return { [key]: values[key] };
  }
}

export function transformValues(keys: (keyof ThreatProfile)[], values: ThreatProfile): Partial<ThreatProfilePayload> {
  return keys.reduce((updates, key) => {
    const update = transformValue(key, values);
    return { ...updates, ...update };
  }, {});
}

export const TAG_WEIGHTS: readonly TagWeight[] = [
  TagWeight.Ignored,
  TagWeight.Lowest,
  TagWeight.Low,
  TagWeight.Medium,
  TagWeight.High,
  TagWeight.Highest
] as const;

export function convertFitVectorsToBucketSizes(
  fitVectors: Record<string, number[]>
): Record<string, Record<TagWeight, number>> {
  return Object.entries(fitVectors).reduce(
    (accumulated, [key, vector]) => ({
      ...accumulated,
      [key]: convertSingleFitVectorToBucketSize(vector)
    }),
    {}
  );
}

export function convertSingleFitVectorToBucketSize(fitVector: number[]): Record<TagWeight, number> {
  return chunkOverlap(fitVector, 2, 1)
    .map(([lower, upper], index) => ({
      [TAG_WEIGHTS[index]]: toPrecisionDecimal((upper - lower) * 100, 0)
    }))
    .reduce((accum, val) => ({ ...accum, ...val })) as Record<TagWeight, number>;
}

export function convertBucketSizesToFitVectors(
  bucketRecord: Record<string, Record<TagWeight, number>>
): Record<string, number[]> {
  return Object.entries(bucketRecord).reduce(
    (accumulated, [key, buckets]) => ({
      ...accumulated,
      [key]: convertSingleBucketSizeToFitVector(buckets)
    }),
    {}
  );
}

export function convertSingleBucketSizeToFitVector(buckets: Record<TagWeight, number>): number[] {
  const values = TAG_WEIGHTS.map(weight => toPrecisionDecimal(buckets[weight] / 100.0, 2));

  return values.reduce(
    (fitVector, value, index) => [...fitVector, toPrecisionDecimal(value + fitVector[index], 2)],
    [0]
  );
}

export function validateSingleFitVector(fitVector: number[]): boolean {
  return isEmpty(fitVector)
    ? false
    : toPrecisionDecimal(
        sum(chunkOverlap(fitVector, 2, 1).map(([lower, upper]) => toPrecisionDecimal(upper - lower, 2))),
        2
      ) === 1;
}

export function validateFitVectors(fitVectors: Record<string, number[]>): boolean {
  return isEmpty(fitVectors)
    ? false
    : Object.values(fitVectors).reduce((valid, fitVector) => valid && validateSingleFitVector(fitVector), true);
}

export function expandScale(scale: number): string[] {
  const bits = toPrecisionDecimal(scale / 100, 0);
  return bits
    .toString()
    .split('')
    .reverse()
    .map((bit, index) => Number(bit) * (100 * 10 ** index))
    .filter(Boolean)
    .map(bit => bit.toString());
}

export function collapseScale(scales: string[]): number {
  return scales.reduce((scale, value) => scale + toPrecisionDecimal(value, 0), 0);
}
