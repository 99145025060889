import EventEmitter from 'events';

import { Analytic } from 'types/analytic';

enum Name {
  Standby = 'standby',
  AddCondition = 'add_condition',
  AnalyticError = 'analytic_error',
  Change = 'change',
  Delete = 'delete',
  DiffCheck = 'diff_check',
  DiffCheckFinished = 'diff_check_result',
  Error = 'ab_error',
  Publish = 'publish',
  Save = 'save',
  Saved = 'saved',
  SetLogId = 'set_row_id',
  SetValues = 'set_values',
  OpenTest = 'open_test',
  Test = 'test',
  Testing = 'testing',
  TestCancel = 'test_cancel',
  TestComplete = 'test_complete',
  TestError = 'test_error',
  YAMLError = 'yaml_error',
  Parsed = 'parsed'
}
export { Name as ABEventName };

type EEInterface<E, T> = {
  emit(eventName: E, param?: T);
  on(eventName: E, callback: (param?: T) => void): void;
  once(eventName: E, callback: (param?: T) => void): void;
};

type AnalyticBuilderEE = EventEmitter &
  EEInterface<Name.AddCondition, { field: string; value: string }> &
  EEInterface<Name.AnalyticError, boolean> &
  EEInterface<Name.Change, string> &
  EEInterface<Name.Delete, string> &
  EEInterface<Name.DiffCheck, null> &
  EEInterface<Name.DiffCheckFinished, string> &
  EEInterface<Name.Error, string> &
  EEInterface<Name.Publish, null> &
  EEInterface<Name.Save, null> &
  EEInterface<Name.Saved, Analytic> &
  EEInterface<Name.SetLogId, null> &
  EEInterface<Name.SetValues, Partial<Analytic>> &
  EEInterface<Name.OpenTest, null> &
  EEInterface<Name.Test, string> &
  EEInterface<Name.Testing, null> &
  EEInterface<Name.TestCancel, string> &
  EEInterface<Name.TestComplete, null> &
  EEInterface<Name.TestError, string> &
  EEInterface<Name.YAMLError, string>;

export const analyticBuilderEE: AnalyticBuilderEE = new EventEmitter();
