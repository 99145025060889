import React from 'react';

import useNoPermitCreateRedirect from 'hooks/useNoPermitCreateRedirect';
import useQueryString from 'hooks/useQueryString';

import { AnalyticVersionProvider } from 'module/Analytic/core/AnalyticVersionProvider';
import { SyntaxCheckerProvider } from 'module/SyntaxChecker';

import { ArtifactType } from 'types/common';

import IDE from './IDE';

export default function IDEWrapper(props: any) {
  useNoPermitCreateRedirect(ArtifactType.Analytic);
  const { getByKey } = useQueryString();
  const analyticGuid = getByKey('detection');
  return (
    <SyntaxCheckerProvider>
      <AnalyticVersionProvider analyticGuid={analyticGuid}>
        <IDE {...props} />
      </AnalyticVersionProvider>
    </SyntaxCheckerProvider>
  );
}

export * from './IDEStateProvider';
export * from './IDETranslationStateProvider';
export * from './useIDEValue';
