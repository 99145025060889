import React, { ReactElement } from 'react';

import get from 'lodash/get';
import includes from 'lodash/includes';
import pull from 'lodash/pull';

import { OutlinedAccordion as Accordion } from 'snap-ui/Accordion';
import Button from 'snap-ui/Button';
import Divider from 'snap-ui/Divider';
import Tooltip from 'snap-ui/Tooltip';
import Typography from 'snap-ui/Typography';
import { styled } from 'snap-ui/util';

import AnalyticIcon from 'module/Icons/AnalyticIcon';
import AttackIcon from 'module/Icons/AttackIcon';
import UndetectedThreatIcon from 'module/Icons/UndetectedThreatIcon';
import UnvalidatedAnalyticIcon from 'module/Icons/UnvalidatedAnalyticIcon';
import { Session } from 'module/Session/Session.type';
import { Discriminator, reduceToBaseTags } from 'module/Tag';
import TagList from 'module/Tag/TagList';
import Badge from 'module/Widgets/Badge';
import { draftJSToMarkdownRawParser } from 'module/Widgets/Markdown/Markdown.util';
import MarkdownRead from 'module/Widgets/Markdown/MarkdownRead';

import { useAuth, useUserCatalog } from 'provider';

import { ArtifactScore } from 'types/common';
import { BlueMarker, BlueMarkerExtended, CombinedCompositeMarker, RedMarker } from 'types/marker';

import { getPreferredOrgScore } from 'utilities/ArtifactUtils';
import { convertSecondsToMMss, getDiff } from 'utilities/TimeUtils';

export const StyledDefaultSidebar = styled('div')`
  .MuiDivider-root {
    margin-top: ${p => p.theme.spacing(4)};
    margin-bottom: ${p => p.theme.spacing(2)};
  }
  .label {
    font-size: 0.75rem;
    background-color: ${p => p.theme.palette.secondary.dark};
    background-image: none;
    border: 0 solid transparent;
    border-radius: 0.25rem;
    color: ${p => p.theme.palette.common.white};
    display: inline-block;
    font-weight: 700;
    line-height: 1;
    margin: 0 0.125em;
    padding: 0.5833em 0.833em;
    text-transform: none;
    vertical-align: baseline;
  }
  .sidebar-section {
    display: flex;
    align-items: baseline;
    gap: ${p => p.theme.spacing(1)};
    color: #bebebe;
    .title-container {
      display: flex;
      gap: ${p => p.theme.spacing(2)};
      align-items: center;
      width: 270px;
    }
    .title {
      font-size: 1.25rem;
      width: 270px;

      &.normal {
        font-weight: normal;
      }
      .attack-title-badge {
        font-size: 0.875rem;
        font-weight: 600;
        padding: ${p => p.theme.spacing(1, 2, 1, 2)};
        margin-left: ${p => p.theme.spacing(1)};
      }
    }
    .name {
      font-size: 0.875rem;
      width: 20px;
      display: flex;
      align-items: inherit;
      width: 270px !important;
      padding-right: ${p => p.theme.spacing(3)};

      .non-domino-icon {
        min-width: 16px;
      }
      i.blue.icon {
        height: auto;
        margin-left: -1px;
        margin-right: 0px;
      }
      &.attack-marker {
        display: flex;
        gap: ${p => p.theme.spacing(1)};
        color: ${p => p.theme.palette.common.white};
        margin-right: 0 !important;
      }
      .MuiButton-root.MuiButton-text {
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
      }
    }
    .severity {
      min-width: 90px;
    }
  }

  .simple-format {
    .MuiCollapse-root {
      color: ${p => p.theme.palette.common.white};
      font-weight: 400;
      font-size: 0.875rem;
      transition: height 0ms;
    }
    .MuiButtonBase-root.MuiAccordionSummary-root.MuiAccordionSummary-gutters.AccordionSummary {
      min-height: 60px;
    }
    .MuiButtonBase-root.MuiAccordionSummary-root {
      flex-direction: row;

      .MuiAccordionSummary-content.Mui-expanded {
        margin: 0;
      }

      .MuiCollapse-root {
        border: 0;
      }
    }

    .MuiAccordionDetails-root.AccordionDetails {
      .Tooltip-wrapper {
        padding: ${p => p.theme.spacing(1, 0, 1, 0)};
        display: block;
      }
    }

    .field-info {
      display: flex;
      flex-direction: row;
      gap: ${p => p.theme.spacing(4)};
      .detail-name {
        flex-basis: 156px;
        min-width: 156px;
      }

      .value {
        flex: 1;
      }
    }
  }
`;

type Props = {
  composite: CombinedCompositeMarker;
  hostname: string;
  session: Session;
  onAttackClick: (attack: RedMarker) => void; // making guess at red marker
  onAnalyticClick: (analytic: BlueMarker) => void; // making guess at blue marker
};

const DefaultSidebar = ({ session, hostname, composite, onAnalyticClick }: Props): ReactElement => {
  const [activeIndices, setActiveIndices] = React.useState<number[]>([0, 1, 2]);
  const { defaultOrgId } = useAuth();
  const { users } = useUserCatalog();

  const createdByName = users.find(user => user.id === session?.created_by_id)?.name || '';
  const compositeBlue = Object.values(
    composite.blue.reduce((allData, eachData) => {
      if (!allData[eachData.analytics[0]?.guid]) {
        allData[eachData.analytics[0]?.guid] = eachData;
      }
      return allData;
    }, {})
  ) as BlueMarkerExtended[];

  const duration = React.useMemo(() => {
    const host = session.hosts.find(h => h.machine.name === hostname);
    if (!host) return '00:00';
    return convertSecondsToMMss(getDiff(host.end, host.start));
  }, [session, hostname]);

  function handleAccordionClick(titleProps): void {
    const index = titleProps;
    const currentIndices = [...activeIndices];
    if (includes(activeIndices, index)) {
      pull(currentIndices, index);
      setActiveIndices(currentIndices);
    } else {
      currentIndices.push(index);
      setActiveIndices(currentIndices);
    }
  }

  const description = draftJSToMarkdownRawParser(session.description);

  return (
    <StyledDefaultSidebar>
      <div>
        <div className='sidebar-title'>{get(session, 'name')}</div>
      </div>
      <Divider className='divide-banner' />
      <Accordion
        key={0}
        simple={true}
        expanded={includes(activeIndices, 0)}
        onChange={() => {
          handleAccordionClick(0);
        }}
        className='sidebar-accordion-title'
        summary={
          <>
            <Typography className='sideBar-title' variant='h4'>
              Threat Details
            </Typography>
          </>
        }
        details={
          <>
            <div className='field-container'>
              <div className='field-info'>
                <p className='detail-name'>Description</p>
                <div className='value'>
                  <MarkdownRead
                    value={description?.length > 150 ? description.substring(0, 150) + '...' : description}
                    raw
                  />
                </div>
              </div>
            </div>
            <div className='field-container'>
              <div className='field-info'>
                <p className='detail-name'>Author</p>
                <span className='value'>{createdByName}</span>
              </div>
            </div>
            <div className='field-container'>
              <div className='field-info'>
                <p className='detail-name'>Duration</p>
                <span className='value'>{duration}</span>
              </div>
            </div>
            <div className='field-container'>
              <div className='field-info'>
                <p className='detail-name'>Created On</p>
                <span className='value'>{session.creation}</span>
              </div>
            </div>
            <div className='field-container'>
              <div className='field-info'>
                <p className='detail-name'>Tags</p>
                <span className='value'>
                  <TagList
                    inline
                    attack={session.attack_names}
                    actor={session.actor_names}
                    software={session.software_names}
                    vulnerability={session.vulnerability_names}
                  />
                </span>
              </div>
            </div>
          </>
        }
      />
      {composite.red.length > 0 && (
        <>
          <Divider className='divide-banner' />
          <Accordion
            key={1}
            simple={true}
            expanded={includes(activeIndices, 1)}
            onChange={() => {
              handleAccordionClick(1);
            }}
            className='sidebar-accordion-title'
            summary={
              <div className='sidebar-section'>
                <div className='title-container'>
                  <Typography className='title normal ' variant='h4'>
                    Attacks
                    <span className='label attack-title-badge'>{composite.red.length}</span>
                  </Typography>
                </div>
                <span>Severity</span>
              </div>
            }
            details={composite.red.map(r => {
              const attackNames = reduceToBaseTags(r.attack_names as unknown as string[], Discriminator.Attack);
              return (
                <Tooltip
                  key={r.id}
                  placement='top-start'
                  wrap
                  title={
                    <div>
                      {attackNames.map((attack, i) => (
                        <span key={i}>{attack.name}</span>
                      ))}
                    </div>
                  }
                >
                  <div className='sidebar-section'>
                    <div className='name attack-marker'>
                      {r.lonely ? <UndetectedThreatIcon /> : <AttackIcon />}

                      <p>
                        <strong>{attackNames[0]?.name}</strong>
                        <em>{' ' + (attackNames.length > 1 ? ` and ${attackNames.length - 1} others` : '')}</em>
                        {r.description && (
                          <>
                            <br />
                            {r.description}
                          </>
                        )}
                      </p>
                    </div>
                    <span className='severity'>
                      <Badge badgeSignature={r.severity || ArtifactScore.UNKNOWN} badgeName='SEVERITY' size='small' />
                    </span>
                  </div>
                </Tooltip>
              );
            })}
          />
        </>
      )}
      {compositeBlue.length > 0 && (
        <>
          <Divider className='divide-banner' />
          <Accordion
            key={2}
            simple={true}
            expanded={includes(activeIndices, 2)}
            onChange={() => {
              handleAccordionClick(2);
            }}
            className='sidebar-accordion-title'
            summary={
              <div className='sidebar-section'>
                <div className='title-container'>
                  <Typography className='title normal' variant='h4'>
                    Detections<span className='label attack-title-badge'> {compositeBlue.length}</span>
                  </Typography>
                </div>
                <span className='severity'>Severity</span>
                <span className='confidence'>Confidence</span>
              </div>
            }
            details={compositeBlue.map((b, index) => {
              const firstAnalytic = b.analytics[0];
              const rank = getPreferredOrgScore(defaultOrgId, firstAnalytic.ranks, firstAnalytic.rank);
              const severity = getPreferredOrgScore(defaultOrgId, firstAnalytic.severities, firstAnalytic.severity);
              return (
                <div key={`${b.row_id}:${b.analytic_compilation_id}${index}`} className='sidebar-section'>
                  <div className='name'>
                    {b.lonely ? <UnvalidatedAnalyticIcon /> : <AnalyticIcon />}
                    <Button
                      variant='text'
                      onClick={(): void => onAnalyticClick(b)}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      {b.analytics[0].name}
                    </Button>
                  </div>
                  <span className='severity'>
                    <Badge badgeSignature={severity} badgeName='SEVERITY' size='small' />
                  </span>

                  <Badge badgeSignature={rank} badgeName='CONFIDENCE' size='small' />
                </div>
              );
            })}
          />
        </>
      )}
    </StyledDefaultSidebar>
  );
};

export default DefaultSidebar;
