import React from 'react';

import { faListAlt } from '@fortawesome/pro-solid-svg-icons';

import Chip from 'snap-ui/Chip';
import Icon from 'snap-ui/Icon';
import Link from 'snap-ui/Link';
import Paper from 'snap-ui/Paper';
import Placeholder, { TextPlaceholders } from 'snap-ui/Placeholder';
import Tabs, { TabLabel } from 'snap-ui/Tabs';
import Tooltip from 'snap-ui/Tooltip';
import Typography from 'snap-ui/Typography';
import { styled } from 'snap-ui/util';

import Path from 'constants/paths';

import { JobList } from 'module/BAS/JobList';
import { JobListAsyncData } from 'module/BAS/JobList/useJobList';
import { useEntityScripts } from 'module/BAS/ScriptList';
import { AnalyticDetectionSummaryState } from 'module/Detection/useAnalyticDetectionSummary';
import { Processor } from 'module/HighlightDetection';
import MatchingLog from 'module/MatchingLog';
import { LogRoot } from 'module/MatchingLog/MatchingLog.widgets';
import BackfillButton from 'module/Session/BackfillButton';
import { Backfill } from 'module/Session/Session.type';

import { useAuth } from 'provider';

import { checkTaskPermission } from 'services/authService';

import { FunctionalPermission } from 'types/auth';
import { CeleryState, Guid, Ident } from 'types/common';

import { TestableAnalyticCompilationTargets } from '../../../types/analytic';
import CustomerHits, { HIT_COUNT_DAYS_BACK, useOSHitCount } from './CustomerHits';
import useAnalyticBackfill from './useAnalyticBackfill';

const PanePlaceholderContainer = styled('div')`
  display: flex;
  flex-direction: column;
  padding: ${p => p.theme.spacing(5)};
  .Placeholder {
    margin-bottom: ${p => p.theme.spacing(2)};
  }

  .w100 {
    width: 100%;
  }

  .w80 {
    width: 80%;
  }

  .w60 {
    width: 60%;
  }

  .w50 {
    width: 50%;
  }
`;

export const PanePlaceholder = () => {
  return (
    <PanePlaceholderContainer>
      <Placeholder variant='text' className='w100' height={15} />
      <Placeholder variant='text' className='w50' height={15} />
      <Placeholder variant='text' className='w80' height={15} />
      <Placeholder variant='text' className='w60' height={15} />

      <Placeholder variant='text' className='w100' height={15} />
      <Placeholder variant='text' className='w50' height={15} />
      <Placeholder variant='text' className='w80' height={15} />
      <Placeholder variant='text' className='w60' height={15} />
    </PanePlaceholderContainer>
  );
};

const TabContent = styled(Paper)``;

function generatePlaceholderTab(key: string, analyticBackfill: Backfill) {
  return {
    value: 'placeholder',
    label: <TabLabel key='log'>Threat Library Hits</TabLabel>,
    content: (
      <TabContent key='placeholder'>
        {analyticBackfill?.status === CeleryState.in_progress ? (
          <LogRoot>
            <Typography variant='h4' component='div'>
              Running Against Threat Library...
            </Typography>
            <BackfillButton {...analyticBackfill} />
          </LogRoot>
        ) : (
          <TextPlaceholders />
        )}
      </TabContent>
    )
  };
}

type Props = {
  /** Analytics tag */
  analyticGuid: Guid;
  logsource: string;
  compilationTarget: Ident[];
  processor: Processor;
  isLoading: boolean;
  jobListAsync: JobListAsyncData;
  detectionSummary: AnalyticDetectionSummaryState;
};
const BACKFILL_STATE: (CeleryState | 'IDLE')[] = [CeleryState.in_progress, CeleryState.pending, CeleryState.retrying];

function ViewAnalyticTabs(props: Props): React.ReactElement {
  const { analyticGuid } = props;
  const { permission } = useAuth();
  const { data: scripts } = useEntityScripts();

  const analyticBackfill = useAnalyticBackfill(analyticGuid);
  const isBackfillPending = BACKFILL_STATE.includes(analyticBackfill?.status);

  const { hitCount } = useOSHitCount(analyticGuid);

  if (props.isLoading || isBackfillPending)
    return <Tabs key='loading-tabs' tabs={[generatePlaceholderTab('placeholder-1', analyticBackfill)]} />;

  const tabs = [];
  if (props.compilationTarget?.some(id => TestableAnalyticCompilationTargets.includes(id))) {
    tabs.push({
      value: 'log',
      label: (
        <TabLabel key='log'>
          Threat Library Hits
          <Chip key='logs' data-testid='logs-count' label={props.detectionSummary.summary.total} />
        </TabLabel>
      ),
      content: (
        <TabContent key='log'>
          <MatchingLog
            analyticGuid={props.analyticGuid}
            highlightProcessor={props.processor}
            detectionSummary={props.detectionSummary}
          />
        </TabContent>
      )
    });
  }

  if (checkTaskPermission(permission, FunctionalPermission.OpenSearchUIAccess)) {
    tabs.push({
      value: 'hunt',
      label: (
        <Tooltip arrow placement='top' title={`${hitCount || 0} hits in the last ${HIT_COUNT_DAYS_BACK} days`} wrap>
          <TabLabel key='hunt'>
            Detection Hits <Chip label={hitCount} />
          </TabLabel>
        </Tooltip>
      ),
      content: (
        <TabContent key='hunt'>
          {hitCount > 0 ? (
            <CustomerHits analyticGuid={props.analyticGuid} logsource={props?.logsource} />
          ) : (
            <LogRoot>
              <Icon className='RootLog-icon' icon={faListAlt} />
              <Typography variant='h4' component='div'>
                No Detection Hits from your Integrations
              </Typography>
              <p>
                Launch a hunt or{' '}
                <Link primary to={Path.Integrations}>
                  schedule a recurring confidence tailoring job
                </Link>{' '}
                to bring detection hits from your integrations into SnapAttack.
              </p>
            </LogRoot>
          )}
        </TabContent>
      )
    });
  }

  if (scripts?.length && checkTaskPermission(permission, FunctionalPermission.TaskBASAgent)) {
    tabs.push({
      value: 'bas',
      label: (
        <TabLabel key='bas'>
          Attack Simulations <Chip label={props.jobListAsync.data?.total} />
        </TabLabel>
      ),
      content: (
        <TabContent key='bas'>
          <JobList {...props.jobListAsync} />
        </TabContent>
      )
    });
  }

  if (tabs.length < 1) return null;
  return <Tabs tabs={tabs} />;
}

export default ViewAnalyticTabs;
