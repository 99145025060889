import React from 'react';

import { faRectangle, faSignalBars } from '@fortawesome/pro-regular-svg-icons';
import { faBars, faGrid } from '@fortawesome/pro-solid-svg-icons';
import isEmpty from 'lodash/isEmpty';

import Divider from 'snap-ui/Divider';
import Menu from 'snap-ui/Menu';
import { styled } from 'snap-ui/util';

import { BulkLauncherButton, BulkLauncherDialog, BulkLauncherInterface } from 'module/BAS/Launcher';
import { addToCollectionInterfaceFactory } from 'module/Curation';
import { AddToCollection } from 'module/Curation/AddToCollection/AddToCollection';
import { buildCuratedFilter } from 'module/Curation/Curation.service';
import {
  DetectionDeploymentDialog,
  DetectionDeploymentInterface,
  DetectionDeploymentMenuButton
} from 'module/DetectionDeployment';
import { useDetectionExportInterface, useRecommendedExportInterface } from 'module/Export';
import { useFilterRegistry } from 'module/GlobalFilter';
import { huntInterfaceFactory, JobType } from 'module/Job';
import { BurgerClicker } from 'module/Layout/Artifact.widgets';
import { HamburgerFab } from 'module/Layout/Styled';
import { useMayI } from 'module/May';
import { MetadataBulkEditButton, MetadataBulkEditDialog, MetadataBulkEditInterface } from 'module/Metadata';
import { bulkTaggingFactory } from 'module/Tag/BulkTag';

import { useFeedExtra } from 'provider';

import { FunctionalPermission } from 'types/auth';
import { BASProductNameKey } from 'types/bas';
import { ArtifactType, Guid } from 'types/common';

import { DisplayKind } from './Feed.type';

const [SelectedATCInterface, SelectedATCButton, SelectedATCDialog] =
  addToCollectionInterfaceFactory('SelectedATCContext');
const [RecommendedATCInterface, RecommendedATCButton, RecommendedATCDialog] = addToCollectionInterfaceFactory(
  'AddRecommendedToCollectionContext'
);
const [AttackScriptATCInterface, AttackScriptATCButton, AttackScriptATCDialog] = addToCollectionInterfaceFactory(
  'AddAttackScriptToCollectionContext'
);
const [BulkTagInterface, BulkTagButton, BulkTagDialog] = bulkTaggingFactory('BulkTaggingContext');

const [HuntInterface, HuntButton, HuntDialog] = huntInterfaceFactory('HuntContext');
const [BulkConfidenceInterface, BulkConfidenceButton, BulkConfidenceDialog] =
  huntInterfaceFactory('BulkConfidenceContext');

const MenuContainer = styled(Menu)`
  .MuiPaper-root {
    margin-top: ${p => p.theme.spacing(1)};
    padding: ${p => p.theme.spacing(2, 1)};
    border-top: 3px solid ${p => p.theme.palette.primary.main};
    border-radius: 0;
    background-color: ${p => p.theme.palette.background.paper};
  }
`;

export default function FeedMenu(props: {
  feedTotal: number;
  feedGuid?: Guid[];
  hasActivity: boolean;
  displayKind: DisplayKind;
  onDisplayKindChange(kind: DisplayKind): void;
  selectionModel: Guid[];
  implicitTopic?: ArtifactType;
}) {
  const burgerRef = React.useRef();
  const { topic, toQuery, values: filter } = useFilterRegistry(props.implicitTopic || ArtifactType.Session);
  const [showBurgerMenu, setShowBurgerMenu] = React.useState(false);
  const queryWithSelectionsOrAll =
    props.selectionModel.length > 0 ? buildCuratedFilter(props.selectionModel) : toQuery();
  const isBasUser = useMayI(FunctionalPermission.BASStableFeatures);

  const [DetectionExportButton, DetectionExportDialog] = useDetectionExportInterface();
  const [RecommendedExportButton, RecommendedExportDialog] = useRecommendedExportInterface();
  const { refreshMetadata } = useFeedExtra();

  return (
    <>
      <SelectedATCInterface>
        <RecommendedATCInterface>
          <AttackScriptATCInterface>
            <BulkTagInterface>
              <HuntInterface jobType={JobType.Hunt}>
                <BulkConfidenceInterface jobType={JobType.Rank}>
                  <BulkLauncherInterface
                    topic={topic}
                    criteria={queryWithSelectionsOrAll}
                    basProduct={filter?.basProduct as BASProductNameKey[]}
                  >
                    <DetectionDeploymentInterface>
                      <MetadataBulkEditInterface>
                        <HamburgerFab
                          disabled={props.hasActivity}
                          aria-label='Bulk actions'
                          icon={faBars}
                          onClick={() => setShowBurgerMenu(true)}
                          ref={burgerRef}
                        />
                        <MenuContainer
                          anchorEl={burgerRef.current}
                          onClose={() => setShowBurgerMenu(false)}
                          onClick={() => setShowBurgerMenu(false)}
                          open={showBurgerMenu}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right'
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right'
                          }}
                        >
                          {ArtifactType.Analytic === topic && <DetectionExportButton />}
                          {ArtifactType.Session === topic && <RecommendedExportButton />}
                          {ArtifactType.Analytic === topic && <DetectionDeploymentMenuButton />}
                          {ArtifactType.Analytic === topic && (
                            <DetectionDeploymentMenuButton title='Undeploy Detections' undeploy />
                          )}
                          {ArtifactType.Analytic === topic && <HuntButton />}
                          {ArtifactType.Analytic === topic && <BulkConfidenceButton icon={faSignalBars} />}
                          {[ArtifactType.Analytic, ArtifactType.Session, ArtifactType.AttackScript].includes(topic) && (
                            <BulkLauncherButton />
                          )}
                          {ArtifactType.Collection !== topic && <SelectedATCButton />}
                          {ArtifactType.Session === topic && (
                            <RecommendedATCButton title='Add Recommended Detections to Collection' />
                          )}
                          {ArtifactType.Analytic === topic && isBasUser && (
                            <AttackScriptATCButton title='Add Linked Attack Scripts to Collection' />
                          )}
                          {[
                            ArtifactType.Intel,
                            ArtifactType.Analytic,
                            ArtifactType.Session,
                            ArtifactType.AttackScript
                          ].includes(topic) && (
                            <BulkTagButton
                              TooltipProps={{
                                title: props.displayKind === DisplayKind.Grid ? '' : 'Switch to grid view to edit tags',
                                placement: 'left'
                              }}
                              disabled={props.displayKind === DisplayKind.Card}
                            />
                          )}
                          <MetadataBulkEditButton
                            TooltipProps={{
                              title:
                                props.displayKind === DisplayKind.Card
                                  ? 'Switch to grid view to edit metadata'
                                  : isEmpty(props.selectionModel)
                                  ? 'Select items in the grid to continue'
                                  : '',
                              placement: 'left'
                            }}
                            disabled={props.displayKind === DisplayKind.Card || isEmpty(props.selectionModel)}
                          />
                          <Divider />
                          <BurgerClicker
                            title='Display as Card'
                            icon={faRectangle}
                            onClick={() => {
                              setShowBurgerMenu(false);
                              props.onDisplayKindChange(DisplayKind.Card);
                            }}
                            disabled={props.displayKind === DisplayKind.Card}
                          />
                          <BurgerClicker
                            title='Display as Grid'
                            icon={faGrid}
                            onClick={() => {
                              setShowBurgerMenu(false);
                              props.onDisplayKindChange(DisplayKind.Grid);
                            }}
                            disabled={props.displayKind === DisplayKind.Grid}
                          />
                        </MenuContainer>
                        <SelectedATCDialog
                          type={topic as AddToCollection['type']}
                          selectionModel={props.displayKind === DisplayKind.Grid ? props.selectionModel : undefined}
                          criteria={queryWithSelectionsOrAll}
                          title={
                            props.displayKind === DisplayKind.Card ? 'Add filtered items to a collection' : undefined
                          }
                        />
                        <RecommendedATCDialog
                          type={topic as AddToCollection['type']}
                          selectionModel={props.displayKind === DisplayKind.Grid ? props.selectionModel : undefined}
                          criteria={queryWithSelectionsOrAll}
                          fromRecommendedDetection
                          title='Add Recommended Detections to Collection'
                        />
                        <AttackScriptATCDialog
                          type={topic as AddToCollection['type']}
                          selectionModel={props.displayKind === DisplayKind.Grid ? props.selectionModel : undefined}
                          criteria={queryWithSelectionsOrAll}
                          fromRelatedAttackScripts
                          title='Add Linked Attack Scripts to Collection'
                        />
                        <HuntDialog query={queryWithSelectionsOrAll} selections={props.selectionModel} />
                        <BulkConfidenceDialog query={queryWithSelectionsOrAll} selections={props.selectionModel} />
                        <BulkLauncherDialog />
                        <DetectionDeploymentDialog criteria={queryWithSelectionsOrAll} />
                        <DetectionExportDialog filter={queryWithSelectionsOrAll} />
                        <RecommendedExportDialog filter={queryWithSelectionsOrAll} />
                        <BulkTagDialog selections={props.selectionModel} topic={topic} />
                        <MetadataBulkEditDialog
                          selections={props.selectionModel}
                          topic={topic}
                          onSuccess={refreshMetadata}
                        />
                      </MetadataBulkEditInterface>
                    </DetectionDeploymentInterface>
                  </BulkLauncherInterface>
                </BulkConfidenceInterface>
              </HuntInterface>
            </BulkTagInterface>
          </AttackScriptATCInterface>
        </RecommendedATCInterface>
      </SelectedATCInterface>
    </>
  );
}
